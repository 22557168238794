@use 'glyphs' as glyphs;
@use 'mixins/mixin-cardinal-position' as *;
@use 'base/text' as text;
@use 'palette';
@use '../graphical-toolbars.global.scss' as *;

.graphical-toolbar,
.graphical-toolbar-button {
    position: absolute;
    z-index: 15; // above the content
    box-shadow: $toolbar-box-shadow;
}
.graphical-toolbar {
    background-color: #fff;
    border-radius: 3px;
}
.graphical-toolbar-button {
    position: absolute !important;
}

.graphical-toolbar-root {
    @extend .graphical-toolbar;
    display: flex;

    @each $pos in n, s, e, w, ne, se, sw, nw {
        &.pos-#{$pos} {
            @include cardinal-position($pos, $toolbar-from-side);
        }
    }
}

dxy-graphical-toolbar,
.mat-menu-panel.dxy-graphical-toolbar--mat-menu {
    .mat-menu-content {
        display: flex;
    }
    ul.toolbar-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex: 1 0 auto;
        &.vertical {
            flex-direction: column;
            flex-grow: 1;
            &:not(.burger-menu):not(.in-burger-menu) {
                max-width: $toolbar-size;
                padding: $toolbar-end-padding $toolbar-padding;
            }
            &.burger-menu {
                padding: 10px 0;
            }
            &.in-burger-menu {
                background-color: white;
            }
            > li.separator {
                min-height: 0;
                &:before {
                    content: '';
                    width: $separator-length;
                    margin: 0 auto;
                    border-bottom: $separator-border;
                }
            }
        }
        &.horizontal {
            flex-direction: row;
            //max-height: $toolbar-size;
            padding: $toolbar-padding $toolbar-end-padding;
            > li.separator {
                min-width: 0;
                &:before {
                    content: '';
                    height: $separator-length;
                    margin: auto 0;
                    border-right: $separator-border;
                }
            }
            &.options-stride {
                flex-wrap: wrap;
                max-width: calc(
                    var(--options-stride) *
                        (#{$button-size} + #{2 * $button-margin}) + #{2 *
                        $toolbar-end-padding}
                );
            }
        }
        > li {
            display: flex;
            align-items: center;
            margin: $button-margin;
            min-width: $button-size;
            min-height: $button-size;
            &.current-active {
                cursor: default;
                button {
                    opacity: 1 !important;

                    i {
                        color: palette.$reflex-50;
                    }
                }
            }
        }
        li.in-burger-menu {
            margin: 0;
            padding: 0;
            min-height: 40px;
            align-items: center;
            &:hover {
                background-color: var(--color-background-any--hover);
            }
            &.separator {
                pointer-events: none;
                &:before {
                    width: 100%;
                }
            }
        }
    }
}

.mat-menu-panel.dxy-graphical-toolbar--mat-menu {
    min-width: unset;
    min-height: unset;
    overflow: visible;
    box-shadow: $toolbar-box-shadow !important;

    &.no-max-width {
        max-width: unset;
    }

    //#region used with overlapTrigger=true
    // when using transform, there is delay we don't want, so we use margins instead
    $shift-primary: $toolbar-size - $toolbar-padding;
    $shift-secondary: $toolbar-padding + $toolbar-end-padding;
    &.menu-side-top {
        margin-left: -$shift-secondary;
        margin-bottom: $shift-primary;
    }
    &.menu-side-right {
        margin-left: $shift-primary;
        margin-top: -$shift-secondary;
    }
    &.menu-side-bottom {
        margin-left: -$shift-secondary;
        margin-top: $shift-primary;
    }
    &.menu-side-left {
        margin-right: $shift-primary;
        margin-top: -$shift-secondary;
    }
    //#endregion

    > .mat-menu-content {
        padding: 0;
    }
}

.burger-menu-text-option {
    border: none;
    margin: 0;
    padding: 12px;
    width: auto;
    background: transparent;
    flex-grow: 1;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
