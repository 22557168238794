@use 'base/text' as text;

*,
:after,
:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

// todo vbo remove this when tab navigation is supported
*:focus-visible {
    outline: none;
}

input,
button {
    font-family: inherit;
    font-weight: inherit;
    font-size: 100%;
}

button {
    cursor: pointer;
    color: inherit;
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: text.$title-text-size;
}

[class^='glyph-'],
[class*=' glyph-'] {
    font-size: 16px;
}
