@use 'base/text' as text;
@use 'palette';

dxy-entity-count-by-entity-type-widget {
    .g-num {
        fill: palette.$blue-50;
    }

    .g-num2 {
        fill: rgba(0, 0, 255, 0.05);
    }

    .g-labels-count {
        font-size: text.$small-text-size;
        text-align: right;
    }

    .x.axis line,
    .y.axis line {
        display: none;
    }

    .x.axis text {
        display: none;
    }

    .domain {
        display: none;
    }

    .g-labels {
        font-size: text.$normal-text-size;
        font-weight: 450;
        fill: palette.$reflex-100;

        &:not(.no-click):hover {
            fill: palette.$blue-50;
            cursor: pointer;
        }
    }
}
