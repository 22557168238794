@use '../../style/mixins/_mixin-label.scss';
@use 'base/text' as text;
@use 'palette';

.mat-select-panel.dxy-field-select,
dxy-field-select {
    .mat-select-value-text .glyph {
        margin-right: 5px;
    }
    .mat-option-text {
        .glyph {
            margin-right: 5px;
        }
        app-entity-card-cell {
            .glyph {
                margin-right: 0px;
            }
        }
    }
    .mat-option-text,
    .read-only-field {
        .img-wrapper {
            height: 30px;

            &.placeholder {
                width: 30px;
                height: 30px;
                background-color: var(--color-background-any-secondary);
                display: flex;
                border-radius: 50%;
            }
        }
    }
    .mat-option {
        height: unset !important;
        min-height: 45px;
    }
    .rich-layout-option .mat-option-text {
        display: flex;
        height: 100%;
        align-items: center;
    }
    &.mini {
        .mat-select-value-text {
            .option-item-glyph {
                margin-right: 4px !important;
                .glyph {
                    margin-right: 0;
                }
            }
        }
    }
    .mat-option-search {
        .mat-option-text {
            background: unset !important;
        }
    }
}

.mat-select-panel.dxy-field-select {
    .mat-optgroup .mat-optgroup-label {
        font-size: text.$small-text-size;
    }
}

dxy-field-select {
    &.no-underline {
        .mat-form-field-underline {
            height: 0;
        }
        .mat-form-field-ripple {
            display: none;
        }
    }

    &.padding-10 {
        mat-select-trigger {
            margin-left: 10px;
        }
        .mat-select-arrow-wrapper {
            margin-right: 10px;
        }
        .mat-form-field-flex {
            padding: 0;
        }
        .mat-form-field-infix {
            padding: 0;
            border-top-width: 10px;
        }
        .mat-form-field-wrapper {
            padding-bottom: 8px;
        }
    }

    &.width-100 {
        .mat-form-field-infix {
            width: 100px;
        }
    }

    &.width-150 {
        .mat-form-field-infix {
            width: 150px;
        }
    }

    mat-select-trigger {
        display: flex;
        align-items: center;

        .img-wrapper {
            height: 30px;
            margin-right: 5px;

            &.placeholder {
                width: 30px;
                background-color: var(--color-background-any-secondary);
                display: flex;
                border-radius: 50%;
            }
        }
    }
}
