@use 'base/text' as text;

dxy-solution-selection {
    .left-section {
        .card {
            width: 240px;
            padding: 16px;
            align-items: center;
            gap: 12px;
            height: 100%;
            justify-content: center;
        }
    }

    .card {
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-shadow:
            0 2px 4px 0 var(--color-shadow-any),
            0 0 2px 0 var(--color-shadow-any) !important;
        position: relative;

        .info-button {
            position: absolute;
            left: auto;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }

        .title {
            text-align: center;
        }
    }
}
