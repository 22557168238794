@import '../global/base/mixin-flex.scss';

.divDashboardMain {
    @include flexItem(1);
    @include flexV();
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 100%;
    z-index: 0;

    .pageTitle {
        font-size: 36px;
        font-weight: 100;

        .objectName {
            font-weight: bold;
        }

        margin-bottom: 50px;
    }
}
