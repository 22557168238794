@mixin flexbox(
    $flex-direction: row,
    $flex-wrap: nowrap,
    $justify-content: flex-start,
    $align-items: stretch,
    $align-content: stretch
) {
    display: flex;
    flex-direction: $flex-direction;
    flex-wrap: $flex-wrap;
    justify-content: $justify-content;
    align-items: $align-items;
    align-content: $align-content;
}

@mixin flexItem(
    $flex-grow: 0,
    $flex-shrink: 1,
    $flex-basis: auto,
    $align-self: auto
) {
    flex-grow: $flex-grow;
    flex-shrink: $flex-shrink;
    flex-basis: $flex-basis;
    align-self: $align-self;
}

@mixin flexOrder($order) {
    order: $order;
}

@mixin flexV(
    $flex-wrap: nowrap,
    $justify-content: flex-start,
    $align-items: stretch,
    $align-content: stretch
) {
    @include flexbox(
        column,
        $flex-wrap,
        $justify-content,
        $align-items,
        $align-content
    );
}

@mixin flexH(
    $flex-wrap: nowrap,
    $justify-content: flex-start,
    $align-items: stretch,
    $align-content: stretch
) {
    @include flexbox(
        row,
        $flex-wrap,
        $justify-content,
        $align-items,
        $align-content
    );
}

@mixin hcontainerDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
    flex-grow: 1;
    min-width: 0;
}

@mixin vcontainerDiv {
    @include flexV();
    min-height: 0;
    overflow: hidden;
}

@mixin flex-grow {
    width: 100%;
    @include flexItem(1);
}
