@use 'base/text' as text;

.d3-tip {
    line-height: 1;
    font-weight: bold;
    padding: 12px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 2px;
    pointer-events: none;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: text.$tiny-text-size;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    position: absolute;
    pointer-events: none;
}

/* Northward tooltips */
.d3-tip.n:after {
    content: '\25BC';
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
    text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
    content: '\25C0';
    margin: -4px 0 0 0;
    top: 50%;
    left: -8px;
}

/* Southward tooltips */
.d3-tip.s:after {
    content: '\25B2';
    margin: 0 0 1px 0;
    top: -8px;
    left: 0;
    text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
    content: '\25B6';
    margin: -4px 0 0 -1px;
    top: 50%;
    left: 100%;
}
