@use 'mixins' as *;
@use 'glyphs' as *;
@use 'base/text' as text;
@use '../global/base/transition.scss';

$dat-bloc-item-height: 30px;

.container-box {
    font-size: 0.9rem;
    background: var(--color-background-any);
    border-radius: 5px;
    box-shadow: var(--color-shadow-any-light);
    margin-bottom: 16px;
    overflow: hidden;
    width: 400px;
}

// #archi-scss (revi): Move in new scss structure
.glyph-arrow-drop-down {
    display: inline-flex;
    @include transition.transition(transform);

    &--collapsed {
        transform: rotate3d(0, 0, 1, -90deg);
        @include transition.transition(
            transform,
            $delay: $dat-transition-default-duration
        );
    }
}

.container-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-top: 1px solid transparent;
    position: relative;
    @include transition.transition(border-color);

    .container-title-text {
        max-width: 70%;
        overflow: hidden;
        float: left;
        @include ellipsis();
    }

    .container-title-collapse {
        float: right;
        height: 20px;
        width: 30px;
    }

    .glyph-arrow-drop-down {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -3px;
    }

    .glyph-arrow-drop-down--model {
        margin-right: -10px;
    }
}

.bloc-item {
    @include ellipsis();
    padding: 5px 20px;
    overflow: hidden;
}

.bloc-item-loading {
    @extend .bloc-item;
    text-align: center;
    font-size: text.$small-text-size;

    & > p {
        margin: 1px;
    }
}

.bloc-item-empty {
    @extend .bloc-item;
    color: var(--color-foreground-any-tertiary);
    text-align: center;
}
