dxy-space-authorization-preview .user-header dxy-user-badge-cell {
    .dg_user-initials,
    .dg_user-avatar {
        width: 90px;
        height: 90px;
        font-size: 48px;
        line-height: 86px;
        font-weight: 700;
        max-width: 90px !important;
    }
}
