$navbar-top-padding-top: 5px;
$navbar-top-padding-bottom: 5px;
$navigation-title-container-height: 62px;

$navbar-top-height: $navbar-top-padding-top + $navbar-top-padding-bottom +
    $navigation-title-container-height;

// Side bar
$nav-sidebar-width: 52px; // Please use --nav-sidebar-width CSS var to accomodate the runtime dynamic value
$sidebar-icon-width: 34px;
$sidebar-item-icon-fontsize: 17px;
$sidebar-item-margin: ($nav-sidebar-width - $sidebar-icon-width) * 0.5;
$sidebar-item-height-extension: $sidebar-item-margin; // Visual space between items (hover state tiles)
$sidebar-item-icon-padding: (
        $sidebar-icon-width - $sidebar-item-icon-fontsize
    ) *
    0.5;

:root {
    --nav-sidebar-width-collapsed: #{$nav-sidebar-width};
    --nav-sidebar-width-expanded: 200px;
    --nav-sidebar-width: #{$nav-sidebar-width};
    --nav-sidebar-item-height: #{$sidebar-icon-width +
        $sidebar-item-height-extension};
}
