@use 'mixins';
@use 'palette';
@import '../../../../styles/global/base/transition.scss';
@import '../../../../styles/global/base/bold.scss';

$dashed-vertical-line-color: #d6d6d6; //multiply(white, fade(var(--color-foreground-any), 20%))

app-dp-mapping {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background-any-secondary);
    overflow: auto;
    //For FireFox => fix missing scroll
    height: 0;
    padding: 0 !important;

    .as-split-area {
        display: flex;
        overflow-y: hidden !important; // avoid double scroll-bar
    }

    .graphical-surface {
        display: flex;
        width: 100%;
        background: var(--color-background-any);
        user-select: none;
        position: relative;

        .mapping-container {
            flex-basis: 1px;
        }

        .gs-internal {
            z-index: 10;
            pointer-events: none;
            position: absolute;
        }
    }

    .mapping-zone {
        @include flexItem();
        background: inherit;
        flex-grow: 1;
        align-self: stretch;
        flex-basis: 35%;
        position: relative;
        padding: 50px 10px 10px 10px;
        @include flexbox(column, nowrap, flex-start, center);
        overflow: auto;

        &:before {
            color: palette.$gray-10;
            font-size: 2.5em;
            font-weight: 900;
            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
            text-transform: uppercase;
            top: 0;
            width: 100%;
        }
    }

    .mapping-zone--in {
        align-items: stretch;
        &:before {
            content: 'In';
        }
    }

    .mapping-zone--out {
        align-items: stretch;
        &:before {
            content: 'Out';
        }
    }

    .mapping-zone--processItems {
        flex-basis: 30%;
        border-left: 2px dashed $dashed-vertical-line-color;
        border-right: 2px dashed $dashed-vertical-line-color;
        padding: 7.15em 3em;
        &:before {
            content: 'Process';
        }
    }
    @mixin mapping-item() {
        @include mixins.ellipsis;
        box-shadow: var(--color-shadow-any-light);
        height: 30px;
        margin: 4px 10px;
        padding: 5px 10px 5px 10px;
    }
    .dataProcessing-item {
        background: inherit;
        cursor: initial;
        margin: 2px auto;
        width: 100%;
        font-size: 0.9rem;
    }

    .dataProcessing-item--ghost {
        @include mapping-item();
        background: inherit;
        margin: 0;
        padding-right: 10px;
        box-shadow: none;
        border: 1px dashed var(--color-border-any);
        color: var(--color-foreground-any-tertiary);
        cursor: pointer;
        @include transition(all);

        &:hover {
            border-color: var(--color-foreground-any-tertiary);
        }
    }

    .dpi-action {
        &.action--add {
            font-size: 5em;
            color: palette.$gray-10;
            @include txt-bolder();
            line-height: 1;
            @include transition(color);

            &:hover {
                color: palette.$blue-50;
            }
        }
    }

    .dg_dataProcessingItem-label {
        @include mixins.ellipsis;
        padding-right: 0.5em;
    }

    .mapping-container--processes {
        flex-basis: 1px;
        min-width: 170px;
        padding-right: 10px;
    }

    .right-list {
        flex-grow: 1;
        display: flex;
        //#region to match .dg5-master-list
        background: #ffffff;
        box-shadow: var(--color-shadow-any-neutral);
        //#endregion
    }

    .graphical-surface {
        .item-highlighted {
            background: palette.$reflex-08;
        }
    }
    //#region diagram-surface
    .diagramming {
        //#region for port-handles
        app-dp-implem-element,
        app-dp-item-element,
        app-dp-mapping-item {
            position: relative;
        }
        app-dp-mapping-item .pm-handle {
            z-index: 2; // because of .item-field z-index: 1
        }
        //#endregion
        //#region connectors
        .gs-connector {
            &.virtual path.stroke {
                stroke-dasharray: 3 3;
            }
            &.highlighted {
                path.stroke,
                path.gs-connector-arrow {
                    z-index: 1000;
                    stroke: palette.$reflex-60 !important;
                    stroke-width: 2px;
                }
            }
            path.outline {
                stroke-width: 10;
            }
        }
        //#endregion
    }
    //#endregion - diagram-surface
}
