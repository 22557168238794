mat-tab-group {
    overflow: hidden;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .mat-tab-header {
        border-bottom-color: var(--color-shadow-any);

        .mat-tab-labels {
            text-transform: uppercase;

            .mat-tab-label-content {
                font-weight: 750;
                font-size: 12px;

                [class^='glyph-'],
                > [class*=' glyph-'],
                .dg5-badge {
                    /** Glyph or Badge before text*/
                    &:first-child {
                        margin-right: 10px;
                    }

                    &:not(:first-child) {
                        margin-left: 10px;
                    }
                }
            }

            .mat-tab-label {
                color: var(--color-text-any-primary);
                min-width: auto;

                .mat-ripple-element {
                    background-color: var(--color-background-ripple);
                }
            }

            .mat-tab-label:not(.mat-tab-disabled) {
                transition: all 0.2s ease-out;

                &:hover,
                &.mat-tab-label-active {
                    color: var(--color-text-any-primary);
                    opacity: 1;

                    &.disable-default-opacity .apply-opacity {
                        opacity: 1;
                    }
                }

                &.disable-default-opacity {
                    opacity: 1;

                    .apply-opacity {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    &.mat-primary .mat-tab-header .mat-ink-bar {
        background-color: var(--color-text-any-primary);
    }

    .mat-tab-body-wrapper {
        padding: 20px;
    }

    &.content-fill-available {
        height: 100%;

        .mat-tab-body-wrapper {
            flex-grow: 1;
        }
    }

    &.no-padding .mat-tab-body-wrapper {
        padding: 0;
    }

    &.no-header-border .mat-tab-header {
        border: 0;
    }
}
