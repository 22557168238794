@use 'base/text' as text;
@use 'palette';
@import './base/transition.scss';
@import './base/mixin-icon.scss';

.icon {
    @include transition(color);
    position: relative;

    &:before {
        position: absolute;
        top: 50%;
        margin-top: -6px;
        left: 0px;
    }
}

.primary-icon {
    @include icon-variant(palette.$blue-50);
}

.system-field-icon {
    @include icon-variant(var(--color-foreground-any-tertiary));
    font-size: text.$small-text-size;
    margin-left: 5px;
    margin-top: 5px;
}

.tooltip-icon {
    @include icon-variant(palette.$blue-50);
}

.default-icon {
    @include icon-variant();
}

// // TODO : add everywhere this class to change color of icon for toggle func/tech
// .dg_icon--functional {
// 	/*color: @dat-functional !important;*/
// }
