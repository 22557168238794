@use 'mixins';
@use 'palette';
@import './mixin-flex.scss';
@import './mixin-icon.scss';

.dg5-master-list {
    // ex glossaryPropertyList-container
    @include flexItem(0, 0, 260px);
    overflow: hidden;
    margin-top: 10px;
    background: var(--color-background-any);
    box-shadow: var(--color-shadow-any-light);
    border-radius: 10px 10px 0 0;
    display: flex;
}
.dg5-select-list {
    // ex mapping-propertyList
    padding: 0;
    @include flexV();
    z-index: 2;
}

.dg5-select-list-separator {
    height: 1px;
    align-self: stretch;
    border-bottom: 1px solid var(--color-border-any);
}

.dg5-select-list--start {
    @include flexItem(1, 0, 260px);
    background: inherit;
    overflow: hidden;
}

.dg5-select-list--end {
    @include flexItem(0, 0, 260px);
    width: 260px;
    background: white;

    .container-box {
        border-radius: 0;
        overflow: hidden;
        box-shadow: none;
        margin-bottom: 0;
        width: auto;
    }
}

.dg5-select-list-search {
    background: inherit;
    @include flexItem(0, 0, auto);
}

.dg5-select-list-body {
    @include flexItem(1);
    padding: 0;
    margin: 0;
    list-style-type: none;
    overflow-y: auto;
}
.dg5-select-list-item {
    background: white;

    &:hover {
        background: palette.$reflex-08;
        &:not(.drag-item) {
            cursor: pointer;
        }
    }

    &.disabled {
        color: var(--color-foreground-any-secondary);
        cursor: not-allowed;
        user-select: none;
        &:hover {
            background: white;
        }
    }

    .dg5-select-list--start & {
        @include mixins.menu-item(30px);
        border-bottom: palette.$reflex-03;
        box-shadow: none;

        &:first-child {
            border-top: palette.$reflex-03;
        }
    }
}
.dg5-select-list-icon {
    color: palette.$blue-50;
    @include icon-variant(palette.$blue-50);
    color: palette.$blue-50;
    margin-top: 12px;
    margin-bottom: 12px;
}

.dg5-select-list-text {
    @include mixins.ellipsis();
    flex: 1;
}
.dg5-select-list-searches {
    background: inherit;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.dg5-select-list-actions {
    display: flex;
    padding: 10px 0 5px;

    > button {
        flex: 1;
    }
}
