dxy-csv-import-settings {
    dxy-space-version-selector {
        padding-left: 0;
        padding-right: 0;
    }

    dxy-workspace-dropdown {
        margin-right: 0;
        .selected-workspace {
            padding: 10px;
            width: 400px;
            border-style: solid;
            border-width: 1px;
            border-color: transparent transparent #f0f0f7;
            cursor: pointer;
            transition: all 0.2s ease 0s;

            &:hover {
                background-color: rgba(0, 26, 255, 0.03);
            }

            .displayname {
                flex: 1;
            }
        }
    }

    dxy-versioning-selector {
        margin: 0;
        padding: 10px;
        .glyph-arrow-drop-down {
            margin-left: 5px;
        }
    }
}
