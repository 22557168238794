.dxy-button {
    --background-color: var(--buttons-background-primary-default-resting);
    --background-color-hover: var(--buttons-background-primary-default-hover);
    --background-color-active: var(
        --buttons-background-primary-default-whilepressing
    );
    --background-color-disabled: var(
        --buttons-background-primary-default-disabled
    );
    --background-color-visited: var(
        --buttons-background-primary-default-onloading
    );
    --color: var(--buttons-foreground-primary-resting);
    --border-color: var(--buttons-border-primary-color);
    --gap: var(--buttons-gap-l, 8px);
    --padding: var(--buttons-padding-y-l, 12px) var(--buttons-padding-x-l, 16px);
    --border-radius: var(--buttons-radius-l, 8px);
    --icon-size: var(--buttons-icon-size-m, 16px);
    --font-size: var(--typography-fontSize-s, 13px);
    --line-height: var(--typography-lineHeight-m, 16px);
}
