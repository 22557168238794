@use 'palette';

.ql-mention {
    color: palette.$reflex-100;
    line-height: 12px;

    & .mention-icon {
        margin-right: 5px;
    }
}
