@use 'base/buttons';
@use 'glyphs';
@use 'base/navbar' as nav;

//#region colors
$color-iconBackground: #e9ebf0;
$border-color: #f0f0f7;
$border-thin: 1px solid $border-color;
//#endregion

//#region sizes
$toolbar-margin: 20px;
$side-panel-margin: 10px;
$mini-map-height: 120px;
//#endregion

dxy-graphical-controls {
    //#region defaults
    --navbar-height: #{nav.$navbar-top-height}; // as in webclient app
    --navbar-width: var(--nav-sidebar-width);
    --side-panel-width: 300px;
    --side-panel-slide-delay: 200ms;
    //#endregion
    --_side-panel-shift: calc(var(--side-panel-width) + #{$side-panel-margin});
    background-color: var(
        --color-background-any-tertiary
    ); // needed for fullscreen transitioning
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    .side-panel-expanded,
    .side-panel-collapsed {
        &.right {
            transition: right var(--side-panel-slide-delay);
        }
        &.left {
            transition: left var(--side-panel-slide-delay);
        }
    }

    .content-wrapper {
        flex: 1 1 auto;

        // we break the flex cascade on purpose here,
        // for the .content element to :
        // - adapt when the docking pane width changes
        // - not adapt to its content
        // - be the graphical surface size reference for the inner svg element
        position: relative;
        > .content {
            position: absolute;
            inset: 0;
            display: flex;
            &.side-panel-expanded {
                &.right {
                    right: var(--_side-panel-shift);
                }
                &.left {
                    left: var(--_side-panel-shift);
                }
            }
            &.side-panel-collapsed {
                &.right {
                    right: 0;
                }
                &.left {
                    left: 0;
                }
            }
        }

        // debug
        // border: 1px dashed red;
        // >.content { border: 1px dashed green; }
    }

    .wrapper-v {
        display: flex;
        flex-direction: column;
    }
    .wrapper-h {
        display: flex;
        flex-direction: row;
    }

    .left-toolbar {
        inset: 50% auto auto $toolbar-margin;
        &.side-panel-expanded.left {
            left: calc(var(--_side-panel-shift) + #{$toolbar-margin});
        }
        &.side-panel-collapsed.left {
            left: $toolbar-margin;
        }
    }
    .bottom-right-toolbar {
        inset: auto $toolbar-margin $toolbar-margin auto;
        &.side-panel-expanded.right {
            right: calc(var(--_side-panel-shift) + #{$toolbar-margin});
        }
        &.side-panel-collapsed.right {
            right: $toolbar-margin;
        }

        .mini-map {
            height: $mini-map-height;
            flex: 1 0 auto;
            overflow: hidden;
            &:not(.visible) {
                opacity: 0;
                position: absolute;
            }
        }
        .zoom-percentage {
            font-weight: bold;
            padding: 3px 6px;
            min-width: 53px;
        }
    }

    //#region side-panel
    .side-panel-button {
        &.right {
            inset: $toolbar-margin $toolbar-margin auto auto;
        }
        &.left {
            inset: $toolbar-margin auto auto $toolbar-margin;
        }
    }
    .side-panel {
        position: absolute;
        inset: 0 0 0 auto;
        width: var(--side-panel-width);
        z-index: 15; // above content
        &.side-panel-expanded {
            &.right {
                right: 0;
            }
            &.left {
                left: 0;
                &.side-panel-fullscreen {
                    left: 0;
                }
            }
        }
        &.side-panel-collapsed {
            &.right {
                right: calc(0px - var(--_side-panel-shift));
            }
            &.left {
                left: calc(-20px - var(--_side-panel-shift));
            }
        }
        &.side-panel-fullscreen {
            top: 0;
        }
        margin: $side-panel-margin $side-panel-margin 0 $side-panel-margin;
    }
    //#endregion
}
