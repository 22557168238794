@use '../../palette' as palette;

.dxy-dark {
    --color-foreground-any: #{palette.$reflex-10};
    --color-foreground-any-secondary: #{palette.$gray-50};
    --color-foreground-any-tertiary: #{palette.$gray-60};
    --color-foreground-any-inverted: #{palette.$reflex-100};

    --color-background-any: #{palette.$reflex-100};
    --color-background-any-secondary: #{palette.$gray-60};
    --color-background-any-tertiary: #{palette.$gray-50};
    --color-background-any-inverted: #{palette.$reflex-10};
    --color-background-any-placeholder: #{palette.$gray-60};

    --color-background-any-neutral: #{palette.$gray-60};
    --color-foreground-any-danger: #{palette.$magenta-10};
    --color-background-any-danger: #{palette.$magenta-50};
    --color-foreground-any-success: #{palette.$grass-10};
    --color-background-any-success: #{palette.$grass-50};
    --color-foreground-any-warning: #fff2cc;
    --color-background-any-warning: #b18400;

    --color-border-any: #{palette.$gray-60};
    --color-border-any-secondary: #{palette.$gray-10};
    --color-border-any-tertiary: #f0f0f7;
    --color-border-any--active: #{palette.$reflex-40};

    --color-path-tracker-primary: #{palette.$magenta-50};
    --color-path-tracker-primary-light: #{palette.$magenta-10};
    --color-path-tracker-secondary: #{palette.$blue-50};
    --color-path-tracker-secondary-light: #{palette.$blue-10};
    --color-path-tracker-tertiary: #{palette.$violet-50};
    --color-path-tracker-tertiary-light: #{palette.$violet-10};
}
