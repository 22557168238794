.grid-stack {
  position: relative;
}

.grid-stack-rtl {
  direction: ltr;
}

.grid-stack-rtl > .grid-stack-item {
  direction: rtl;
}

.grid-stack-placeholder > .placeholder-content {
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0;
  position: absolute;
  width: auto;
  z-index: 0 !important;
}

.grid-stack > .grid-stack-item {
  position: absolute;
  padding: 0;
}

.grid-stack > .grid-stack-item > .grid-stack-item-content {
  margin: 0;
  position: absolute;
  width: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.grid-stack > .grid-stack-item.size-to-content:not(.size-to-content-max) > .grid-stack-item-content {
  overflow-y: hidden;
}

.grid-stack-item > .ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}

.grid-stack-item.ui-resizable-autohide > .ui-resizable-handle, .grid-stack-item.ui-resizable-disabled > .ui-resizable-handle {
  display: none;
}

.grid-stack-item > .ui-resizable-ne, .grid-stack-item > .ui-resizable-nw, .grid-stack-item > .ui-resizable-se, .grid-stack-item > .ui-resizable-sw {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMS42MjYgNTExLjYyNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTExLjYyNiA1MTEuNjI3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTMyOC45MDYsNDAxLjk5NGgtMzYuNTUzVjEwOS42MzZoMzYuNTUzYzQuOTQ4LDAsOS4yMzYtMS44MDksMTIuODQ3LTUuNDI2YzMuNjEzLTMuNjE1LDUuNDIxLTcuODk4LDUuNDIxLTEyLjg0NSAgIGMwLTQuOTQ5LTEuODAxLTkuMjMxLTUuNDI4LTEyLjg1MWwtNzMuMDg3LTczLjA5QzI2NS4wNDQsMS44MDksMjYwLjc2LDAsMjU1LjgxMywwYy00Ljk0OCwwLTkuMjI5LDEuODA5LTEyLjg0Nyw1LjQyNCAgIGwtNzMuMDg4LDczLjA5Yy0zLjYxOCwzLjYxOS01LjQyNCw3LjkwMi01LjQyNCwxMi44NTFjMCw0Ljk0NiwxLjgwNyw5LjIyOSw1LjQyNCwxMi44NDVjMy42MTksMy42MTcsNy45MDEsNS40MjYsMTIuODUsNS40MjYgICBoMzYuNTQ1djI5Mi4zNThoLTM2LjU0MmMtNC45NTIsMC05LjIzNSwxLjgwOC0xMi44NSw1LjQyMWMtMy42MTcsMy42MjEtNS40MjQsNy45MDUtNS40MjQsMTIuODU0ICAgYzAsNC45NDUsMS44MDcsOS4yMjcsNS40MjQsMTIuODQ3bDczLjA4OSw3My4wODhjMy42MTcsMy42MTcsNy44OTgsNS40MjQsMTIuODQ3LDUuNDI0YzQuOTUsMCw5LjIzNC0xLjgwNywxMi44NDktNS40MjQgICBsNzMuMDg3LTczLjA4OGMzLjYxMy0zLjYyLDUuNDIxLTcuOTAxLDUuNDIxLTEyLjg0N2MwLTQuOTQ4LTEuODA4LTkuMjMyLTUuNDIxLTEyLjg1NCAgIEMzMzguMTQyLDQwMy44MDIsMzMzLjg1Nyw0MDEuOTk0LDMyOC45MDYsNDAxLjk5NHoiIGZpbGw9IiM2NjY2NjYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-position: center;
}

.grid-stack-item > .ui-resizable-ne {
  transform: translate(0, 10px) rotate(45deg);
}

.grid-stack-item > .ui-resizable-sw {
  transform: rotate(45deg);
}

.grid-stack-item > .ui-resizable-nw {
  transform: translate(0, 10px) rotate(-45deg);
}

.grid-stack-item > .ui-resizable-se {
  transform: rotate(-45deg);
}

.grid-stack-item > .ui-resizable-nw {
  cursor: nw-resize;
  width: 20px;
  height: 20px;
  top: 0;
}

.grid-stack-item > .ui-resizable-n {
  cursor: n-resize;
  height: 10px;
  top: 0;
  left: 25px;
  right: 25px;
}

.grid-stack-item > .ui-resizable-ne {
  cursor: ne-resize;
  width: 20px;
  height: 20px;
  top: 0;
}

.grid-stack-item > .ui-resizable-e {
  cursor: e-resize;
  width: 10px;
  top: 15px;
  bottom: 15px;
}

.grid-stack-item > .ui-resizable-se {
  cursor: se-resize;
  width: 20px;
  height: 20px;
}

.grid-stack-item > .ui-resizable-s {
  cursor: s-resize;
  height: 10px;
  left: 25px;
  bottom: 0;
  right: 25px;
}

.grid-stack-item > .ui-resizable-sw {
  cursor: sw-resize;
  width: 20px;
  height: 20px;
}

.grid-stack-item > .ui-resizable-w {
  cursor: w-resize;
  width: 10px;
  top: 15px;
  bottom: 15px;
}

.grid-stack-item.ui-draggable-dragging > .ui-resizable-handle {
  display: none !important;
}

.grid-stack-item.ui-draggable-dragging {
  will-change: left, top;
  cursor: move;
}

.grid-stack-item.ui-resizable-resizing {
  will-change: width, height;
}

.ui-draggable-dragging, .ui-resizable-resizing {
  z-index: 10000;
}

.ui-draggable-dragging > .grid-stack-item-content, .ui-resizable-resizing > .grid-stack-item-content {
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.grid-stack-animate, .grid-stack-animate .grid-stack-item {
  transition: left 0.3s, top 0.3s, height 0.3s, width 0.3s;
}

.grid-stack-animate .grid-stack-item.grid-stack-placeholder, .grid-stack-animate .grid-stack-item.ui-draggable-dragging, .grid-stack-animate .grid-stack-item.ui-resizable-resizing {
  transition: left 0s, top 0s, height 0s, width 0s;
}

.grid-stack > .grid-stack-item[gs-y="0"] {
  top: 0;
}

.grid-stack > .grid-stack-item[gs-x="0"] {
  left: 0;
}

.gs-12 > .grid-stack-item {
  width: 8.333%;
}

.gs-12 > .grid-stack-item[gs-x="1"] {
  left: 8.333%;
}

.gs-12 > .grid-stack-item[gs-w="2"] {
  width: 16.667%;
}

.gs-12 > .grid-stack-item[gs-x="2"] {
  left: 16.667%;
}

.gs-12 > .grid-stack-item[gs-w="3"] {
  width: 25%;
}

.gs-12 > .grid-stack-item[gs-x="3"] {
  left: 25%;
}

.gs-12 > .grid-stack-item[gs-w="4"] {
  width: 33.333%;
}

.gs-12 > .grid-stack-item[gs-x="4"] {
  left: 33.333%;
}

.gs-12 > .grid-stack-item[gs-w="5"] {
  width: 41.667%;
}

.gs-12 > .grid-stack-item[gs-x="5"] {
  left: 41.667%;
}

.gs-12 > .grid-stack-item[gs-w="6"] {
  width: 50%;
}

.gs-12 > .grid-stack-item[gs-x="6"] {
  left: 50%;
}

.gs-12 > .grid-stack-item[gs-w="7"] {
  width: 58.333%;
}

.gs-12 > .grid-stack-item[gs-x="7"] {
  left: 58.333%;
}

.gs-12 > .grid-stack-item[gs-w="8"] {
  width: 66.667%;
}

.gs-12 > .grid-stack-item[gs-x="8"] {
  left: 66.667%;
}

.gs-12 > .grid-stack-item[gs-w="9"] {
  width: 75%;
}

.gs-12 > .grid-stack-item[gs-x="9"] {
  left: 75%;
}

.gs-12 > .grid-stack-item[gs-w="10"] {
  width: 83.333%;
}

.gs-12 > .grid-stack-item[gs-x="10"] {
  left: 83.333%;
}

.gs-12 > .grid-stack-item[gs-w="11"] {
  width: 91.667%;
}

.gs-12 > .grid-stack-item[gs-x="11"] {
  left: 91.667%;
}

.gs-12 > .grid-stack-item[gs-w="12"] {
  width: 100%;
}

.gs-1 > .grid-stack-item {
  width: 100%;
}

.gs-2 > .grid-stack-item {
  width: 50%;
}

.gs-2 > .grid-stack-item[gs-x="1"] {
  left: 50%;
}

.gs-2 > .grid-stack-item[gs-w="2"] {
  width: 100%;
}

.gs-3 > .grid-stack-item {
  width: 33.333%;
}

.gs-3 > .grid-stack-item[gs-x="1"] {
  left: 33.333%;
}

.gs-3 > .grid-stack-item[gs-w="2"] {
  width: 66.667%;
}

.gs-3 > .grid-stack-item[gs-x="2"] {
  left: 66.667%;
}

.gs-3 > .grid-stack-item[gs-w="3"] {
  width: 100%;
}

.gs-4 > .grid-stack-item {
  width: 25%;
}

.gs-4 > .grid-stack-item[gs-x="1"] {
  left: 25%;
}

.gs-4 > .grid-stack-item[gs-w="2"] {
  width: 50%;
}

.gs-4 > .grid-stack-item[gs-x="2"] {
  left: 50%;
}

.gs-4 > .grid-stack-item[gs-w="3"] {
  width: 75%;
}

.gs-4 > .grid-stack-item[gs-x="3"] {
  left: 75%;
}

.gs-4 > .grid-stack-item[gs-w="4"] {
  width: 100%;
}

.gs-5 > .grid-stack-item {
  width: 20%;
}

.gs-5 > .grid-stack-item[gs-x="1"] {
  left: 20%;
}

.gs-5 > .grid-stack-item[gs-w="2"] {
  width: 40%;
}

.gs-5 > .grid-stack-item[gs-x="2"] {
  left: 40%;
}

.gs-5 > .grid-stack-item[gs-w="3"] {
  width: 60%;
}

.gs-5 > .grid-stack-item[gs-x="3"] {
  left: 60%;
}

.gs-5 > .grid-stack-item[gs-w="4"] {
  width: 80%;
}

.gs-5 > .grid-stack-item[gs-x="4"] {
  left: 80%;
}

.gs-5 > .grid-stack-item[gs-w="5"] {
  width: 100%;
}

.gs-6 > .grid-stack-item {
  width: 16.667%;
}

.gs-6 > .grid-stack-item[gs-x="1"] {
  left: 16.667%;
}

.gs-6 > .grid-stack-item[gs-w="2"] {
  width: 33.333%;
}

.gs-6 > .grid-stack-item[gs-x="2"] {
  left: 33.333%;
}

.gs-6 > .grid-stack-item[gs-w="3"] {
  width: 50%;
}

.gs-6 > .grid-stack-item[gs-x="3"] {
  left: 50%;
}

.gs-6 > .grid-stack-item[gs-w="4"] {
  width: 66.667%;
}

.gs-6 > .grid-stack-item[gs-x="4"] {
  left: 66.667%;
}

.gs-6 > .grid-stack-item[gs-w="5"] {
  width: 83.333%;
}

.gs-6 > .grid-stack-item[gs-x="5"] {
  left: 83.333%;
}

.gs-6 > .grid-stack-item[gs-w="6"] {
  width: 100%;
}

.gs-7 > .grid-stack-item {
  width: 14.286%;
}

.gs-7 > .grid-stack-item[gs-x="1"] {
  left: 14.286%;
}

.gs-7 > .grid-stack-item[gs-w="2"] {
  width: 28.571%;
}

.gs-7 > .grid-stack-item[gs-x="2"] {
  left: 28.571%;
}

.gs-7 > .grid-stack-item[gs-w="3"] {
  width: 42.857%;
}

.gs-7 > .grid-stack-item[gs-x="3"] {
  left: 42.857%;
}

.gs-7 > .grid-stack-item[gs-w="4"] {
  width: 57.143%;
}

.gs-7 > .grid-stack-item[gs-x="4"] {
  left: 57.143%;
}

.gs-7 > .grid-stack-item[gs-w="5"] {
  width: 71.429%;
}

.gs-7 > .grid-stack-item[gs-x="5"] {
  left: 71.429%;
}

.gs-7 > .grid-stack-item[gs-w="6"] {
  width: 85.714%;
}

.gs-7 > .grid-stack-item[gs-x="6"] {
  left: 85.714%;
}

.gs-7 > .grid-stack-item[gs-w="7"] {
  width: 100%;
}

.gs-8 > .grid-stack-item {
  width: 12.5%;
}

.gs-8 > .grid-stack-item[gs-x="1"] {
  left: 12.5%;
}

.gs-8 > .grid-stack-item[gs-w="2"] {
  width: 25%;
}

.gs-8 > .grid-stack-item[gs-x="2"] {
  left: 25%;
}

.gs-8 > .grid-stack-item[gs-w="3"] {
  width: 37.5%;
}

.gs-8 > .grid-stack-item[gs-x="3"] {
  left: 37.5%;
}

.gs-8 > .grid-stack-item[gs-w="4"] {
  width: 50%;
}

.gs-8 > .grid-stack-item[gs-x="4"] {
  left: 50%;
}

.gs-8 > .grid-stack-item[gs-w="5"] {
  width: 62.5%;
}

.gs-8 > .grid-stack-item[gs-x="5"] {
  left: 62.5%;
}

.gs-8 > .grid-stack-item[gs-w="6"] {
  width: 75%;
}

.gs-8 > .grid-stack-item[gs-x="6"] {
  left: 75%;
}

.gs-8 > .grid-stack-item[gs-w="7"] {
  width: 87.5%;
}

.gs-8 > .grid-stack-item[gs-x="7"] {
  left: 87.5%;
}

.gs-8 > .grid-stack-item[gs-w="8"] {
  width: 100%;
}

.gs-9 > .grid-stack-item {
  width: 11.111%;
}

.gs-9 > .grid-stack-item[gs-x="1"] {
  left: 11.111%;
}

.gs-9 > .grid-stack-item[gs-w="2"] {
  width: 22.222%;
}

.gs-9 > .grid-stack-item[gs-x="2"] {
  left: 22.222%;
}

.gs-9 > .grid-stack-item[gs-w="3"] {
  width: 33.333%;
}

.gs-9 > .grid-stack-item[gs-x="3"] {
  left: 33.333%;
}

.gs-9 > .grid-stack-item[gs-w="4"] {
  width: 44.444%;
}

.gs-9 > .grid-stack-item[gs-x="4"] {
  left: 44.444%;
}

.gs-9 > .grid-stack-item[gs-w="5"] {
  width: 55.556%;
}

.gs-9 > .grid-stack-item[gs-x="5"] {
  left: 55.556%;
}

.gs-9 > .grid-stack-item[gs-w="6"] {
  width: 66.667%;
}

.gs-9 > .grid-stack-item[gs-x="6"] {
  left: 66.667%;
}

.gs-9 > .grid-stack-item[gs-w="7"] {
  width: 77.778%;
}

.gs-9 > .grid-stack-item[gs-x="7"] {
  left: 77.778%;
}

.gs-9 > .grid-stack-item[gs-w="8"] {
  width: 88.889%;
}

.gs-9 > .grid-stack-item[gs-x="8"] {
  left: 88.889%;
}

.gs-9 > .grid-stack-item[gs-w="9"] {
  width: 100%;
}

.gs-10 > .grid-stack-item {
  width: 10%;
}

.gs-10 > .grid-stack-item[gs-x="1"] {
  left: 10%;
}

.gs-10 > .grid-stack-item[gs-w="2"] {
  width: 20%;
}

.gs-10 > .grid-stack-item[gs-x="2"] {
  left: 20%;
}

.gs-10 > .grid-stack-item[gs-w="3"] {
  width: 30%;
}

.gs-10 > .grid-stack-item[gs-x="3"] {
  left: 30%;
}

.gs-10 > .grid-stack-item[gs-w="4"] {
  width: 40%;
}

.gs-10 > .grid-stack-item[gs-x="4"] {
  left: 40%;
}

.gs-10 > .grid-stack-item[gs-w="5"] {
  width: 50%;
}

.gs-10 > .grid-stack-item[gs-x="5"] {
  left: 50%;
}

.gs-10 > .grid-stack-item[gs-w="6"] {
  width: 60%;
}

.gs-10 > .grid-stack-item[gs-x="6"] {
  left: 60%;
}

.gs-10 > .grid-stack-item[gs-w="7"] {
  width: 70%;
}

.gs-10 > .grid-stack-item[gs-x="7"] {
  left: 70%;
}

.gs-10 > .grid-stack-item[gs-w="8"] {
  width: 80%;
}

.gs-10 > .grid-stack-item[gs-x="8"] {
  left: 80%;
}

.gs-10 > .grid-stack-item[gs-w="9"] {
  width: 90%;
}

.gs-10 > .grid-stack-item[gs-x="9"] {
  left: 90%;
}

.gs-10 > .grid-stack-item[gs-w="10"] {
  width: 100%;
}

.gs-11 > .grid-stack-item {
  width: 9.091%;
}

.gs-11 > .grid-stack-item[gs-x="1"] {
  left: 9.091%;
}

.gs-11 > .grid-stack-item[gs-w="2"] {
  width: 18.182%;
}

.gs-11 > .grid-stack-item[gs-x="2"] {
  left: 18.182%;
}

.gs-11 > .grid-stack-item[gs-w="3"] {
  width: 27.273%;
}

.gs-11 > .grid-stack-item[gs-x="3"] {
  left: 27.273%;
}

.gs-11 > .grid-stack-item[gs-w="4"] {
  width: 36.364%;
}

.gs-11 > .grid-stack-item[gs-x="4"] {
  left: 36.364%;
}

.gs-11 > .grid-stack-item[gs-w="5"] {
  width: 45.455%;
}

.gs-11 > .grid-stack-item[gs-x="5"] {
  left: 45.455%;
}

.gs-11 > .grid-stack-item[gs-w="6"] {
  width: 54.545%;
}

.gs-11 > .grid-stack-item[gs-x="6"] {
  left: 54.545%;
}

.gs-11 > .grid-stack-item[gs-w="7"] {
  width: 63.636%;
}

.gs-11 > .grid-stack-item[gs-x="7"] {
  left: 63.636%;
}

.gs-11 > .grid-stack-item[gs-w="8"] {
  width: 72.727%;
}

.gs-11 > .grid-stack-item[gs-x="8"] {
  left: 72.727%;
}

.gs-11 > .grid-stack-item[gs-w="9"] {
  width: 81.818%;
}

.gs-11 > .grid-stack-item[gs-x="9"] {
  left: 81.818%;
}

.gs-11 > .grid-stack-item[gs-w="10"] {
  width: 90.909%;
}

.gs-11 > .grid-stack-item[gs-x="10"] {
  left: 90.909%;
}

.gs-11 > .grid-stack-item[gs-w="11"] {
  width: 100%;
}