@use 'base/text' as text;

.dg_suggestionContainer-mainContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .suggestions-list {
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }

    .section-content {
        width: 100%;
    }

    .sub-section-title,
    .suggestion-item {
        min-height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;

        &:hover {
            background-color: var(--color-background-any--hover);
            app-suggestion-choice-actions {
                display: flex;
            }
        }

        app-suggestion-choice-actions {
            display: none;
        }

        .text {
            flex: 1;
        }
    }

    .sub-section-title .text {
        color: var(--color-background-any-secondary);
        font-weight: 700;
        font-size: text.$small-text-size;
    }

    .suggestion-items {
        margin: 0;
        padding: 0;

        dxy-color-point-cell {
            margin-right: 5px;
        }
    }
}
