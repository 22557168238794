@use 'graphical-toolbars.global' as *;
@use 'palette';

.graphical-selector {
    &.mat-menu-panel {
        box-shadow: $toolbar-box-shadow;
    }
    .mat-menu-trigger::before {
        display: none;
    }
    .columns {
        display: flex;
        flex-direction: row;
    }
    .column {
        display: flex;
        flex-direction: column;
        padding: 7px;
        &.sep {
            border-left: 1px solid palette.$reflex-08;
            margin: 14px 0;
            padding: 0;
        }
    }
    .rows {
        display: flex;
        flex-direction: column;
    }
    .row {
        display: flex;
        flex-direction: row;
        padding: 7px;
        &.sep {
            border-bottom: 1px solid palette.$reflex-08;
            margin: 0 14px;
            padding: 0;
        }
    }
    .select-item {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .select-item {
        margin: 7px;
        &:not(.dxy-icon-button) {
            width: 54px;
        }
        cursor: pointer;
        align-content: center;
        &.selected {
            color: palette.$reflex-50;
            path {
                stroke: palette.$reflex-50;
            }
            cursor: default;
        }
    }
    .label {
        margin-left: 7px;
    }
}
