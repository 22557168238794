@use '../../../../styles/global/base/mixin-flex.scss';

dxy-tab-view {
    &.alternative {
        > .tab-header > .mat-tab-group > .mat-tab-header {
            padding-left: 20px;
        }
    }

    [ui-view],
    ui-view {
        //#region ex .vcontainerDiv-overflowAuto (index.scss)
        @include mixin-flex.flexV();
        min-height: 0;
        overflow: auto;
        //#endregion

        //#region ex .flex-grow (helper.scss)
        width: 100%;
        @include mixin-flex.flexItem(1);
        //#endregion
    }

    .tab-inner {
        padding: 10px;
        flex-grow: 1;
        overflow: auto;
        &.small > * {
            max-width: 520px;
        }
        &.no-padding-bottom {
            padding-bottom: 0;
        }
        h2 {
            margin: 10px 0;
        }
    }
}
