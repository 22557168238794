.mat-menu-panel.dxy-navbar-profile-dropdown--mat-menu {
    width: 300px;
    max-width: 300px;
    margin-right: -10px;
    position: absolute;
    top: 0;
    right: 20px;
    max-height: 100%;
}

.nav-profile-menu-btn {
    dxy-user-tag {
        cursor: pointer;
    }
}
