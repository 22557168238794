@use 'palette';

app-attribute-quick-filters {
    .filters {
        .dg5-filter-item {
            cursor: pointer;
            background: var(--color-background-any);
            height: 34px;
            padding: 4px 8px;
            max-width: 95px;
            margin-right: 8px;

            &:hover {
                background-color: palette.$reflex-08;
            }
        }
    }
}
