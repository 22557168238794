dxy-field-multi-select {
    &.no-underline {
        .mat-form-field-underline {
            height: 0;
        }
        .mat-form-field-ripple {
            display: none;
        }
    }

    &.no-padding {
        .mat-form-field-wrapper,
        .mat-form-field-flex {
            padding: 0;
        }
        .mat-form-field-infix {
            padding: 0;
            border: none;
        }
    }
}
