.glyph-proposed {
    color: var(--color-neutral-default) !important;
}
.glyph-inrevision {
    color: var(--color-warning-default) !important;
}
.glyph-invalidation {
    color: var(--color-info-default) !important;
}
.glyph-validated {
    color: var(--color-success-default) !important;
}
.glyph-obsolete {
    color: var(--color-danger-default) !important;
}
