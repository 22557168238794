@use 'base/text' as text;
@use 'palette';

dxy-image-input {
    --imageMaxSizePx: 700px;
    --imageWidthPx: var(--imageMaxSizePx);
    --imageHeightPx: var(--imageMaxSizePx);
    --editorHeightPx: 250px;
    --editorMaxWidthPx: unset;

    &.big-initials {
        .initials {
            font-size: 48px;
        }
    }

    .wrapper {
        position: relative;
        max-width: var(--imageMaxSizePx);
        max-height: var(--imageMaxSizePx);
        .overlay {
            position: absolute;
            inset: 0;
            background-color: rgba(2, 42, 142, 0.5);
            &.round {
                border-radius: 50%;
            }
            &.off {
                opacity: 0;
            }
            transition: opacity 0.5s;
            .dropzone {
                position: absolute;
                inset: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 3px;
                padding: 10px;
                border: 1px dashed white;
                color: hsla(0, 0%, 100%, 0.8);
                &.round {
                    border-radius: 50%;
                }
                .text {
                    pointer-events: none;
                    text-align: center;
                }
            }
            .action-menu {
                display: block;
                flex: 0 auto;
                position: absolute;
                left: auto;
                top: 0;
                right: 0;
                bottom: auto !important;
                margin: 8px;
                height: 30px;
                .dg5-dropdown-icon-button {
                    background-color: #e5e8ff;
                    border-radius: 50%;
                }
                .clickable-line .glyph {
                    margin-right: 10px;
                }
            }
        }
        .placeholder {
            height: var(--imageHeightPx);
            width: var(--imageWidthPx);
            text-align: center;
            line-height: var(--imageHeightPx);
            &.round {
                border-radius: 50%;
            }
            font-weight: 700;
            color: white;
        }
        .image {
            object-fit: contain;
            object-position: top left;
            max-width: var(--imageMaxSizePx);
            max-height: var(--imageMaxSizePx);
            &.round {
                border-radius: 50%;
            }
        }
    }

    .editor {
        display: flex;
        flex-grow: 1;
        height: var(--editorHeightPx);
        &.in-modal {
            height: max(600px, var(--editorHeightPx));
        }
        &.overlay {
            max-width: var(--editorMaxWidthPx);
        }
        &[hidden] {
            display: none;
        }

        doka-image-editor,
        .doka-image-editor {
            --font-family: text.$font-family;
            --font-size: text.$big-text-size;
            &.DokaRoot {
                color: palette.$reflex-100;
                .DokaButtonExport {
                    background-color: var(--color-text-any-primary);
                    border-radius: 5px !important;
                    color: #fff !important;
                    box-shadow: none !important;
                }
                .DokaTabList button {
                    border-radius: 5px !important;
                    box-shadow: none !important;
                    border: 1px solid palette.$reflex-08;
                    &:hover {
                        background: palette.$reflex-08;
                    }
                    span {
                        font-size: text.$small-text-size;
                        margin-top: 0px !important;
                    }
                    &[aria-selected='true'] {
                        background-color: palette.$reflex-08 !important;
                        backdrop-filter: none !important;
                    }
                }
            }
        }
    }
}
