@use 'base/text' as text;

app-linked-object-modal {
    .link-selector dxy-option-list {
        flex: 1;
        .dropdown {
            flex: 1;
            height: 50px;
            display: flex;
        }
        button.selected {
            justify-content: flex-end;
            background: transparent;
        }
    }
}

.mat-menu-panel.dxy-option-list--mat-menu {
    width: 300px;
    ul {
        overflow-y: auto;
    }
}
