dxy-field-radio {
    &.multi-line {
        mat-radio-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            mat-radio-button {
                margin-left: 0px;
                flex-basis: 30px;
                display: flex;
            }
        }
    }
    &.no-underline {
        .mat-form-field-underline {
            display: none;
        }
        .mat-form-field-infix {
            padding-bottom: 0;
        }
    }
    &.no-padding {
        .mat-form-field-infix {
            border-top-width: 0px;
            padding-top: 0px !important;
        }
        .mat-form-field-wrapper {
            padding-bottom: 0px;
        }
    }
}
