mat-checkbox {
    display: flex !important;
    align-items: center;

    &.checkbox-small,
    &.checkbox-xs {
        .mat-ripple-element {
            left: 0 !important;
            top: 0 !important;
        }
        .mat-checkbox-frame {
            border-radius: 1px;
        }
    }
    &.checkbox-small {
        .mat-checkbox-inner-container {
            transform: scale(0.875) !important;
        }
    }
    &.checkbox-xs {
        .mat-checkbox-inner-container {
            transform: scale(0.65) !important;
        }
    }

    .mat-checkbox-frame {
        border-color: var(--color-foreground-any-tertiary) !important;
    }

    &.checkbox-overflow-hidden {
        overflow: hidden;
        label,
        .mat-checkbox-label {
            overflow: hidden;
        }
    }

    .mat-checkbox-layout {
        flex: 1;
        margin-bottom: 0;
    }

    .mat-checkbox-label {
        display: flex;
        flex: 1;
        align-items: center;
        font-weight: 450;
    }
}

mat-pseudo-checkbox {
    color: var(--color-foreground-any-tertiary) !important;
}
