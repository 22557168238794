@use '../mixins';

.dg5-popover {
    @include mixins.menu-panel();
    min-height: unset;
    padding: 0;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    transition:
        visibility 0.3s ease-out,
        opacity 0.3s ease-out;

    &.open {
        visibility: visible;
        opacity: 1;
    }
}
