$toolbar-from-side: 20px;
$toolbar-size: 44px; // width for vertical, height for horizontal
$toolbar-padding: 3px;
$toolbar-end-padding: 7px;
$arrow-expanded: 0px; // from opposite side
$arrow-collapsed: 10px; // from opposite side
$button-size: 32px;
$button-margin: 3px;
$separator-length: 20px;
$separator-border: 1px solid rgba(2, 42, 142, 0.1);
$toolbar-box-shadow: 0 0 14px 0 #3d5cfe22; // $dg5-reflex with alpha #22
