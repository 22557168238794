@use '../../palette' as palette;

body {
    --color-foreground-any: #{palette.$reflex-100};
    --color-foreground-any-secondary: #{palette.$gray-60};
    --color-foreground-any-tertiary: #{palette.$gray-50};
    --color-foreground-any-inverted: #{palette.$reflex-10};

    --color-background-any-secondary: #{palette.$reflex-10};
    --color-background-any-tertiary: #{palette.$gray-05};
    --color-background-any-inverted: #{palette.$reflex-100};
    --color-background-any--active: #{palette.$reflex-08};
    --color-background-any--hover: #{palette.$reflex-05};
    --color-background-any-placeholder: #{palette.$gray-10};

    --color-background-any-neutral: #{palette.$gray-05};
    --color-foreground-any-danger: #{palette.$magenta-50};
    --color-background-any-danger: #{palette.$magenta-10};
    --color-background-any-danger--active: #{palette.$magenta-gray-05};
    --color-foreground-any-success: #{palette.$grass-50};
    --color-background-any-success: #{palette.$grass-10};
    --color-foreground-any-warning: #b18400;
    --color-background-any-warning: #fff2cc;

    --color-foreground-any-ai: #{palette.$violet-60};
    --color-background-any-ai: #{palette.$violet-05};
    --color-background-any-ai--active: #{palette.$violet-05};

    --color-shadow-any: #{palette.$reflex-08};
    --color-shadow-any-neutral: #{0 8px 12px 0 var(--color-shadow-any),
        0 2px 4px 0 var(--color-shadow-any)};
    --color-shadow-any-large: #{0 0px 30px 0 var(--color-shadow-any)};
    --color-shadow-any-light: #{0 2px 4px 0 var(--color-shadow-any),
        0 0 2px 0 var(--color-shadow-any)};

    --color-border-any: #{palette.$gray-10};
    --color-border-any-secondary: #{palette.$gray-40};
    --color-border-any-tertiary: #{palette.$gray-60};
    --color-border-any--active: #{palette.$reflex-40};

    --color-text-any-primary: #{palette.$reflex-60};
    --color-text-any-lightPrimary: #{palette.$reflex-50};
    --color-text-any--disabled: #{palette.$gray-50};
}
