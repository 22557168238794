@use 'glyphs' as *;
@use 'palette';

$graphical-color-gray: palette.$gray-60;

// from Ui-kit (2022-08-08)
// Those are names of GraphicalColor enum values
$graphical-colors: (
    'Black': #000000,
    'White': #ffffff,

    'Indigo': #5b24b2,
    'Ruby': #9c01ad,
    'Magenta': #ca004a,
    'Orange': #ff811a,

    'Wood': #12884b,
    'Green': #50c516,
    'Lime': #c8e200,
    'Yellow': #ffbf00,

    'Reflex': palette.$reflex-60,
    'Reflex2': #3d5cfe,
    'Blue': #28aae2,
    'Turquoise': #11d4b7,

    'Gray': $graphical-color-gray,
    // used for diagram links
    'LightGray': palette.$gray-50, // used for diagram frames
);

.graphical-color {
    @each $name, $color in $graphical-colors {
        &.#{$name} {
            color: $color;
            &.soft {
                color: rgba($color, 0.3);
            }
        }
    }

    &.undefined,
    &.White {
        &:before {
            content: $glyph-circle;
            color: var(--color-foreground-any-tertiary);
        }
    }
    &._multi {
        color: unset;
        &:before {
            content: $glyph-circle-dashed;
        }
    }
}

.graphical-color-border {
    @each $name, $color in $graphical-colors {
        &.#{$name} {
            border-color: $color;
            &.soft {
                border-color: rgba($color, 0.3);
            }
        }
    }
}
.graphical-color-background-before {
    @each $name, $color in $graphical-colors {
        &.#{$name} {
            border-color: $color;
            &.soft {
                background-color: rgba($color, 0.3);
            }
        }
    }
}

.graphical-color-background {
    @each $name, $color in $graphical-colors {
        &.#{$name} {
            background-color: $color;
            &.soft {
                background-color: rgba($color, 0.3);
            }
        }
    }

    &.Black {
        color: white;
    }
    &.White {
        color: black;
    }
    &.Indigo {
        color: white;
    }
    &.Ruby {
        color: white;
    }
    &.Magenta {
        color: white;
    }
    &.Reflex2 {
        color: white;
    }
    &.Blue {
        color: black;
    }
    &.Turquoise {
        color: black;
    }
    &.Wood {
        color: white;
    }
    &.Green {
        color: black;
    }
    &.Lime {
        color: black;
    }
    &.Orange {
        color: black;
    }
    &.Yellow {
        color: black;
    }
    &.Gray {
        color: black;
    }
    &.LightGray {
        color: black;
    }
    &.Reflex {
        color: white;
    }
}

.graphical-color,
.graphical-color-border,
.graphical-color-background-before,
.graphical-color-background {
    /**
     * Disabled since it cause blurry effect across different layers in diagrams
     * It was working with jsplumb but not on nojsplumb surface
     */
    //&.soft { mix-blend-mode: multiply; }
}
