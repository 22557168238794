/** https://datagalaxy-uikit-v1.webflow.io/ui-kit#fonts */
$font-family: Montserrat, arial, sans-serif;

$tiny-text-size: 9px;
$tiny-line-height: 16px;

$small-text-size: 11px;
$small-line-height: 17px;

$normal-text-size: 13px;
$normal-line-height: 20px;

$big-text-size: 15px;
$big-line-height: 22px;

$very-big-text-size: 24px;
$very-big-title-line-height: 31px;

$title-text-size: 17px;
$title-line-height: 24px;

$large-title-text-size: 22px;
$large-title-line-height: 27px;
