@use 'palette';
@use 'base/text' as text;
@use 'mixins' as *;

app-dp-implem-element {
    .container-box {
        width: 100%;
        overflow: visible;
        box-shadow:
            0 0 2px var(--color-shadow-any),
            0 2px 4px var(--color-shadow-any);

        .container-header {
            overflow: visible;
            background-color: palette.$reflex-03;

            &:not(.is-expanded) {
                border-bottom: unset;
            }
            &.is-expanded {
                border-bottom-color: palette.$reflex-08;
            }
        }
    }

    .mapping-container--icon {
        color: palette.$blue-50;
        margin-right: 10px;
    }

    .mappingItem--table {
        display: flex;
        flex-direction: row;
        justify-content: stretch;

        .item-field,
        .item-actions {
            z-index: 1; // needed to be clickable - don't remove
            display: flex;
            flex: 1 1 auto;
            justify-content: center;
        }
        .item-field {
            @include ellipsis;
        }

        .item-name {
            justify-content: flex-start;
        }

        .item-actions {
            justify-content: flex-end;
            max-width: 20px;
            z-index: 3; // to be above draggable item and be clickable
        }
    }

    .mappingItem {
        @include ellipsis;
        padding: 5px 10px;
        align-items: center;
    }

    .container-header app-dp-mapping-item {
        // for burger menu icon not too inside when not hovered
        padding-right: 3px;
    }

    app-dp-mapping-item {
        display: block;

        :hover > .container-title .glyph-arrow-drop-down {
            visibility: visible;
        }

        .container-title {
            width: 100%;
            display: flex;
            flex-direction: row;
            white-space: normal;
            overflow: visible;

            .container-title-text {
                max-width: 100%;
                display: inline-flex;
                flex-grow: 1;
                overflow: visible;
                float: left;
                white-space: normal;
                text-overflow: ellipsis;
            }
            .glyph-arrow-drop-down {
                position: relative;
                right: 0;
                top: 3px;
                margin-right: 0;
            }
            .container-entity {
                flex: 1;
                overflow: hidden;
            }
            .container-action {
                gap: 3px;
                display: flex;
                align-items: center;
                padding-right: 5px;
            }
            .menu-trigger {
                z-index: 1049;
            }
        }

        .dg_navigation-breadcrumb.breadcrumb {
            margin-bottom: 2px;

            .glyph {
                color: var(--color-foreground-any-tertiary) !important;
                font-size: text.$small-text-size;
            }

            > li {
                overflow: hidden;
                text-overflow: ellipsis;
                min-height: 13px;
            }
        }

        .container-header {
            padding: 5px 10px;
        }
    }
}
