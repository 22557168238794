dxy-entity-bulk-edit-modal,
.attribute-form-container {
    dxy-attribute-rich-value-list-input {
        div.col-sm-9 {
            padding: 0px 0px 0px 0px;
        }

        .form-control {
            background-color: transparent !important;
            border: none !important;
            box-shadow: none !important;
            padding: 0px;
            font-size: 16px !important;
        }
    }
}
