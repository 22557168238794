@use 'base/text' as text;
@use 'palette';

//#region common
dxy-versioning-comparator,
dxy-versioning-comparator-single-object {
    .version-selection-wrapper {
        display: flex;
        > div {
            flex-grow: 1;
        }
        .comparison-label {
            font-size: text.$big-text-size;
            opacity: 0.5;
            margin: 0px 4px 0px 4px;
        }
    }

    .dg_versioning-comparator-content-wrapper {
        flex-grow: 1;
        overflow: auto;
    }

    .dg_versioning-comparator-empty-results {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        color: palette.$gray-60;
        font-size: text.$normal-text-size;
    }
}
//#endregion - common

dxy-versioning-comparator {
    overflow: auto;
    padding-top: 0 !important;

    dxy-tab-view {
        height: 100%;
        .tab-container {
            box-shadow: unset;
            .transclude-wrapper {
                width: 100%;
            }
        }
        .tab-content {
            flex-direction: column !important;
        }
    }
}

.versioning-comparator-section-container {
    overflow: auto;
}

dxy-versioning-comparator-single-object {
    display: flex;
    flex-direction: column;

    dxy-versioning-comparator-section {
        background-color: white;
    }

    .dg_versioning-for-docking-panel {
        justify-content: space-evenly;
        width: 100%;
        align-items: baseline;
        min-height: 30px;
        .version-name {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;

            mat-label {
                padding-right: 10px;
            }

            dxy-versioning-selector .displayname,
            > .name {
                line-height: 30px;
                font-weight: 600;
            }
        }
    }

    .version-selection-box {
        box-shadow:
            0 2px 14px palette.$reflex-08,
            0 0 4px rgba(2, 42, 142, 0.2);
        margin-bottom: 10px;
        padding: 20px;
        border-radius: 3px;
    }

    .current-version-col {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .current-version-row {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
    }

    .label-difference {
        color: orange;
        font-size: text.$big-text-size;
    }
}

.versioning-comparator-single-object-version-selector-menu {
    //mat-menu of versioning-selector used in dxy-versioning-comparator-single-object
    .difference-version {
        color: palette.$orange-50;
    }
    .inexistant-version {
        color: palette.$gray-50;
    }
}
