@use 'base/buttons' as buttons;
@use 'palette';

dxy-time-series-modal {
    .grid-container {
        flex-grow: 1;
        overflow: auto;
    }
    .time-series-graph {
        .y.axis {
            .domain,
            line {
                display: none;
            }
        }
        .x.axis {
            line {
                display: none;
            }
            .domain {
                stroke: palette.$reflex-08;
            }
        }
    }
}
