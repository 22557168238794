dxy-multiselect-list {
    .search-bar {
        padding: 0 0 0 16px !important;
    }
    .menu-section-scroll .cdk-virtual-scroll-content-wrapper {
        padding-top: var(--multiselect-list-header-height);
        right: 0;
    }
    mat-pseudo-checkbox {
        transform: scale(0.875);
    }

    .mat-list-item-content {
        padding: 5px 16px !important;
        min-height: 45px;
    }

    mat-tab-group {
        z-index: 2;
        background: var(--color-background-any);
    }
}
