/**
* $pos: 'n'|'ne'|'e'|'se'|'s'|'sw'|'w'|'nw'
* $dist: distance to side
* $prc: (only for n,s,e,w) percentage from opposite side (ie: 50% is middle)
*/
@mixin cardinal-position($pos, $dist: 20px, $prc: 50%) {
    @if $pos == 'n' {
        inset: $dist auto auto $prc;
        transform: translateX(-$prc);
    } @else if $pos == 'e' {
        inset: $prc $dist auto auto;
        transform: translateY(-$prc);
    } @else if $pos == 's' {
        inset: auto auto $dist $prc;
        transform: translateX(-$prc);
    } @else if $pos == 'w' {
        inset: $prc auto auto $dist;
        transform: translateY(-$prc);
    } @else if $pos == 'ne' {
        inset: $dist $dist auto auto;
    } @else if $pos == 'se' {
        inset: auto $dist $dist auto;
    } @else if $pos == 'sw' {
        inset: auto auto $dist $dist;
    } @else if $pos == 'nw' {
        inset: $dist auto auto $dist;
    }
}
