@use 'base/text' as text;
@use 'palette';

.dxy-dropdown-button-menu {
    .separator {
        height: 1px;
        margin: 2px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    }

    .dropdown-action {
        align-items: flex-start !important;
        flex-direction: column;
        height: unset !important;

        .dropdown-action-title {
            color: palette.$reflex-100;
            font-size: text.$big-text-size;
            line-height: 16px;
            min-height: 40px;
            display: flex;
            align-items: center;
            .glyph {
                margin-right: 5px;
            }
        }

        .dropdown-action-subtitle {
            display: block;
            min-height: 5px;
            margin-bottom: 10px;
            padding-right: 20px;
            align-self: stretch;
            color: palette.$gray-60;
            font-size: text.$small-text-size;
            line-height: 12px;
        }
    }
}
