@use 'palette';

.gs-node {
    --zs-node-selection-margin: 6px;

    &.no-layer {
        position: unset;
    }

    &.selected,
    &.highlighted {
        outline: 2px solid palette.$reflex-40;
        outline-offset: var(--zs-node-selection-margin);
    }
}

.selection-area {
    border: 1px solid palette.$reflex-40;
    box-sizing: content-box;
    background: rgba(133, 153, 254, 0.1);
}
