@use 'base/text' as text;
@use 'palette';

.dxy-field {
    &:not(.no-full-width) {
        width: 100%;
        display: flex;
        flex-direction: column;
        mat-form-field {
            width: 100%;
        }
    }

    &.no-full-width {
        .mat-form-field-subscript-wrapper {
            width: unset;
            .mat-hint {
                overflow: visible;
            }
        }
    }

    mat-form-field {
        &:not(.mat-form-field-disabled):not(.mat-form-field-invalid)
            label:not(.mat-radio-label):not(.mat-checkbox-layout) {
            color: palette.$reflex-60;
        }

        &.dg5-compact {
            .mat-form-field-label-wrapper label.mat-form-field-label {
                font-size: 13px !important;
            }
            .mat-form-field-wrapper {
                font-size: text.$small-text-size;
            }
        }

        label {
            font-weight: 700;
            line-height: 14px;
            font-size: text.$small-text-size;
            width: 100%;

            &.mat-form-field-label {
                font-size: text.$big-text-size;
                font-weight: 700;
                line-height: 14px;
                overflow: visible;
                display: flex !important;

                /* Allow mat-label to have tooltip on hover */
                mat-label {
                    display: block;
                    pointer-events: auto;
                }

                .mat-placeholder-required {
                    margin-left: 5px;
                }
            }
        }

        &.mat-form-field-appearance-fill {
            &:not(:hover):not(.mat-focused) {
                .mat-form-field-flex {
                    background-color: transparent;
                }
            }

            .mat-form-field-flex {
                transition: background-color 0.3s ease;
            }
        }

        &.mat-form-field-appearance-outline .mat-form-field-infix {
            border-top-width: 0.5em;
        }

        .mat-form-field-suffix,
        .mat-form-field-prefix {
            .mat-icon-button {
                height: 30px !important;
                width: 30px !important;
            }
            mat-icon.mat-icon {
                font-size: text.$big-text-size;
                color: palette.$gray-60 !important;

                .mat-icon-button {
                    color: palette.$gray-60 !important;
                }
            }
        }

        .mat-form-field-underline,
        .mat-form-field-ripple {
            background-color: palette.$reflex-08;
        }

        .mat-form-field-underline {
            position: relative;
            bottom: 0;
        }

        .mat-form-field-wrapper {
            padding-bottom: 5px;
        }
    }

    &.no-min-width mat-form-field {
        min-width: unset;

        .mat-form-field-infix {
            width: unset;
        }
    }

    &:not(.readonly-no-min-width) {
        .read-only-field {
            min-width: 180px;
        }
    }
    &:not(.readonly-no-bottom-margin) {
        .read-only-field {
            margin-bottom: 5px;
        }
    }

    .read-only-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 0.75em;
        cursor: default;

        label,
        mat-label {
            font-weight: 700;
            line-height: 14px;
            font-size: text.$small-text-size;
            width: fit-content;
            margin-bottom: 4px;
            &:not(.disabled) {
                color: palette.$reflex-60;
            }
            &.mat-form-field-label {
                transition: none;
                position: relative;
                display: block;
                top: unset;
            }
        }

        .value-container {
            line-height: 15px;
            min-height: 29px;
            padding: 7px 0;
            margin: 0;
            display: flex;
            align-items: center;
            cursor: default;

            .glyph {
                //margin-right: 10px;
            }
        }

        .read-only-separator + .mat-hint {
            margin-bottom: 0;
            margin-top: 5px;
        }
    }

    .read-only-separator {
        height: 1px;
        width: 100%;
        opacity: 0.42;
        background-color: palette.$reflex-08;
    }

    textarea {
        border: unset;
    }

    .mat-hint {
        font-size: 75%;
        display: block;
        margin-bottom: 6px;
    }

    .mat-form-field:has(.mat-error) + .mat-hint {
        margin-top: 1em;
    }

    .mat-form-field-subscript-wrapper {
        top: calc(100% - 1em);
    }
}
