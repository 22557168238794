@use 'base/buttons' as buttons;

dxy-entity-user-list-popover-tooltip {
    dxy-search-input .search-bar {
        input,
        input::placeholder,
        .glyph-search,
        .glyph-cancelsearch {
            color: var(--color-foreground-any-inverted) !important;
        }
        input {
            caret-color: var(--color-foreground-any-inverted);
        }
        border-color: var(--color-border-any-tertiary) !important;
    }
}
