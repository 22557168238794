@use 'mixins' as *;
@use 'palette';

dxy-navbar-breadcrumb {
    .breadcrumb-separator,
    dxy-space-version-selector .separator {
        @include breadcrumb-separator();
    }

    dxy-space-version-selector {
        &.is-full-dropdown {
            dxy-workspace-dropdown:hover,
            dxy-versioning-selector:hover {
                .selected-workspace,
                .menu-selected {
                    .displayname {
                        text-decoration: underline;
                        color: palette.$reflex-60;
                    }
                    button:after {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        dxy-workspace-dropdown,
        dxy-versioning-selector {
            &.space-selector,
            &.version-selector {
                margin: 0;
            }
            .displayname {
                font-size: 12px;
                &:hover {
                    text-decoration: underline;
                    color: palette.$reflex-60;
                }
            }
        }
    }

    dxy-option-list {
        &:hover.is-full-dropdown .selected button:after {
            height: 20px;
            width: 20px;
        }
        &:hover.is-full-dropdown .selected .text,
        .selected .text:hover {
            text-decoration: underline;
            color: palette.$reflex-60 !important;
            cursor: pointer;
        }
    }
}

.breadcrumb-badge {
    display: flex;
    align-items: center;
    &::after {
        position: relative;
        margin-left: 6px;
        margin-right: 2px;
    }
}
