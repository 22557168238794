@use '@angular/material' as mat;
@use 'base/text' as text;
@use 'mat-palette';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

//#region *** default theme ***

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dxy-app-primary: mat.define-palette(mat-palette.$dg-primary-colors);
$dxy-app-accent: mat.define-palette(mat-palette.$dg-primary-colors);

// The warn palette is optional (defaults to red).
$dxy-app-warn: mat.define-palette(mat-palette.$dg-warn-colors);

// custom creating theme function in order to replace default light/dark foreground & background
@function create-dxy-light-theme($dxy-primary, $dxy-accent, $dxy-warn) {
    @return (
        color: (
            primary: $dxy-app-primary,
            accent: $dxy-accent,
            warn: $dxy-app-warn,
            is-dark: false,
            foreground: mat-palette.$dg-light-foreground,
            background: mat-palette.$dg-light-background,
        )
    );
}

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dxy-app-theme: create-dxy-light-theme(
    $dxy-app-primary,
    $dxy-app-primary,
    $dxy-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include angular-material-theme($dxy-app-theme);
// OR:
// Include the theme styles for only specified components.
@include mat.legacy-core-theme($dxy-app-theme);
@include mat.legacy-button-theme($dxy-app-theme);
@include mat.legacy-checkbox-theme($dxy-app-theme);
@include mat.tooltip-theme($dxy-app-theme);
@include mat.legacy-menu-theme($dxy-app-theme);
@include mat.legacy-select-theme($dxy-app-theme);
@include mat.dialog-theme($dxy-app-theme);
@include mat.legacy-form-field-theme($dxy-app-theme);
@include mat.legacy-input-theme($dxy-app-theme);
@include mat.legacy-autocomplete-theme($dxy-app-theme);
@include mat.legacy-table-theme($dxy-app-theme);
@include mat.datepicker-theme($dxy-app-theme);
@include mat.legacy-radio-theme($dxy-app-theme);
@include mat.legacy-tabs-theme($dxy-app-theme);
@include mat.legacy-slider-theme($dxy-app-theme);
@include mat.tree-theme($dxy-app-theme);
@include mat.slide-toggle-theme($dxy-app-theme);
@include mat.expansion-theme($dxy-app-theme);

//#region ** custom font **
// doc: https://material.angular.io/guide/typography
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.

//TODO (fbo, vbo, xgo) define needed styles
$custom-typography: mat.define-typography-config(
    $font-family: text.$font-family,
    $headline-5: mat.define-typography-level(32px, 48px, 700),
    $headline-6: mat.define-typography-level(
            $font-size: 20px,
            $line-height: 32px,
            $font-weight: 700,
            $letter-spacing: normal,
        ),
    $body-2: mat.define-typography-level(text.$big-text-size, 24px, 450),
    $button: mat.define-typography-level(text.$normal-text-size, 36px, 450),
    $body-1: mat.define-typography-level(text.$normal-text-size, 1.125, 450),
);

$legacy-custom-typography: mat.define-legacy-typography-config(
    $font-family: text.$font-family,
    $headline: mat.define-typography-level(32px, 48px, 700),
    $body-1: mat.define-typography-level(text.$big-text-size, 24px, 450),
    $button: mat.define-typography-level(text.$normal-text-size, 36px, 450),
    $input: mat.define-typography-level(inherit, 1.125, 450),
);

// Override typography for all Angular Material, including mat-base-typography and all components.
//@include angular-material-typography($custom-typography);
// OR:
// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat.typography-hierarchy($custom-typography);
// and for specific Angular Material components.
@include mat.dialog-typography($custom-typography);
@include mat.legacy-button-typography($legacy-custom-typography);
@include mat.legacy-checkbox-typography($legacy-custom-typography);
@include mat.tooltip-typography($legacy-custom-typography);
@include mat.legacy-menu-typography($legacy-custom-typography);
@include mat.legacy-select-typography($legacy-custom-typography);
@include mat.legacy-form-field-typography($legacy-custom-typography);
@include mat.legacy-input-typography($legacy-custom-typography);
@include mat.legacy-autocomplete-typography($legacy-custom-typography);
@include mat.datepicker-typography($legacy-custom-typography);
@include mat.legacy-radio-typography($legacy-custom-typography);
@include mat.legacy-tabs-typography($legacy-custom-typography);
@include mat.expansion-typography($legacy-custom-typography);

//#endregion - custom font

//#endregion - default theme

//#region *** dark theme ***

// Define an alternate dark theme.
// custom creating theme function in order to replace default light/dark foreground & background
@function create-dxy-dark-theme($dxy-primary, $dxy-accent, $dxy-warn) {
    @return (
        primary: $dxy-app-primary,
        accent: $dxy-accent,
        warn: $dxy-app-warn,
        is-dark: true,
        foreground: mat-palette.$dg-dark-foreground,
        background: mat-palette.$dg-dark-background
    );
}

// If this selector is matched, this theme will be applied instead of light
// Include the dark color styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.dxy-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.dxy-dark-theme {
    $dxy-app-theme: create-dxy-dark-theme(
        $dxy-app-primary,
        $dxy-app-primary,
        $dxy-app-warn
    );
    /* TODO(mdc-migration): Remove all-legacy-component-colors once all legacy components are migrated*/
    @include mat.all-legacy-component-colors($dxy-app-theme);
    @include mat.all-component-colors($dxy-app-theme);
}

//#endregion - dark theme
