@use 'base/text' as text;
@use 'palette';
app-client-admin-details {
    dxy-space-version-selector {
        display: flex;
        box-shadow: none;
        border-style: solid;
        border-width: 1px;
        border-color: var(--color-border-filterButton);
        border-radius: 3px;
        margin-right: 10px;

        .space-selector,
        .version-selector {
            height: 28px;
            margin-right: 5px;
        }

        .separator {
            height: 28px;
            margin-right: 10px;
        }

        .space-label {
            font-size: text.$tiny-text-size;
            line-height: 10px;
            font-weight: 700;
        }

        .all-spaces-version-text {
            font-size: text.$normal-text-size;
            margin-right: 10px;
        }

        .space-selector.has-label {
            .workspace-icon {
                width: 22px;
                height: 22px;
                font-size: text.$tiny-text-size;
                margin-right: 5px;
            }
        }
    }
}
