@use 'glyphs' as *;
@use 'base/text' as text;
@use 'palette';

dxy-graphical-controls {
    &.loading {
        opacity: 0.25;
    }
}

//#region color definitions (global)
$lineage-path-tracker-blue: #84e0eb;
$lineage-path-tracker-red: #f99497;
//#endregion

app-lineage-graph {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;

    //#region color definitions
    $caret-circle-background-color: rgba(1, 36, 73, 0.3);
    $caret-circle-background-color-lzy: #dfe0e5;
    $lzy-caret-circle-outer-color: rgba(202, 0, 74, 0.3);
    $lzy-caret-circle-inner-color: white;
    //#endregion

    $text-color: palette.$reflex-100;
    $border-color: palette.$reflex-10;
    $link-color: palette.$reflex-60;
    $hellips-color: palette.$gray-60;
    $link-end-color: palette.$turquoise-50;

    $track0-box-color: #f0f0f7;
    $track0-link-color: #0e1a3b;
    $track1-box-color: $lineage-path-tracker-blue;
    $track1-link-color: #35a6c6;
    $track2-box-color: $lineage-path-tracker-red;
    $track2-link-color: #fb4e5a;
    $track12-box-color: #c682d4;
    $track12-link-color: #9500bd;
    $track10-box-color: #7ad0e2;
    $track10-link-color: #236789;
    $track20-box-color: #e68b92;
    $track20-link-color: #8c384a;
    $track120-box-color: #b879cc;
    $track120-link-color: #560385;
    $path-tracker-trackmore-color: #dddddd;
    //#endregion

    background-color: white; //$color-colorgrey;

    //#region drawing surface
    .d3div {
        flex: 1 1 auto;
        height: 100%;

        svg text {
            user-select: none;
            &::selection {
                background: none;
            }
        }

        /** hidden item boxes and links */
        g[data-id].hidden,
        g.link.hidden {
            visibility: hidden;
            pointer-events: none;
        }
        g[data-id].hidden.hell {
            visibility: unset;
            display: unset !important;
            .databox,
            .ghead,
            .ghead * {
                visibility: hidden;
                pointer-events: none;
            }
        }

        //#region fonts
        .expander-caret,
        .item-icon,
        .hover-icon-container {
            font-family: 'datagalaxy';
        }
        //#endregion

        //#region svg defs

        #caret-circle {
            fill: $caret-circle-background-color;
        }
        #caret-circle-lzy {
            fill: $caret-circle-background-color-lzy;
        }
        #lzy-caret-circle {
            .outer {
                fill: $lzy-caret-circle-outer-color;
            }
            .inner {
                fill: $lzy-caret-circle-inner-color;
            }
        }

        //#region link ends (dot, arrow)
        #link-dot,
        #link-arr {
            stroke-width: 2;
            fill: $link-end-color;
        }
        #link-dot > circle {
            fill: $link-color;
        }

        #link-dot-track,
        #link-arr-track {
            stroke-width: 1;
            fill: $link-end-color;
        }
        #link-dot-track > circle {
            fill: $link-color;
        }
        //#endregion

        .burger-icon,
        .preview-icon {
            cursor: pointer;
            circle {
                fill: inherit; //will take the value from the <use> element
                pointer-events: bounding-box;
            }
            // set by code, for firefox ESR68
            //text { font-size: 1.2rem; dominant-baseline: middle; text-anchor: middle; fill: $text-color; }
        }

        //#endregion

        .hover-icon-content {
            // the <use> element
            fill: none;
            &:hover {
                fill: rgba(0, 0, 255, 0.05);
            }
        }

        .hover-icon-background {
            fill: #f0f0f7;
            width: 23px;
            height: 23px;
            transform: translateY(3px);
        }

        //#region items (boxes)
        g[data-id] {
            /* uil0: outermost */
            &.uil0 {
                .databox {
                    filter: url('#drop-shadow');
                }
                .item-name {
                    font-weight: 700;
                }
            }
            /* uil1: intermediate, uil2: innermost */
            &.uil1,
            &.uil2 {
                .item-name {
                    font-weight: 450;
                }
            }

            /* item body */
            .databox {
                fill: white;
            }

            //#region item head */
            .ghead {
                .item-head,
                .item-name {
                    pointer-events: bounding-box;
                    fill: transparent;
                }

                .expander-caret,
                .lzy-caret {
                    transform: translate(15px, 20px);
                }
                .expander-caret {
                    > text {
                        font-size: text.$tiny-text-size;
                        fill: white;
                        stroke: none;
                    }
                    &.expanded > text {
                        transform: translate(0.8px, -9px) rotate(90deg);
                    }
                }

                .item-icon {
                    font-size: text.$title-text-size;
                    transform: translate(30px, 23px);
                }

                .item-name {
                    line-height: 18px;
                    font-size: text.$normal-text-size;
                    /* translate.x must match LineageConstants.itemTextLeft */
                    transform: translate(50px, 20px);
                }

                .golden-item-icon {
                    &-bg {
                        fill: palette.$yellow-50;
                    }
                    &-text {
                        font-size: text.$small-text-size;
                        font-family: 'datagalaxy';
                    }
                }
            }

            > .ghead {
                .item-name {
                    fill: $text-color;
                }
            }
            &.searched-item:not(.trk0):not(.trk1):not(.trk2) > .ghead {
                .item-head {
                    fill: $text-color;
                }
                .item-icon,
                .item-name {
                    fill: white;
                }
            }

            &.trk0:not(.trk1):not(.trk2) > .ghead > .item-head {
                fill: $track0-box-color;
                stroke: $track0-box-color;
            }
            &.trk0.trk1:not(.trk2) > .ghead > .item-head {
                fill: $track10-box-color;
                stroke: $track10-box-color;
            }
            &.trk0:not(.trk1).trk2 > .ghead > .item-head {
                fill: $track20-box-color;
                stroke: $track20-box-color;
            }
            &:not(.trk0).trk1:not(.trk2) > .ghead > .item-head {
                fill: $track1-box-color;
                stroke: $track1-box-color;
            }
            &:not(.trk0):not(.trk1).trk2 > .ghead > .item-head {
                fill: $track2-box-color;
                stroke: $track2-box-color;
            }
            &:not(.trk0).trk1.trk2 > .ghead > .item-head {
                fill: $track12-box-color;
                stroke: $track12-box-color;
            }
            &.trk0.trk1.trk2 > .ghead > .item-head {
                fill: $track120-box-color;
                stroke: $track120-box-color;
            }

            //&.lzy .item-head { /* item with lazy (ie not loaded) children */ }

            //#endregion

            //#region hierarchically ellipsed item
            &.hell {
                /* hierarchical ellipsis */
                g.inhell {
                    rect.bg {
                        fill: white;
                        pointer-events: visible;
                    }
                    text {
                        fill: $hellips-color;
                    }
                    path {
                        fill: none;
                        stroke: $hellips-color;
                        stroke-dasharray: 2;
                        stroke-width: 1px;
                    }

                    &:hover {
                        text {
                            fill: white;
                        }
                        rect.bg {
                            fill: $hellips-color;
                            cursor: pointer;
                        }
                    }
                }
            }
            //#endregion
        }
        //#endregion

        //#region links
        g.link {
            > path {
                fill: none;
                &.golden-link-path {
                    stroke: palette.$yellow-50;
                    stroke-width: 4;
                    stroke-opacity: 0.5;
                    stroke-linecap: round;
                }
            }
            &.lzy {
                /* lazy links */
                .link-path {
                    stroke-dasharray: 2;
                    opacity: 0.3;
                }
            }
            &.vir {
                /* virtual links */
                .link-path {
                    stroke-dasharray: 2;
                    opacity: 0.3;
                }
            }
            &:not(.trk0):not(.trk1):not(.trk2) > .link-path {
                stroke-width: 1;
                opacity: 0.3;
            }
            &.trk0,
            &.trk1,
            &.trk2 {
                > .link-path {
                    stroke-width: 2;
                }
                > .golden-link-path {
                    stroke-width: 6;
                    stroke-opacity: 1;
                }
            }
            &.trk0:not(.trk1):not(.trk2) > .link-path {
                opacity: 1;
            }
            &.trk0.trk1:not(.trk2) > .link-path {
                stroke: $track10-link-color;
                opacity: 1;
            }
            &.trk0:not(.trk1).trk2 > .link-path {
                stroke: $track20-link-color;
                opacity: 1;
            }
            &:not(.trk0).trk1:not(.trk2) > .link-path {
                stroke: $track1-link-color;
                opacity: 1;
            }
            &:not(.trk0):not(.trk1).trk2 > .link-path {
                stroke: $track2-link-color;
                opacity: 1;
            }
            &:not(.trk0).trk1.trk2 > .link-path {
                stroke: $track12-link-color;
                opacity: 1;
            }
            &.trk0.trk1.trk2 > .link-path {
                stroke: $track120-link-color;
                opacity: 1;
            }
        }
        //#endregion
    }
    //#endregion

    //#region pathTracker control (in contextual burger-menu)

    .dynamic-content > span {
        margin-top: 3px;
        display: flex;

        .path-tracker-option-track {
            margin: 0 2.5px 0 2.5px;
            @mixin round-icon($bgcolor, $diameter: 26px) {
                position: relative;
                width: $diameter;
                height: $diameter;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:after {
                    content: '';
                    display: block;
                    height: $diameter;
                    width: $diameter;
                    position: absolute;
                    top: center;
                    left: center;
                    background: $bgcolor;
                    border-radius: calc(#{$diameter} / 2);
                }
            }
            &.path-tracker-option-track1 {
                @include round-icon($track1-box-color);
            }
            &.path-tracker-option-track2 {
                @include round-icon($track2-box-color);
            }

            &:hover {
                opacity: 1;
                cursor: pointer;
            }
            &.hidden {
                display: flex !important;
                visibility: hidden;
            }

            &:before {
                content: $glyph-cancelsearch;
                font-family: 'datagalaxy';
                font-size: text.$big-text-size;
                color: white;
                z-index: 1;
            }
            &:not(.hidden):hover:before {
                color: black;
            }

            &.hidden {
                visibility: visible;
                opacity: 0.2;
                &:hover {
                    cursor: default;
                }
                &:before {
                    display: none;
                }
            }
        }
    }

    dxy-burger-menu {
        .burger-menu {
            .path-tracker-option {
                cursor: default;
                .path-tracker-option-track {
                    &.path-tracker-option-track1 {
                        margin-left: 10px;
                    }
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    //#endregion
}

app-lineage-graph .left-toolbar,
.dxy-graphical-toolbar--mat-menu {
    .golden-link-option.glyph {
        display: block;
        background-color: palette.$yellow-50;
        border-radius: 16px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: text.$small-text-size;

        &.inactive {
            background-color: palette.$gray-60;
            color: #fff;
        }
    }

    .dg5-beta-badge:after {
        margin: auto 5px;
        transform: scale(0.9) translate(-5px, -11px);
    }

    .icon-path-tracker.glyph {
        display: flex;
        width: 16px;
        height: 16px;

        &.blue-and-red {
            &:before,
            &:after {
                content: '';
                width: 8px;
                height: 16px;
            }
            &:before {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                background-color: $lineage-path-tracker-blue;
            }
            &:after {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                background-color: $lineage-path-tracker-red;
            }
            &.blue-unpinned:before,
            &.red-unpinned:after {
                opacity: 0.5;
            }
        }
        &.blue,
        &.red {
            border-radius: 8px;
            &.pinned {
                &:before {
                    content: '\e8a9';
                    font-family: datagalaxy;
                    font-size: text.$small-text-size;
                    line-height: 16px;
                    margin-left: 2px;
                    color: #fff;
                }
            }
        }
        &.blue {
            background-color: $lineage-path-tracker-blue;
        }
        &.red {
            background-color: $lineage-path-tracker-red;
        }
    }
}
