@use 'base/text' as text;
@import '../../../styles/global/base/mixin-flex.scss';
@import '../../../styles/global/base/bold.scss';

dxy-task-results {
    dxy-omni-grid {
        div[col-id='titleAndRawText'][role='gridcell'] {
            font-size: text.$normal-text-size;
            line-height: 15px !important;
            .title {
                @include txt-bold();
                display: inline-block;
                margin-top: 10px;
            }
        }
    }
}
