@use '../mixins/_mixin-flex.scss';

.hidden {
    display: none !important;
}
.no-pointer-events {
    pointer-events: none;
}

.mainView {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    background-color: var(--color-background-any-secondary);
    color: var(--color-foreground-any);

    > .vcontainerDiv,
    > .hcontainerDiv {
        flex-grow: 1;
        background: var(--color-background-any);
    }
}

.main-view-wrapper {
    @include mixin-flex.vcontainerDiv();
    z-index: 4;
    height: 100%;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    flex: 1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--color-background-any);
    box-shadow: var(--color-shadow-any-neutral);
    padding: 30px 30px 0 30px;
}
