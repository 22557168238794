.dxy-thumbnail {
    height: 44px;
    width: 44px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    gap: 2px;
    color: var(--color-foreground-any-secondary);
    background-color: var(--color-background-any-neutral);
    position: relative;
    overflow: hidden;
    > i {
        font-size: 19px;
    }
    > span {
        line-height: 11px;
        font-size: 9px;
    }
}
