@use 'base/text' as text;

dxy-tabs-header {
    display: flex;
    flex-direction: column;
    &:not(.no-grow) {
        flex-grow: 1;
    }
    flex-wrap: nowrap;
    background-color: var(--color-background-any);

    &.stretch-tabs {
        .mat-tab-label {
            flex: 1;
        }
    }

    .header-cell {
        display: flex;
        align-items: center;
    }

    .mat-tab-group {
        padding: 0;
        div[role='tab'] {
            font-size: text.$normal-text-size;
            font-weight: 700;
            letter-spacing: 1px;

            &.mat-tab-label {
                padding: 0 10px;
            }

            .badge {
                margin-left: 5px;
            }
        }
        .mat-tab-body-wrapper {
            display: none;
        }
    }
    dxy-tag {
        width: 24px;
        margin-left: 5px;
    }
}
