@use 'glyphs' as *;
@forward 'global/status-icon.scss';
@forward 'global/list.scss';
@forward 'global/grid.scss';
@forward 'global/card.scss';
@forward 'global/component.scss';

html,
body {
    height: 100% !important;
}

#boot-splash {
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    opacity: 0; // set to 1 via JS once image has been loaded
    transition: opacity 0.2s ease-in-out;
    background: var(--color-background-any);
    z-index: 1003;
}

a:not([href]) {
    cursor: pointer;
}

input.form-control::placeholder {
    font-style: normal !important;
}

.hcontainerDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
    flex-grow: 1;
    min-width: 0;
}

/* My Styles
-----------------------------------------------------------*/

center {
    margin: auto;
}

.view {
    padding: 16px 16px 16px 16px;
}

.flex {
    display: flex;
}

.hcenter {
    margin-left: auto;
    margin-right: auto;
}

// Class to work with ui-view plugged components

.block {
    display: block;
}

.vcontainerDiv {
    @include flexV();
    min-height: 0;
    overflow: hidden;
}

.vflexDiv {
    display: flex;
    flex-direction: column;
}

// Old scss files
@import 'global/header.scss';
@import 'global/section.scss';
@import 'global/form.scss';
@import 'global/icon-space.scss';
@import 'global/bloc.scss';
@import 'global/button.scss';
@import 'global/helper.scss';
@import 'global/widget.scss';
@import 'global/animation.scss';
@import 'global/screenshot.scss';
