app-space-description-widget {
    dxy-rich-text-field.editing {
        .ql-editor {
            height: 185px;
            overflow: scroll !important;
        }

        .mat-hint {
            margin: 0;
        }
    }
}
