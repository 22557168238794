@use 'base/text' as text;
@use 'palette';

dxy-entity-panel-body {
    dxy-task-container,
    dxy-commentary-container,
    app-suggestion-container,
    dxy-insight-data,
    app-campaign-container,
    app-data-quality-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .panel-content-tool {
        display: flex;
        align-items: center;
        height: 48px;
        padding-left: 20px;
        background-color: palette.$reflex-05;

        .panel-content-toolname {
            display: flex;
            align-items: center;
            font-weight: 750;
            color: palette.$reflex-60;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 12px;
            cursor: pointer;
            line-height: 50px;
            height: 50px;
            margin-right: auto;
            padding-right: 10px;
            padding-left: 10px;
            border-bottom: palette.$reflex-60 solid 3px;
            z-index: 1;

            .dg5-badge {
                background-color: palette.$reflex-50;
                color: var(--color-background-any);
            }
        }
    }

    .panel-content-body {
        overflow: auto;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        border-left: 1px solid palette.$reflex-08;
    }

    .tool-add-entity {
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;
        margin-bottom: 0;
    }

    .actions-container {
        display: flex;
        margin-right: 10px;
    }

    .dxy-placeholder {
        flex-grow: 1;
        .dxy-placeholder-img {
            max-width: 100%;
        }
    }
}

dxy-entity-panel-body,
.diagram-link-explorer {
    // Archi-scss(revi): To Remove (replace by .dxy-placeholder)
    .no-item-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;

        .dg_no-item-image {
            width: 350px;
            max-width: 100%;
        }

        .no-item-text {
            margin: 10px 20px 30px;
            align-self: center;
            text-align: center;
            color: palette.$gray-60;
        }

        .no-item-title {
            font-weight: 700;
            font-size: text.$title-text-size;
            line-height: 36px;
            margin-top: 30px;
        }

        .doc-btn {
            text-decoration: none !important;
        }
    }
}
