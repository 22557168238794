.dg5-drag-drop {
    &.drag-placeholder-hidden {
        .cdk-drag-placeholder {
            opacity: 0;
        }
    }
    &.drag-preview-hidden {
        .cdk-drag-preview {
            opacity: 0;
        }
    }
    &.drag-over-orange {
        .dragHover {
            box-shadow: 0px 0px 0px 1px orange inset;
        }
    }
    &.drag-animated {
        .cdk-drag-animating {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
        .cdk-drop-list-dragging :not(.cdk-drag-placeholder) {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
    }
    &.drag-preview-round-corners {
        .cdk-drag-preview {
            border-radius: 4px;
        }
    }

    .cdk-drag-preview &:not(.drag-preview-hidden) {
        background: white;
        box-shadow:
            0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
}

.dg5-disable-dragover--over {
    &:before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 300;
    }
}
