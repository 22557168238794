@use 'base/text' as text;

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: text.$font-family;
    font-weight: 450;
    font-size: text.$normal-text-size;
    color: var(--color-foreground-any);
}
