@use 'base/text' as text;

dxy-entity-synonyms dxy-grid {
    .description-cell {
        color: var(--color-foreground-any-secondary);
        line-height: 16px;
        font-size: text.$small-text-size;
        width: 100px;
        word-wrap: break-word;
        white-space: initial;
    }
}
