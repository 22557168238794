.value-list-filter-option-select-menu {
    dxy-search-input {
        position: sticky;
        top: 0;
        z-index: 1;

        .search-bar {
            background: white;
        }
    }
}

.dg5-mat-menu.mat-menu-panel {
    .value-list-filter-option-select-menu {
        dxy-option-list {
            .mat-menu-item {
                height: unset !important;
                padding: 5px 20px;
                min-height: 40px;
                line-height: unset;
            }
        }
    }
}
