:root {
    --toggle-knob-shadow-color: color-mix(
        in srgb,
        var(--color-brand-default),
        transparent var(--transparency-background-hover)
    );

    --toggle-knob-background: var(--color-neutral-negative);
    --toggle-knob-shadow-box: 0px 0px 0px 4px var(--toggle-knob-shadow-color);

    --toggle-selected-frame: var(--color-brand-default);
    --toggle-unselected-frame: var(--color-neutral-light);
    --toggle-frame-unselected-disabled-color: var(--color-neutral-lighter);
    --toggle-frame-selected-disabled-color: var(--color-brand-light);

    --toggle-width-big: 32px;
    --toggle-height-big: 20px;
    --toggle-knob-transform-big: 16px;
    --toggle-knob-height-big: 16px;
    --toggle-knob-width-big: 16px;
    --toggle-knob-border-big: 20px;
    --toggle-knob-top-big: 2px;
    --toggle-knob-left-selected-big: -2px;
    --toggle-knob-left-unselected-big: 2px;

    --toggle-width-default: 24px;
    --toggle-height-default: 15px;
    --toggle-knob-transform-default: 11px;
    --toggle-knob-height-default: 11px;
    --toggle-knob-width-default: 11px;
    --toggle-knob-border-default: 20px;
    --toggle-knob-top-default: 2px;
    --toggle-knob-left-selected-default: 0px;
    --toggle-knob-left-unselected-default: 2px;

    --toggle-width-small: 16px;
    --toggle-height-small: 9px;
    --toggle-knob-transform-small: 7px;
    --toggle-knob-height-small: 7px;
    --toggle-knob-width-small: 7px;
    --toggle-knob-border-small: 13px;
    --toggle-knob-top-small: 1px;
    --toggle-knob-left-selected-small: 1px;
    --toggle-knob-left-unselected-small: 1px;

    --toggle-container-padding: var(--padding-pd-l);

    --toggle-content-size-big: var(--typography-fontsize-s);
    --toggle-content-size-default: var(--typography-fontsize-xs);
    --toggle-content-size-small: var(--typography-fontsize-xxs);
}
