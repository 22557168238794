@use 'base/text' as text;

dxy-data-type-model-settings {
    > .dg_card.dg_component {
        min-height: auto;
    }
    .current-mapping {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
    }
    .mat-header-row {
        font-size: text.$small-text-size;
    }
    .dg_component-header > button {
        margin-left: auto;
    }
}
