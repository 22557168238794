@use '../base/buttons' as buttons;
@use 'libs/ui/styles/glyphs.scss' as glyphs;

// default is 1000. 1050 ensures modal is displayed above preview-panel-slider
.cdk-overlay-container {
    z-index: 1050 !important;
}

.dg5-base-modal .mat-mdc-dialog-container {
    padding: 0;

    .mat-mdc-dialog-title {
        margin-bottom: 0;
    }

    .modal-header {
        height: 60px;
        padding: 0 10px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        background-color: var(--color-background-any-inverted);

        > .dxy-icon-button {
            margin-left: auto;
        }

        .modal-title {
            padding: 0;
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 700;
            margin-left: 10px;
            color: var(--color-foreground-any-inverted);
        }
    }

    .mat-mdc-dialog-content {
        color: var(--color-foreground-any);
        padding: 20px;
        margin: 0;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .modal-footer {
        position: relative;
        border-top: 1px solid var(--color-shadow-any);
        display: flex;
        padding: 18px;
        align-items: center;
        justify-content: flex-end;

        .mandatory-field {
            color: var(--color-foreground-any-secondary);
            margin-right: auto;
        }

        > *:last-child {
            margin-right: 5px;
        }
    }
}

// Override default dark backdrop with our colors
.cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
    background-color: #00103032;
}
