app-entity-export-modal {
    .checkbox-wrapper {
        padding-left: 10px;
        .checkbox-text {
            margin: 0;
        }
    }

    .options-section {
        flex: 1;
        overflow-y: scroll;
        margin-left: -10px;

        .inline-radio {
            flex-direction: row;
            display: flex;
        }
    }

    .file-name-section {
        display: flex;
        align-items: center;
    }
}
