.dxy-button--large {
    &.dxy-icon-button {
        --padding: var(--buttons-padding-y-l, 12px)
            var(--buttons-padding-x-iconButton-l, 12px);
        --gap: var(--buttons-gap-l-icon, 0px);
    }
}
.dxy-button--medium {
    &.dxy-icon-button {
        --padding: var(--buttons-padding-y-m, 8px)
            var(--buttons-padding-x-iconButton-m, 8px);
        --gap: var(--buttons-gap-m-icon, 0px);
    }
}
.dxy-button--small {
    &.dxy-icon-button {
        --padding: var(--buttons-padding-y-s, 4px)
            var(--buttons-padding-x-iconButton-s, 4px);
        --gap: var(--buttons-gap-s-icon, 0px);
    }
}
