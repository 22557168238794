@use 'palette';

app-account-login,
app-account-set-password {
    .account-form {
        display: flex;
        width: 400px;
        flex-direction: column;

        .account-button {
            width: 100%;
            height: 50px;
            margin-bottom: 20px;
            margin-top: 20px;
            margin-left: 0;
        }
    }
}

.account-link {
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.account-error,
.account-warning,
.account-success {
    width: 100%;
    margin: 10px auto 20px auto;
    padding: 15px;
    border-radius: 3px;
    text-align: center;
}

.account-error {
    background-color: transparentize(palette.$magenta-50, 0.85);
    color: darken(palette.$magenta-50, 10%);
}

.account-warning {
    background-color: transparentize(palette.$yellow-50, 0.8);
    color: darken(palette.$yellow-50, 30%);
}

.account-success {
    background-color: transparentize(palette.$grass-50, 0.8);
    color: darken(palette.$grass-50, 30%);
}
