//#region overrides impactAnalysis-dataVizObjectGraph.global.scss

.dg_dataVizGraphObject-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: grab;

    > svg {
        flex: 1 1 auto;
    }
}

.dg_dataVizGraphObject-graphicalNode {
    cursor: default;
}

.dg_dataVizGraphObject-graphicalNodeText-Body {
    background-color: transparent;
}

.dg_dataVizGraphObject-graphicalNodeText-MainContainer {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.dg_dataVizGraphObject-graphicalNodeText-ObjectContainer {
    font-weight: bold;
    text-align: center;
}

.dg_dataVizGraphObject-graphicalNodeText-ParentObjectContainer {
    text-align: center;
}

.dg_dataVizGraphObject-graphicalNodeText-ParentObjectContainer--glyph {
    margin-right: 2px;
}

.dg_dataVizGraphObject-graphicalNodeText-ParentObjectContainer--label {
    font-style: italic;
}

.dg_dataVizGraphObject-graphicalLinkText {
    font-size: 13px;
    text-align: center;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.golden {
        font-weight: 700;
    }
}

.dg_dataVizGraphObject-graphicalLink {
    stroke: #777;
    stroke-width: 2px;

    &.graphicalLink--LinkedColumns {
        stroke-dasharray: 10, 5;
        stroke-linecap: round;
    }

    &.graphicalLink--LinkedProperties {
        stroke-width: 3px;
        stroke-dasharray: 1, 3;
        stroke-linecap: round;
    }
}
//#endregion
