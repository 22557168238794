@use 'palette';

dxy-compare-entity-form {
    .group {
        min-width: 420px;
        max-width: 460px;
    }
    .glyph-copy-compared-field {
        cursor: pointer;
        font-size: 20px;
        padding-left: 10px;
        padding-top: 10px;
        color: palette.$reflex-100;
        background-color: palette.$gray-40;
        &:hover {
            cursor: pointer !important;
            color: white;
            background-color: palette.$reflex-100 !important;
        }
    }
    .dg_scroll-comp-version {
        max-width: 460px;
    }
}
