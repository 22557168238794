@use 'base/text' as text;

dxy-search-filters-list {
    dxy-space-version-selector {
        height: 30px;
        flex: unset;

        .all-spaces-version-text {
            font-size: text.$small-text-size;
            line-height: 24px;
        }

        .space-selector,
        .version-selector {
            height: 24px;
            margin-right: 15px;

            .menu-selected,
            .selected-workspace {
                height: 12px;
                line-height: 12px;
                font-size: text.$small-text-size;
            }
        }

        .space-selector.has-label .workspace-icon {
            height: 19px;
            width: 19px;
            font-size: 8px;
        }
        .separator {
            display: none;
        }
    }
}
