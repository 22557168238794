.entity-list-filter-no-result {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-foreground-any-secondary);
}

.entity-list-item {
    background: white;

    > a {
        text-decoration: none;
    }

    &:hover {
        background: var(--color-background-any--hover);
        cursor: pointer;
    }

    &.selected {
        background: var(--color-background-any--active) !important;
    }

    &.disabled {
        color: var(--color-foreground-any-tertiary);
        cursor: not-allowed;
        user-select: none;
        &:hover {
            background: var(--color-background-any);
        }
    }
}

.treeListItem-info {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;

    & :nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
