.dg_card {
    min-width: 400px;
    background-color: var(--color-background-any);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    margin: 15px 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    overflow: visible;
}
.dg_card.dg_component {
    min-height: 150px;
    background-color: var(--color-background-any);
}
