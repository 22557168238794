﻿@use 'palette';
@use 'base/text' as text;

// #archi-scss (revi): create scss variables in new scss structure
$shadowBarSerieColor: #333;
$completionSubBarColor: #2e3192;

// This class needs to be global in order to apply classes styles inside svg
.dg_horizontalBarChart {
    .hbc-shadowBarSerie {
        fill: $shadowBarSerieColor;
        fill-opacity: 0.05;
    }

    .hbc-fillingSubBar {
        fill: palette.$blue-50;
        transition: all 0.5s cubic-bezier(0.694, 0.048, 0.335, 1);
    }

    .hbc-completionSubBar {
        fill: $completionSubBarColor;
    }

    .hbc-legend-fillingSubBar {
        background-color: palette.$blue-50;
    }

    .hbc-legend-completionSubBar {
        background-color: $completionSubBarColor;
    }

    .hbc-labelBar {
        color: $shadowBarSerieColor;
        font-size: text.$normal-text-size;
        margin-right: 5px;
        line-height: 16.5px;
    }

    .hbc-legendContainer {
        display: flex;
        padding-top: 8px;
    }

    .hbc-legendItem {
        display: flex;
        align-items: center;
        color: palette.$reflex-100;
        opacity: 0.5;
        font-size: text.$small-text-size;
        line-height: 16.5px;
        margin-right: 8px;
    }

    .hbc-legendItemBox {
        width: 15px;
        height: 5px;
        margin-right: 5px;
    }

    .hbc-ghost {
        background-color: palette.$reflex-100;
        opacity: 0.2;
    }
}
