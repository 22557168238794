@use 'base/text' as text;
@use 'palette';
@use '../mixins/mixin-label.scss';

/**
 * Reference : https://datagalaxy-uikit-v1.webflow.io/ui-kit#filters
 */

.dg5-filter-item {
    display: flex;
    flex-direction: row;
    background-color: var(--color-background-any);
    cursor: pointer;
    margin-right: 10px;
    padding: 4px;
    align-items: center;
    height: 30px;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: palette.$reflex-08;
    border-radius: 3px;
    color: palette.$reflex-100;
    text-align: inherit;

    &.disabled {
        cursor: inherit;

        .glyph-arrow-drop-down {
            display: none;
        }
    }

    &:hover {
        &:not(.disabled) {
            background-color: palette.$reflex-08;
        }
    }

    .filter-value-wrapper {
        line-height: 12px;
        display: flex;
        flex-wrap: nowrap;
    }

    .filter-icon {
        margin-right: 5px;
        align-items: center;
        display: flex;
    }

    .filter-image {
        display: flex;
        align-items: center;
        margin-right: 5px;
        img {
            width: 20px;
            height: 20px;
        }
    }

    .filter-label {
        @include mixin-label.smallLabel();
    }

    .operator-name {
        font-size: text.$small-text-size;
        line-height: 12px;
        margin-right: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.light-operator {
            color: palette.$gray-60;

            &.second-operator {
                margin-left: 5px;
                margin-right: 3px;
            }
        }
    }

    .text-value-wrapper {
        display: flex;
        min-width: 0;

        > div {
            display: flex;
        }
    }

    .duo-glyph {
        font-size: 13px;
        margin-right: 2px;
        align-self: center;
    }

    .filter-value {
        display: block !important;
        flex: auto;
        font-size: text.$small-text-size;
        line-height: 12px;
        height: 12px;
        white-space: nowrap;
        background: transparent;
        border: unset;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    input.filter-value {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 120px;
        line-height: 14px;
        padding: 0;
        &::placeholder {
            color: palette.$gray-60;
            font-style: normal;
        }
    }

    .glyph-arrow-drop-down {
        font-size: text.$normal-text-size;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-separator {
        padding-right: 2px;
        padding-left: 2px;
        border-radius: 3px;
        background-color: palette.$gray-60;
        color: white;
        font-size: 8px;
        line-height: 12px;
        height: 12px;
        font-weight: 700;
        margin-left: 3px;
        margin-right: 3px;
    }

    .filter-group {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .struck-out-text {
        text-decoration: line-through;
    }
}
