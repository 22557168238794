@use 'base/text' as text;

dxy-attribute-quality-score-dropdown {
    dxy-percentage-donut {
        .donut-ring,
        .donut-segment {
            stroke-width: 4;
        }

        .donut-text {
            font-size: text.$big-text-size;
            font-weight: 700;
        }
    }
}
