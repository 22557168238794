@use 'palette';

.screenshot-effect {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: palette.$reflex-100;
    z-index: 10000;
    animation: screenshot-animation 500ms forwards;
}

@keyframes screenshot-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
