dxy-glossary-implementation {
    flex: 1 1 auto;
    display: flex;
    dxy-graphical-controls .left-toolbar {
        box-shadow: unset;
        .add-element .glyph {
            color: white;
        }
    }
}
