@use 'graphical-colors.global' as *;

@forward '../graph';

/*
    diagramming class name must be set on the surface element or any of its parent.
*/
.diagramming {
    //#region port-handle
    .ph-element::before {
        // needed for hovering
        content: '';
        position: absolute;
        inset: -10px;
    }
    //#endregion

    &.debug,
    .debug {
        //#region graph
        //#region port-manager
        .ph-element {
            // port-handle
            border: 1px dashed green;
            &::before {
                outline: 1px solid white;
            }
        }
        .ph-custom {
            // port-handle
            border: 1px dashed red;
        }
        .ph-node {
            // port-handle
            border: 1px dashed blue;
        }
        //#endregion
        svg.gs-connector-path {
            // base-connector
            path.outline {
                stroke: gray;
                opacity: 0.3;
            }
        }
        .oe-handle {
            // ortho-editor
            background-color: purple;
            opacity: 0.4;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        //#endregion - graph

        //#region diagram-surface
        //#region area-selector
        .as-handle {
            border-radius: 50%;
            border: 1px solid red;
        }
        .as-area {
            border: 1px dashed purple;
        }
        //#endregion
        //#region resize-manager
        .rm-frame {
            border: 1px dashed red;
        }
        //.rm-handle { outline: 1px solid red; }
        //#endregion
        //#endregion - diagram-surface

        .diag-entity {
            opacity: 0.7;
        }
    }
}
.zs-minimap-content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .mm-rects {
        height: 100%;
        width: 100%;
    }
    .mm {
        position: absolute;
        &.r {
            background-color: rgb(96, 122, 134);
        }
        &.view {
            opacity: 0.4;
            background-color: rgb(79, 111, 126);
            cursor: grab;
        }
    }
}
