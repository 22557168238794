@use '../../style/core-ui-variables';

.mat-menu-panel.button-filter--mat-menu {
    $filter-operator-height: 50px;

    .has-operators {
        dxy-multiselect-list {
            --multiselect-list-height: calc(
                #{core-ui-variables.$dropdown-max-height} -
                    #{$filter-operator-height}
            );
        }
    }

    &.small-panel {
        width: 250px;
    }

    .operators {
        mat-radio-group {
            justify-content: unset;
            width: unset;

            mat-radio-button {
                margin-left: 15px;
                label {
                    margin: 0;
                }
            }
        }
    }
}
