:root {
    --menus-background-color: var(--color-neutral-negative);
    --menus-radius: var(--radius-rd-s, 2px);
    --menus-radius-large: var(--radius-rd-xl);
    --menus-gap: var(--gap-gp-m);
    --menus-shadow: var(--shadow-neutral);
    --menu-item-padding: var(--padding-pd-l) var(--padding-pd-xl);

    --menu-header-padding: var(--padding-pd-xl);
    --menu-header-gap: var(--gap-gp-m);
    --menu-header-background-default: var(--color-brand-lighter);
    --menu-header-foreground-default: var(--color-brand-darker);
    --menu-header-background-ai: var(--color-ai-lighter);
    --menu-header-foreground-ai: var(--color-ai-darker);
    --menu-header-background-danger: var(--color-danger-lighter);
    --menu-header-foreground-danger: var(--color-danger-darker);
    --menu-header-title-font-size: var(--typography-fontsize-xs);

    --menu-border-color-bordered: var(--color-neutral-light);
    --menu-toolbar-divider-color: var(--color-neutral-lighter);
    --menu-toolbar-gap: var(--gap-gp-s);
    --menu-toolbar-padding: var(--padding-pd-s);
}
