@use '../base/text';

form {
    &.compact {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.dxy-label {
    font-size: text.$small-text-size;
    line-height: 15px;
    font-weight: 700;
    color: var(--color-text-any-primary);
}
