.dxy-link {
    all: unset;
    color: var(--color-text-any-primary);
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    &:active,
    &:visited {
        color: var(--color-text-any-primary);
    }
}
