@use 'base/text' as text;
@use 'palette';

@mixin label() {
    color: palette.$reflex-60;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin smallLabelSize() {
    font-size: text.$tiny-text-size !important;
    line-height: 10px !important;
    font-weight: 700 !important;
}

@mixin smallLabel() {
    @include label();
    @include smallLabelSize();
}
