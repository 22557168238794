@use 'palette';

// #archi-scss (revi): remove all .btn usages

.btn-primary {
    border: unset;
    background-color: palette.$reflex-50;
    color: white;
    &:hover {
        background-color: darken(palette.$reflex-50, 10%);
    }

    // If primary button is disabled : opacity is 0.5 on the whole button (background + content)
    &[disabled],
    &.disabled {
        opacity: 0.5;
        &:hover {
            background-image: palette.$reflex-50;
        }
    }
}

.btn.no-text {
    border: none;
    padding: 1px 10px;
    font-size: 1.2rem;
    background-color: #f9f9f9; //#CCCCCC;
    &:hover {
        background-color: #d4d4d4;
    }
}
