@use 'graphical-colors.global' as *;

.gs-connector-arrow {
    &.arrow.plain:not(.fb) {
        stroke: none;
    }
    &.arrow.line {
        fill: none;
    }

    @each $thickness in (1, 2, 4, 6) {
        &.thick-#{$thickness} {
            stroke-width: #{$thickness};
        }
    }

    @each $name, $color in $graphical-colors {
        &.color-#{$name} {
            &.arrow.plain,
            &.circle.plain {
                fill: $color;
            }

            &.arrow.line,
            &.arrow.fb,
            &.circle.line {
                stroke: $color;
            }
        }
    }
}
