@use 'palette' as *;

/* _theming.scss */
$dg-primary-colors: (
    50: #e2e7f6,
    100: #b7c2e8,
    200: #889ad8,
    300: #5872c8,
    400: #3453bd,
    500: $reflex-50,
    600: #0e30aa,
    700: #0c28a1,
    800: #092298,
    900: #051688,
    A100: #b5bbff,
    A200: #828cff,
    A400: #4f5dff,
    A700: #3646ff,
    contrast: (
        50: $reflex-100,
        100: $reflex-100,
        200: $reflex-100,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: $reflex-100,
        A200: $reflex-100,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

/* For use in src/lib/core/theming/_palette.scss */
$dg-warn-colors: (
    50: #f9e0e9,
    100: #efb3c9,
    200: #e580a5,
    300: #da4d80,
    400: #d22665,
    500: $magenta-50,
    600: #c50043,
    700: #bd003a,
    800: #b70032,
    900: #ab0022,
    A100: #ffd4da,
    A200: #ffa1ae,
    A400: #ff6e81,
    A700: #ff556b,
    contrast: (
        50: $reflex-100,
        100: $reflex-100,
        200: $reflex-100,
        300: $reflex-100,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: $reflex-100,
        A200: $reflex-100,
        A400: $reflex-100,
        A700: $reflex-100,
    ),
);

$dg-grey-colors: (
    50: #ebecf0,
    100: #cecfdb,
    200: #adafc3,
    300: #8c8eaa,
    400: #737698,
    500: $gray-60,
    600: #52567e,
    700: #484c73,
    800: #3f4269,
    900: #2e3156,
    A100: #698fff,
    A200: #2c62ff,
    A400: #134fff,
    A700: #0344ff,
    contrast: (
        50: $reflex-100,
        100: $reflex-100,
        200: $reflex-100,
        300: $reflex-100,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: $reflex-100,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$dg-light-foreground: (
    base: $reflex-100,
    divider: $gray-60,
    dividers: $gray-60,
    disabled: $gray-60,
    disabled-button: rgba($reflex-100, 0.26),
    disabled-text: $gray-60,
    elevation: $reflex-100,
    hint-text: $gray-60,
    secondary-text: $gray-60,
    icon: rgba($reflex-100, 0.54),
    icons: rgba($reflex-100, 0.54),
    text: rgba($reflex-100, 0.87),
    slider-min: rgba($reflex-100, 0.87),
    slider-off: rgba($reflex-100, 0.26),
    slider-off-active: rgba($reflex-100, 0.38),
);

$dg-light-background: (
    status-bar: map-get($dg-grey-colors, 300),
    app-bar: map-get($dg-grey-colors, 100),
    background: map-get($dg-grey-colors, 50),
    hover: rgba($reflex-100, 0.04),
    card: white,
    dialog: white,
    disabled-button: rgba($reflex-100, 0.12),
    raised-button: white,
    focused-button: rgba($reflex-100, 0.12),
    selected-button: map-get($dg-grey-colors, 300),
    selected-disabled-button: map-get($dg-grey-colors, 400),
    disabled-button-toggle: map-get($dg-grey-colors, 200),
    unselected-chip: map-get($dg-grey-colors, 300),
    disabled-list-option: map-get($dg-grey-colors, 200),
    tooltip: map-get($dg-grey-colors, 700),
);

$dg-dark-foreground: (
    base: white,
    divider: $gray-60,
    dividers: $gray-60,
    disabled: $gray-60,
    disabled-button: rgba(white, 0.26),
    disabled-text: $gray-60,
    elevation: white,
    hint-text: $gray-60,
    secondary-text: $gray-60,
    icon: rgba(white, 0.54),
    icons: rgba(white, 0.54),
    text: rgba(white, 0.87),
    slider-min: rgba(white, 0.87),
    slider-off: rgba(white, 0.26),
    slider-off-active: rgba(white, 0.38),
);

$dg-dark-background: (
    status-bar: map-get($dg-grey-colors, 600),
    app-bar: map-get($dg-grey-colors, 800),
    background: map-get($dg-grey-colors, 900),
    hover: rgba(white, 0.04),
    card: $reflex-100,
    dialog: $reflex-100,
    disabled-button: rgba(white, 0.12),
    raised-button: $reflex-100,
    focused-button: rgba(white, 0.12),
    selected-button: map-get($dg-grey-colors, 600),
    selected-disabled-button: map-get($dg-grey-colors, 500),
    disabled-button-toggle: map-get($dg-grey-colors, 800),
    unselected-chip: map-get($dg-grey-colors, 600),
    disabled-list-option: map-get($dg-grey-colors, 700),
    tooltip: map-get($dg-grey-colors, 200),
);
