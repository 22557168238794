@use 'palette';

.dg5-palette {
    // background color palette
    @each $name, $color in palette.$badge-palette {
        &.bg.#{$name} {
            background-color: $color;
        }
    }

    // text color palette
    @each $name, $color in palette.$badge-palette {
        &.txt.#{$name} {
            color: $color;
        }
    }
}
