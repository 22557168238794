.mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-focus-state-layer-color: var(
        --color-foreground-slider
    );
    --mdc-switch-selected-handle-color: var(--color-foreground-slider);
    --mdc-switch-selected-hover-state-layer-color: var(
        --color-foreground-slider
    );
    --mdc-switch-selected-pressed-state-layer-color: var(
        --color-foreground-slider
    );
    --mdc-switch-selected-focus-handle-color: var(--color-foreground-slider);
    --mdc-switch-selected-hover-handle-color: var(--color-foreground-slider);
    --mdc-switch-selected-pressed-handle-color: var(--color-foreground-slider);
    --mdc-switch-selected-focus-track-color: var(--color-background-slider);
    --mdc-switch-selected-hover-track-color: var(--color-background-slider);
    --mdc-switch-selected-pressed-track-color: var(--color-background-slider);
    --mdc-switch-selected-track-color: var(--color-background-slider);
}
