.dxy-button {
    @each $variant in (primary, secondary, tertiary) {
        &.dxy-button--#{$variant} {
            $variantPart: any;
            @if $variant == primary {
                $variantPart: primary;
            }
            &.dxy-button-- {
                @each $color in (default, ai, destructive, success, warning) {
                    &#{$color} {
                        --background-color: var(
                            --buttons-background-#{$variantPart}-#{$color}-resting
                        );
                        --background-color-hover: var(
                            --buttons-background-#{$variantPart}-#{$color}-hover
                        );
                        --background-color-active: var(
                            --buttons-background-#{$variantPart}-#{$color}-whilepressing
                        );
                        --background-color-disabled: var(
                            --buttons-background-#{$variantPart}-#{$color}-disabled
                        );
                        --background-color-visited: var(
                            --buttons-background-#{$variantPart}-#{$color}-onloading
                        );
                    }
                }
            }
        }
    }
    &.dxy-button--secondary,
    &.dxy-button--tertiary {
        &.dxy-button--default {
            --background-color-hover: var(
                --buttons-background-any-grayscale-hover
            );
            --background-color-active: var(
                --buttons-background-any-grayscale-whilepressing
            );
        }
        &.dxy-button--destructive {
            --color-hover: var(--buttons-foreground-any-destructive-hover);
        }
        &.dxy-button--success {
            --color-hover: var(--buttons-foreground-any-success-hover);
        }
        &.dxy-button--warning {
            --color-hover: var(--buttons-foreground-any-warning-hover);
        }
        &.dxy-button--ai {
            --color: var(--buttons-foreground-any-ai-resting);
        }
    }
    &.dxy-button--transparent {
        --background-color: transparent;
        --background-color-hover: var(
            --buttons-background-any-transparent-hover
        );
        --background-color-active: var(
            --buttons-background-any-transparent-whilepressing
        );
        --background-color-disabled: transparent;
        --background-color-visited: transparent;
    }
}
