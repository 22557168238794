$dat-transition-default-timing-fn: cubic-bezier(0.55, 0.09, 0.68, 0.53);
$dat-transition-default-duration: 0.2s;
$dat-transition-long-duration: 0.5s;

@mixin transition(
    $property,
    $duration: $dat-transition-default-duration,
    $timing: $dat-transition-default-timing-fn,
    $delay: 0s
) {
    transition: #{$property} #{$duration} #{$timing} #{$delay};
}
