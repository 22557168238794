dxy-menu-bar-view {
    h1 {
        margin-top: 0;
    }
    .dg5-beta-badge {
        position: relative;
        top: -7px;
        left: 5px;
    }
}
