@use 'base/text' as text;
@use 'palette';

dxy-text-attributes-fill-percentage-widget {
    display: flex;
    overflow: visible;
    flex-direction: column;
    max-height: 100%;
    position: relative;
    height: 100%;
    .svg-wrapper {
        flex: 1;
        overflow: auto;
    }

    .g-num {
        fill: palette.$blue-50;
    }

    .g-num2 {
        fill: rgba(0, 0, 255, 0.05);
    }

    .g-labels-percentage {
        font-size: text.$small-text-size;
        text-align: right;
    }

    .x.axis line,
    .y.axis line {
        display: none;
    }

    .x.axis text {
        display: none;
    }

    .domain {
        display: none;
    }

    .g-labels {
        font-size: text.$normal-text-size;
        font-weight: 450;
        fill: palette.$reflex-100;
    }
}
