.mat-menu-panel.dropdown-section--mat-menu {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 350px;
    max-width: 350px !important;

    > .mat-menu-content {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        > ul {
            padding: 0;
        }
    }
}
