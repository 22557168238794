@use 'mixins/mixin-flex' as *;
@use 'palette';
@use 'base/text' as text;
@use 'caption-position/mixin-caption-position' as *;
@use 'glyphs' as *;

$source-port-size: 8px;
$source-port-margin: 6px;
$element-header-height: 60px;

// IMPORTANT: no z-index here. see diagram-designer.global.scss

app-diagram-element {
    display: flex;
    flex-direction: column;

    &:hover .diag-elem-content:not(.diag-frame) {
        box-shadow: var(--color-shadow-any-neutral);
    }

    .source-port {
        position: absolute;
        $outer-size: $source-port-size + 2 * $source-port-margin;
        width: $outer-size;
        height: $outer-size;
        &.head-left,
        &.head-right {
            &:not(.for-nano) {
                top: calc(($element-header-height - $outer-size) / 2);
            }
            &.for-nano {
                top: calc((10px - $outer-size) / 2);
            }
        }
        $shift: -$outer-size - $source-port-margin;
        &.head-left {
            left: $shift;
        }
        &.head-right {
            right: $shift;
        }
        &::before {
            position: absolute;
            margin: 50%;
            opacity: 0;
            transition: all 300ms ease;
            transform: translate(-50%, -50%);
            color: palette.$reflex-40;
            font-family: 'DataGalaxy';
            font-size: 25px;
            background-color: white;
            border-radius: 50%;
            box-shadow: var(--color-shadow-any-light);
        }
        &:not(.has-links)::before {
            content: $glyph-handle-simple;
        }
        &.has-links {
            &.head-left::before {
                content: $glyph-handle-left;
            }
            &.head-right::before {
                content: $glyph-handle-right;
            }
        }
        &:hover::before {
            transform: translate(-50%, -50%) scale(150%);
        }
        cursor: var(--link-cursor-url),
            url(/images/cursors/cursor-link-20px.png), default;
    }
    &:hover .source-port::before,
    .source-port:hover::before {
        opacity: 1;
    }

    //#region debug elements
    &.debug {
        //background: rgba(99, 99, 99, .3);
        .diag-elem-content {
            outline: 1px dashed red;
        }
        textarea {
            border: 1px dashed purple !important;
        }
    }
    .debug-wrapper {
        position: relative;
        margin-top: auto;
        .debug-content {
            position: absolute;
            color: black;
            font-size: text.$tiny-text-size;
        }
    }
    //#endregion
}

.diagramming app-diagram-element {
    &:not(.selected),
    &.gs-dragging {
        .diag-elem-content textarea:not(:focus) {
            pointer-events: none;
            cursor: grab;
        }
    }
}
//#endregion

// While dragging a frame,
// the .diag-elem-content of a contained node
// is reparented from app-diagram-element up to .jtk-surface-canvas
app-diagram-designer .diag-elem-content {
    flex: 1;
    &:not(.diag-frame) {
        box-shadow: var(--color-shadow-any-light);
        transition: box-shadow 300ms ease;
    }
    &:not(.size-mode-nano) {
        display: flex;
        flex-direction: column;
    }
    &.size-mode-nano {
        height: 100%;
        width: 100%;
    }
    &.diag-note,
    &.diag-frame {
        flex-grow: 1;
        overflow: hidden;
        dxy-graphical-textarea {
            --inside-margin: 20px;
            --outside-margin: 10px;
            textarea {
                width: 100%;
            }
        }
    }
    &.diag-note {
        padding: 8px;
        box-shadow: 0px 4px 8px rgba(16, 53, 177, 0.2);
    }
    &.diag-note textarea {
        color: inherit;
    }
    &.diag-frame textarea {
        color: black;
    }
    &.diag-entity {
        border-radius: 3px;
        padding: 0;
        &:not(.size-mode-nano) {
            app-entity-card-cell,
            .entity-children {
                padding: 8px;
            }
        }
        .entity-data-container {
            flex-grow: 1;
        }
        .action-list {
            position: absolute;
            right: 0;
            background: white;
        }

        .expand-btn-container {
            position: absolute;
            inset: auto 0 -26px 0;
            height: 19px;
            padding: 2px 4px 2px 8px;
            border-radius: 3px;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            .expand-btn {
                display: flex;
                cursor: pointer;
                background: transparent;
                padding: 0 8px;
                line-height: 14px;
                height: text.$normal-text-size;
                border: none;
                .expand-btn-text {
                    padding-left: 8px;
                }
                [class^='glyph-'] {
                    font-size: 22px;
                    line-height: 12px;
                }
            }
        }

        &:not(.size-mode-nano) {
            background: white;
            border-radius: 3px;
            // #chrome-bug Asymmetric borders with radius cause blurry text
            // &.graphical-color-border {
            //     border-left-width: 6px;
            //     border-left-style: solid;
            //     &.size-mode-mini { padding-left: 5px; }
            // }
            // so we use this trick:
            &.graphical-color-background-before {
                border-left-width: 6px;
                border-left-style: solid;
            }
        }
        &.size-mode-nano {
            border-radius: 50%;
        }
        &.size-mode-mini {
            dxy-action-list {
                .content {
                    overflow-x: visible; /* for the burger menu icon */
                }
                .action-list {
                    position: absolute;
                    right: -18px;
                    top: -18px;
                    background: white;
                    border-radius: 5px;
                }
            }
        }
        &.size-mode-maxi {
            flex-grow: 1;
            overflow-y: hidden;
        }
    }
}
