.dxy-actions {
    > .dxy-button ~ .dxy-button,
    > .dxy-button ~ .dxy-button-group,
    > .dxy-button-group ~ .dxy-button {
        --actions-gap: 12px;
        margin-left: var(--actions-gap);
        &.dxy-button--medium,
        &.dxy-button--small {
            --actions-gap: 8px;
            &.dxy-button--tertiary {
                --actions-gap: 4px;
            }
        }
    }
}
