dxy-collapsible {
    &.body-padding .mat-expansion-panel-content .mat-expansion-panel-body {
        padding: 10px;
    }
    .mat-expansion-panel-content {
        font-size: inherit;
        &.no-header.collapsed {
            padding: 0;
        }

        .mat-expansion-panel-body {
            padding: 0;
        }
    }
}

// Fix empty collapsible when parent div is animated
.ng-animating {
    .mat-expansion-panel {
        &.mat-expanded {
            .mat-expansion-panel-content {
                height: fit-content !important;
                visibility: inherit !important;
                * {
                    visibility: inherit !important;
                }
            }
        }
        &:not(.mat-expanded) {
            .mat-expansion-panel-content {
                height: 0 !important;
                visibility: hidden !important;
            }
        }
    }
}
