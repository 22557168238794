button.mat-icon-button {
    &.mat-calendar-next-button,
    &.mat-calendar-previous-button {
        &::after {
            border: 0;
            transform: none;
            margin: unset;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;
        }

        &::before {
            content: '';
            height: 10px;
            width: 10px;
        }
    }

    &.mat-calendar-next-button::before {
        border-right: 2px solid var(--color-foreground-any-secondary);
        border-top: 2px solid var(--color-foreground-any-secondary);
        transform: translateX(-2px) rotate(45deg);
    }
    &.mat-calendar-previous-button::before {
        border-left: 2px solid var(--color-foreground-any-secondary);
        border-bottom: 2px solid var(--color-foreground-any-secondary);
        transform: translateX(2px) rotate(45deg);
    }
}

@mixin date-cell-hover-background() {
    background-color: var(--color-background-any-tertiary);
}

/* Override dates cells background color on hover **/
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
    ) {
    @include date-cell-hover-background();
}
.cdk-keyboard-focused,
.cdk-program-focused {
    .mat-calendar-body-active
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
            .mat-calendar-body-comparison-identical
        ) {
        @include date-cell-hover-background();
    }
}
