app-client-admin {
    .main-view-wrapper {
        > *:not(router-outlet) {
            overflow: auto;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
        }
    }
}

.client-admin-body {
    display: flex;
    flex: 1;
    overflow: hidden;
    height: unset;
}
.client-admin-content--left-column,
.client-admin-content--right-column {
    box-shadow: var(--color-shadow-any-light);
    overflow: hidden !important;
    display: flex;
    border-radius: 10px 10px 0 0;
    flex-direction: column;
    height: unset !important;
}
.client-admin-content--left-column {
    margin: 10px 10px 0 10px;
    width: 260px;

    &.as-split-area {
        margin-right: 0;
    }
    dxy-search-input {
        margin: 5px 0;
    }
}
.client-admin-content--right-column {
    margin: 10px 10px 0 0;
}
