@use 'palette';

// DgModule.unknown items
.dg5-common-color {
    color: palette.$gray-60 !important;
    fill: palette.$gray-60;
}

// DgModule.Glossary items
.functional-static-color {
    color: palette.$green-50 !important;
    fill: palette.$green-50;
}

// DgModule.Usage items
.functional-dynamic-color {
    color: palette.$grass-50 !important;
    fill: palette.$grass-50;
}

// DgModule.Catalog items
.technical-static-color {
    color: palette.$blue-50 !important;
    fill: palette.$blue-50;
}

// DgModule.Processing items
.technical-dynamic-color {
    color: palette.$reflex-60 !important;
    fill: palette.$reflex-60;
}
