@use '../../palette' as palette;

body {
    --color-background-button-pulse: #3d5cfe55;
    --color-border-filterButton: #{palette.$reflex-08};

    --color-background-button-primary: #{palette.$reflex-50};
    --color-background-button-primary--disabled: #{palette.$reflex-40};
    --color-background-button-transparent--hover: rgba(1, 30, 183, 0.08);

    --color-background-button-ai: #{palette.$violet-50};
    --color-background-button-ai--hover: #{palette.$violet-60};

    --color-background-button-danger: #{palette.$magenta-50};
    --color-background-button-danger--hover: #{darken(palette.$magenta-50, 5%)};

    --color-foreground-icon-accept: #{palette.$green-50};
    --color-foreground-icon-reject: #{palette.$magenta-50};
}
