@import './base/mixin-flex.scss';

.no-grow {
    flex-grow: 0;
}

.flex-grow {
    width: 100%;
    @include flexItem(1);
}

.display-flex {
    @include flexbox();
}

.txt-center {
    text-align: center !important;
}

.dg_u-pos-rel {
    position: relative !important;
}
