@use 'base/text' as text;
@use 'palette';

dxy-evolution-of-entity-count-by-entity-type-widget {
    .svg-wrapper {
        flex: 1;

        .lines {
            cursor: pointer;
            &:hover {
                stroke-width: 5;
            }
        }
        .lines {
            &-count {
                font-size: text.$small-text-size;
                font-weight: 300;
            }
        }
    }

    .legend {
        display: inline-block;
        &-point {
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-right: 10px;
            border-radius: 3px;
        }
        &-text {
            display: inline-block;
            margin-right: 10px;
            font-size: text.$tiny-text-size;
        }
    }

    .y.axis {
        .domain,
        line {
            display: none;
        }
    }
    .x.axis {
        line {
            display: none;
        }
        .domain {
            stroke: palette.$reflex-08;
        }
    }
}
