/** mixin to apply caption-position classes
    to a textarea or span, relative to its parent, inside or outside
*/
@mixin caption-position($inside-margin: 20px, $outside-margin: 10px) {
    &.inside {
        margin: $inside-margin;
        &:not(.full-height) {
            &.middle,
            &.top {
                margin-bottom: auto;
            }
            &.middle,
            &.bottom {
                margin-top: auto;
            }
        }
    }
    &.outside {
        position: absolute;
        &.left {
            left: 0;
        }
        &.center {
            left: 50%;
            transform: translateX(-50%);
        }
        &.right {
            right: 0;
        }
        &.top {
            bottom: calc(100% + #{$outside-margin});
        }
        &.middle {
            display: none;
        }
        &.bottom {
            top: calc(100% + #{$outside-margin});
        }
    }
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
}
