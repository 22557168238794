// Figma : https://www.figma.com/file/v1taP0qzivTXCv8Sco6E5f/Styles?type=design&node-id=7149%3A2&mode=design

$white-10: #ffffff;

// Gray
$gray-05: #f7f8f9;
$gray-10: #f1f1f4;
$gray-40: #d5d5dd;
$gray-50: #aaabbb;
$gray-60: #6d6f88;

// Reflex

$reflex-10: #f3f6ff;
$reflex-40: #8599fe;
$reflex-50: #3d5cfe;
$reflex-60: #011eb7;
$reflex-100: #001030;
$reflex-03: rgba($reflex-60, 0.03);
$reflex-05: rgba($reflex-60, 0.05);
$reflex-08: rgba($reflex-60, 0.08);

// Blue
$blue-gray-05: #d9ecf3;
$blue-10: #dbf1fa;
$blue-40: #97d5f0;
$blue-50: #28aae2;

// Turquoise
$turquoise-10: #d4f7f1;
$turquoise-40: #91eadb;
$turquoise-50: #11d4b7;

// Purple
$purple-10: #efd9f2;
$purple-40: #ce8ad7;
$purple-50: #ad00bf;

// Violet
$violet-gray-05: #e2d8f5;
$violet-05: #eee8f8;
$violet-10: #d9c4ff;
$violet-40: #ad94d9;
$violet-50: #5b24b2;
$violet-60: #31017e;

// Magenta
$magenta-gray-05: #f3dfe6;
$magenta-10: #ffccdf;
$magenta-40: #ff99be;
$magenta-50: #ca004a;

// Orange
$orange-10: #ffe1cc;
$orange-40: #ffc094;
$orange-50: #ff811a;

// Yellow
$yellow-10: #fff2cc;
$yellow-40: #ffe091;
$yellow-50: #ffbf00;

// Lime
$lime-10: #f4f9d2;
$lime-40: #e4f192;
$lime-50: #c8e200;

// Green
$green-10: #e5f6df;
$green-40: #a8e292;
$green-50: #50c516;

// Grass
$grass-10: #e4f1ea;
$grass-40: #8cc4a6;
$grass-50: #12884b;

// Palette
$badge-palette: (
    'OrangeD': #cb4800,
    'OrangeM': #e57400,
    'OrangeL': #f1a600,
    'RedD': #7d0000,
    'RedM': #d30027,
    'RedL': #fc6565,
    'PinkD': #841052,
    'PinkM': #e01882,
    'PinkL': #f074c0,
    'PurpleD': #4a148c,
    'PurpleM': #761fde,
    'PurpleL': #b98bef,
    'BlueD': #1a237e,
    'BlueM': #404ed7,
    'BlueL': #3f19f5,
    'GreenD': #006836,
    'GreenM': #009245,
    'GreenL': #8cc63f,
);
