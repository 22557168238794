@use 'graphical-colors.global' as *;

$line-style-dasharrays: (
    'line-style-dashed_2_2': 2 2,
    'line-style-dashed_3_3': 3 3,
    'line-style-dashed_7_2': 7 2,
    'line-style-dashed_2_2_6_2': 2 2 6 2,
);

.gs-connector-path {
    path {
        &.stroke,
        &.outline {
            cursor: pointer;
            fill: none;
            pointer-events: stroke;
        }

        &.stroke {
            @each $thickness in (1, 2, 4, 6) {
                &.thick-#{$thickness} {
                    stroke-width: #{$thickness};
                }
            }
            @each $name, $color in $graphical-colors {
                &.color-#{$name} {
                    stroke: $color;
                }
            }
            @each $name, $dasharray in $line-style-dasharrays {
                &.#{$name} {
                    stroke-dasharray: $dasharray;
                }
            }
        }

        &.outline {
            stroke: transparent;
            transition: stroke 150ms ease-in-out;
            stroke-width: 20;
        }
    }
}
