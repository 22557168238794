@use './variables' as *;

.glyph-burger {
    &:before {
        transform: rotate(90deg);
        content: $glyph-splitter;
    }
}

.glyph-copy {
    &:before {
        content: $glyph-add;
    }
}
