@use '../../palette' as palette;

body {
    --color-background-sidebar--hover: rgba(20, 74, 182, 0.43);

    --color-sidebar: #{palette.$gray-50};
    --color-sidebar-active: white;

    --color-background-sidebar-hover: #{palette.$reflex-50}55;
    --color-background-sidebar-active: #{palette.$reflex-50};

    --color-background-sidebar-glossary-hover: #{palette.$green-50}55;
    --color-background-sidebar-glossary-active: #{palette.$green-50};

    --color-background-sidebar-catalog-hover: #{palette.$blue-50}55;
    --color-background-sidebar-catalog-active: #{palette.$blue-50};

    --color-background-sidebar-dataprocessing-hover: #{palette.$reflex-50}55;
    --color-background-sidebar-dataprocessing-active: #{palette.$reflex-60};

    --color-background-sidebar-software-hover: #{palette.$grass-50}55;
    --color-background-sidebar-software-active: #{palette.$grass-50};

    --color-background-sidebar-home-button: #{palette.$reflex-50};
    --color-border-sidebar-step: #{palette.$gray-05}33;
}
