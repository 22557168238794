@use 'base/text' as text;
@use 'palette';

// Define style for route to component usage
[ui-view],
ui-view {
    > dxy-activity-log-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
app-entity-docking-pane {
    dxy-activity-log-container {
        dxy-field-date {
            max-width: 115px;
            .clear-btn-icon {
                position: absolute;
                top: -20px;
            }
        }
    }
}

dxy-activity-log-container {
    padding-bottom: 50px;
    flex-grow: 1;

    .actions-container {
        flex-shrink: 0;
        display: flex;
        align-items: flex-end;
        margin-bottom: 0;
    }

    .content-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .activity-head {
        margin-bottom: 10px;
        padding-bottom: 0;

        .activity-head-row {
            display: flex;
            flex-direction: row;
            align-items: stretch;

            .activity-head-column {
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                &.half-width {
                    width: 50%;
                }
            }
        }
    }

    .activity-body-main {
        max-height: fit-content;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        padding: 10px;
        margin: 10px;
        box-shadow: 0 0 14px var(--color-shadow-any);
        border-radius: 6px;
    }

    .logElement-title {
        color: palette.$gray-50;
        font-size: text.$small-text-size;
    }
    dxy-activity-log-element {
        border-top: 1px solid palette.$reflex-08;
        &:first-child {
            border-top: unset;
        }
    }
    .logElement-item {
        display: inline-block;
        font-size: text.$normal-text-size;
        white-space: normal;
        word-break: break-word;
        overflow: hidden;
        width: 100%;
    }
}
