@use 'palette';

dxy-csv-preimport-check {
    .glyph-warning {
        color: var(--color-foreground-any-warning) !important;
        padding: 2px;
        margin-right: 5px;
    }
    .glyph-mandatory {
        color: var(--color-foreground-any-danger) !important;
        padding: 2px;
        margin-right: 10px;
    }
}
