@use 'base/text' as text;
@use 'glyphs' as *;
@use 'palette';

dxy-edit-grid,
.mat-row.dxy-edit-grid-order-by-drag-preview {
    --header-height: 50px;
    --row-height: 48px; // material's default
    &.no-font-size {
        .mat-cell,
        .mat-header-cell,
        .mat-footer-cell {
            font-size: inherit;
        }
    }
    &.no-input-padding {
        .mat-cell input {
            padding: unset;
        }
    }
    &.no-cell-padding {
        .mat-cell {
            padding: unset;
        }
    }

    // Material default is 24px on first column
    &.no-global-left-padding {
        .mat-header-cell,
        .mat-cell,
        .mat-footer-cell {
            &:first-of-type {
                padding: 0 5px;
            }
        }
    }

    &.compact {
        .mat-cell,
        .mat-footer-cell,
        .mat-cell input {
            font-size: text.$normal-text-size;
        }
        .mat-header-cell {
            font-size: text.$small-text-size;
        }
        .mat-cell,
        .mat-header-cell,
        .mat-footer-cell {
            padding: 0 5px;
        }
        .mat-cell input {
            padding: 0;
        }
    }
    &.ellipsis {
        .mat-header-cell,
        .mat-cell,
        .mat-sort-header-container,
        .mat-sort-header-content {
            overflow: hidden;
            width: auto;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .mat-sort-header-content {
            display: inline;
        }
    }

    //#region for resizable columns
    .mat-table.resizable {
        &.resizing {
            user-select: none;
        }
        .mat-header-cell {
            position: relative;

            .mat-sort-header-container {
                height: 26px;
            }

            &:not(:last-child) .resize-handle {
                cursor: col-resize;
                width: 10px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
                border-right: 1px solid palette.$reflex-05;
            }
        }
    }
    //#endregion

    .mat-row {
        min-height: var(--row-height);
        border-color: var(--color-border-any);

        .mat-cell {
            cursor: default;
            font-size: text.$normal-text-size;
        }
    }

    mat-checkbox {
        margin-left: 10px;
        margin-right: 10px;
    }

    .selector-readonly-glyph {
        margin-right: 5px;
    }

    .mat-column-__children {
        min-width: 78px;
    }

    .mat-mdc-slide-toggle.displayed-on-hover {
        display: none;
    }

    .mat-header-row {
        max-height: var(--header-height);
        min-height: var(--header-height);
        background-color: palette.$reflex-05;
        border-top: 1px solid palette.$reflex-08;
        border-bottom: 1px solid palette.$reflex-08;
        border-radius: 3px;
    }
    .mat-header-cell {
        font-weight: 700;
        color: palette.$reflex-60;
        font-size: text.$small-text-size;

        .mat-sort-header-arrow {
            font-family: Datagalaxy;
            transform: none !important;
            opacity: 0.87 !important;
            > * {
                display: none;
            }
            &:before {
                content: $glyph-unsorted;
            }
        }

        &[aria-sort='ascending'] {
            .mat-sort-header-arrow:before {
                content: $glyph-asc-sort;
                opacity: 1;
            }
        }
        &[aria-sort='descending'] {
            .mat-sort-header-arrow:before {
                content: $glyph-desc-sort;
            }
        }
    }

    .mat-row:hover {
        background-color: palette.$reflex-03;

        .mat-mdc-slide-toggle {
            &.displayed-on-hover {
                display: flex;
            }
        }
    }

    .mat-cell,
    .mat-header-cell,
    .mat-footer-cell {
        border-color: var(--color-shadow-any);
    }

    .mat-cell,
    .mat-header-cell .mat-sort-header-container,
    .mat-footer-cell {
        padding: 0 10px;
    }

    mat-cell:last-of-type,
    mat-header-cell:last-of-type,
    mat-footer-cell:last-of-type {
        padding-right: 10px;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 10px;
    }

    .mat-cell,
    .mat-header-cell {
        > .mat-checkbox .mat-checkbox-layout {
            margin-bottom: 0;
        }
    }

    .mat-cell.order-by-drag > .grab-icon {
        color: var(--color-foreground-any-secondary);
        cursor: grab;
        &:active {
            cursor: grabbing;
        }
    }
    .mat-cell.input-cell input {
        width: 100%;
        border: unset;
        background-color: transparent;
    }
}

.mat-row.dxy-edit-grid-order-by-drag-preview {
    &.compact {
        opacity: 0 !important;
    }
    min-height: var(--row-height);
    height: var(--row-height) !important;
    max-height: var(--row-height) !important;
}
