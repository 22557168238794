dxy-rich-text-field {
    .mat-form-field-infix {
        padding-top: 1em;
    }
    mat-label,
    .mat-form-field-label {
        height: 32px;
    }
    mat-label {
        display: flex !important;
        align-items: center;
        justify-content: center;
        > span {
            padding-top: 1px;
        }
    }
    &.no-padding {
        &.dxy-field .read-only-field {
            margin: 0;
            padding: 0;
        }
        .mat-form-field-wrapper,
        .mat-form-field-flex {
            padding: 0;
        }
        .mat-form-field-infix {
            padding: 0;
            border: none;
        }
        .ql-editor {
            padding: 0;
        }
    }
    &.no-underline {
        .read-only-separator {
            display: none;
        }
        .mat-form-field-underline {
            height: 0;
        }
        .mat-form-field-ripple {
            display: none;
        }
    }

    dxy-rich-text-field-control {
        .editor-container {
            .editor-pane {
                background: unset;
                border: unset;
                font-family: unset;

                .ql-editor {
                    padding: 0;
                    overflow-y: visible;
                    overflow-x: hidden;
                }
            }
            .editor-toolbar {
                border: unset;
                padding: 0;
                margin-top: 10px;

                .ql-picker-label {
                    padding-left: 0;
                }
            }
        }
    }

    .read-only-field {
        label {
            display: flex;
            align-items: center;
        }
    }
}
