@use 'base/text' as text;
@use 'palette';

.dg_dataVizObjectGraph-element > text.item-icon {
    font-family: datagalaxy;
    transform: translate(90px, 55px);
    font-size: 20px;
}

.dg_dataVizObjectGraph-name {
    font-size: text.$normal-text-size;
    height: 20px;
    width: 200px;
}

.dg_dataVizObjectGraph-type {
    font-size: text.$small-text-size;
    font-style: italic;
    height: 18px;
    width: 100px;
}

.dg_dataVizObjectGraph-name,
.dg_dataVizObjectGraph-type {
    color: palette.$gray-60;
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
}

.dg_dataVizObjectGraph-element:hover .dg_dataVizObjectGraph-disque-select {
    stroke-width: 10px !important;
}

.dg_dataVizObjectGraph-element[selected='true']
    .dg_dataVizObjectGraph-disque-select {
    stroke-width: 10px !important;
}

.dg_dataVizObjectGraph-element[root='true']
    .dg_dataVizObjectGraph-disque-select {
    stroke-width: 20px !important;
}

.dg_dataVizObjectGraph-disque-select {
    transition: all 0.2s ease;
    opacity: 0.2;
}

.dg_dataVizObjectGraph-element {
    &.functional-static-color {
        .dg_dataVizObjectGraph-disque,
        .dg_dataVizObjectGraph-disque-select {
            stroke: palette.$green-50 !important;
        }
    }
    &.functional-dynamic-color {
        .dg_dataVizObjectGraph-disque,
        .dg_dataVizObjectGraph-disque-select {
            stroke: palette.$grass-50 !important;
        }
    }
    &.technical-static-color {
        .dg_dataVizObjectGraph-disque,
        .dg_dataVizObjectGraph-disque-select {
            stroke: palette.$blue-50 !important;
        }
    }
    &.technical-dynamic-color {
        .dg_dataVizObjectGraph-disque,
        .dg_dataVizObjectGraph-disque-select {
            stroke: palette.$reflex-60 !important;
        }
    }

    .dg_dataVizObjectGraph-disque {
        stroke-width: 2px;
    }
}

.dg_dataVizObjectGraph-element[cluster='false'] {
    &.functional-static-color .dg_dataVizObjectGraph-disque {
        fill: palette.$green-50 !important;
    }
    &.functional-dynamic-color .dg_dataVizObjectGraph-disque {
        fill: palette.$grass-50 !important;
    }
    &.technical-static-color .dg_dataVizObjectGraph-disque {
        fill: palette.$blue-50 !important;
    }
    &.technical-dynamic-color .dg_dataVizObjectGraph-disque {
        fill: palette.$reflex-60 !important;
    }
    text.item-icon {
        fill: white;
    }
}

.dg_dataVizObjectGraph-element[cluster='true'] {
    .dg_dataVizObjectGraph-disque {
        fill: white !important;
    }
    &.functional-static-color text.item-icon {
        fill: palette.$green-50;
    }
    &.functional-dynamic-color text.item-icon {
        fill: palette.$grass-50;
    }
    &.technical-static-color text.item-icon {
        fill: palette.$blue-50;
    }
    &.technical-dynamic-color text.item-icon {
        fill: palette.$reflex-60;
    }
}

.lineage-root-item
    .dg_dataVizObjectGraph-element[cluster='false'].functional-static-color
    .cap {
    fill: palette.$orange-50;
    stroke: none !important;
}
.dg_dataVizObjectGraph-element[cluster='false'].functional-static-color .cap {
    fill: palette.$green-50;
    stroke: none !important;
}
.dg_dataVizObjectGraph-element[cluster='true'].functional-static-color .cap {
    fill: white;
    stroke: none !important;
}

.dg_dataVizObjectGraph-active .dg_dataVizObjectGraph-disque-select {
    stroke-width: 20px !important;
}

.dg_dataVizGraphObject-graphicalLink {
    stroke: #bbb;

    &--TableColumns,
    &--ModelTables,
    &--EntityLink {
        @extend .dg_dataVizGraphObject-graphicalLink;
    }

    &--PropertyLinkedColumns,
    &--PropertyLinkedTables,
    &--PropertyLinkedContainers,
    &--PropertyLinkedModels,
    &--PropertyLinkedProperties,
    &--SoftwareElementLinkedColumns,
    &--SoftwareElementLinkedTables,
    &--SoftwareElementLinkedModels,
    &--SoftwareElementLinkedContainers,
    &--SoftwareElementLinkedProperties {
        @extend .dg_dataVizGraphObject-graphicalLink;
        stroke-dasharray: 1, 3;
        stroke-linecap: round;
    }

    &--DataProcessingInputColumns,
    &--DataProcessingOutputColumns,
    &--DataProcessingInputOutputColumns,
    &--DataProcessingInputTables,
    &--DataProcessingOutputTables,
    &--DataProcessingInputOutputTables,
    &--DataProcessingInputModels,
    &--DataProcessingOutputModels,
    &--DataProcessingInputOutputModels,
    &--DataProcessingInputContainers,
    &--DataProcessingOutputContainers,
    &--DataProcessingInputOutputContainers {
        @extend .dg_dataVizGraphObject-graphicalLink;
        stroke-dasharray: 5, 5;
        stroke-linecap: round;
    }
}
