:root {
    --input-border-color-resting: transparent;
    --input-border-color-always-on-resting: var(--color-neutral-lighter);
    --input-border-color-hover: var(--color-neutral-light);
    --input-border-color-focus: var(--color-brand-default);
    --input-border-color-error: var(--color-danger-default);
    --input-error-color: var(--color-danger-default);
    --input-text-size: var(--typography-fontsize-s);
    --input-text-size-small: var(--typography-fontsize-xs);
    --input-border-radius: var(--radius-rd-ml);
    --input-padding-medium: var(--padding-pd-l);
    --input-padding-small: var(--padding-pd-m);
    --input-padding-xs: var(--padding-pd-s);
    --input-gap-size: var(--gap-gp-s);
    --input-read-only-opacity: 0.7;
    --input-error-line-height: 15px;
    --input-text-line-height: 18px;
    --input-font-size: var(--typography-fontsize-s);
}
