dxy-field-hyperlink {
    &.mini {
        dxy-hyperlink-field-control {
            .inputs-wrapper {
                background-color: var(--color-background-any);
                position: absolute;
                top: -2px;
                bottom: -5px;
                left: 0;
                right: 0;
                z-index: 10;
                .input-group {
                    display: block;
                    .input-url {
                        display: block;
                    }
                }
            }
            .no-edit {
                a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: calc(100% - 24px);
                    display: inline-block;
                    vertical-align: bottom;
                }
            }
        }
    }
}
