dxy-screen-layout {
    .group-container.group-container-wrap {
        flex-wrap: wrap;
    }
}
dxy-screen-layout-category {
    max-width: 100%;
    dxy-entity-form {
        flex-grow: 1;
        max-width: 100%;
    }
    display: flex;
}
