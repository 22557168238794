.gs-connector {
    position: absolute;
    overflow: visible;
    pointer-events: none;

    &.editing,
    &.selected {
        path.outline {
            stroke: rgba(0, 100, 255, 0.16);
            opacity: 1;
        }
    }

    &:hover {
        path.outline {
            stroke: rgba(0, 100, 255, 0.1);
            opacity: 1;
        }
    }

    &.connecting {
        /**
         * Prevent connecting edges from being hovered by pointer events
         * This way it will not be highlighted when the mouse is over it for example
         * It also prevent tooltips from showing up ect...
         * Note: the override scss is from connector/path/path.scss
         */
        .gs-connector-path {
            path {
                &.stroke,
                &.outline {
                    pointer-events: none !important;
                }
            }
        }
    }
}
