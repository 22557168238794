@use 'base/text' as text;

.dxy-fab-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 0px 14px 0px var(--color-shadow-any);
    background: var(--color-background-any);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
