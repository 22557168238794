.dxy-button {
    &.dxy-button--large {
        --gap: var(--buttons-gap-l, 8px);
        --padding: var(--buttons-padding-y-l, 12px)
            var(--buttons-padding-x-l, 16px);
        --border-radius: var(--buttons-radius-l, 8px);
        --icon-size: var(--buttons-icon-size-m, 16px);
        --font-size: var(--typography-fontSize-s, 13px);
        --line-height: var(--typography-lineHeight-m, 16px);
    }
    &.dxy-button--medium {
        --gap: var(--buttons-gap-m, 8px);
        --padding: var(--buttons-padding-y-m, 8px)
            var(--buttons-padding-x-m, 12px);
        --border-radius: var(--buttons-radius-m, 8px);
        --icon-size: var(--buttons-icon-size-m, 16px);
        --font-size: var(--typography-fontSize-s, 13px);
        --line-height: var(--typography-lineHeight-m, 16px);
    }
    &.dxy-button--small {
        --gap: var(--buttons-gap-s, 8px);
        --padding: var(--buttons-padding-y-s, 4px)
            var(--buttons-padding-x-s, 4px);
        --border-radius: var(--buttons-radius-s, 8px);
        --icon-size: var(--buttons-icon-size-s, 8px);
        --font-size: var(--typography-fontSize-xs, 11px);
        --line-height: var(--typography-lineHeight-m, 16px);
        i {
            padding: var(--buttons-icon-iconContainerOffset-default, 2px);
        }
    }
}
