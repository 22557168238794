@use 'palette';

.mat-menu-panel.split-button--mat-menu {
    dxy-option-list {
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            padding: 5px 20px;
            white-space: nowrap;
            user-select: none;
            cursor: pointer;

            &:hover {
                color: palette.$reflex-100;
                background-color: palette.$reflex-08;
            }
        }
    }
}
