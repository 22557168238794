@use 'base/text' as text;

.dxy-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dxy-placeholder-img {
        width: 440px;
        margin-bottom: 15px;
    }
    h3 {
        font-size: text.$title-text-size;
        line-height: text.$title-line-height;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .dxy-placeholder-text {
        color: var(--color-foreground-any-secondary);
    }
}
