﻿dxy-task-item {
    dxy-user-tag {
        .dg_user-avatar,
        .dg_mini-user-initials {
            margin-right: 10px !important;
        }
    }

    .read-only-field .value-text-readonly {
        height: 22px;
        margin: 0;
    }

    dxy-field-date .mat-form-field-infix input {
        height: 20px;
    }
}
