@use 'mixins';
@use 'palette';

dxy-entity-selector-field {
    .entity-element {
        margin: 2px 2px 2px 0;
    }

    .mini-value-content {
        display: flex;
        height: 17px;
        .value-text {
            padding-left: 5px;
            flex-grow: 1;
            @include mixins.ellipsis();
        }
    }

    .value-container .glyph {
        margin-right: 0 !important;
    }

    dxy-field-select {
        .value-container dxy-option-item,
        mat-select-trigger dxy-option-item {
            width: unset;
            display: flex;
            height: 24px;
            margin-right: 5px;
            margin-bottom: 5px;
            padding-right: 4px;
            padding-left: 2px;
            align-items: center;
            align-self: center;
            border-radius: 12px 3px 3px 12px;
            cursor: pointer;
            background-color: palette.$reflex-05;
        }
    }
}

.filters-wrappers {
    padding: 5px;
    display: flex;
    border-bottom: 1px solid var(--color-border-any);

    .filter {
        display: flex;
    }
}
