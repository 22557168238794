@use 'glyphs' as *;
@use 'palette';
@use 'base/text' as text;
@import '../global/base/mixin-flex.scss';
@import '../global/base/bold.scss';

$vendor-images-path: '/images/';

.toast,
.toast-success,
.toast-error,
.toast-info,
.toast-warning {
    background-color: white;
}

.toast {
    .toast-close-button {
        color: #030303;
    }
    .toast-title {
        color: #030303;
    }
    .toast-message {
        color: #030303;
    }
    &:before {
        position: absolute;
        left: 16px;
        color: #030303;
        font-size: 20px;
        font-family: datagalaxy;
        content: $glyph-jirabug;
    }
}
.toast-success {
    .toast-title,
    .toast-message,
    .toast-close-button {
        color: palette.$green-50;
    }
    &:before {
        position: absolute;
        left: 16px;
        color: palette.$green-50;
        font-size: 20px;
        font-family: datagalaxy;
        content: $glyph-check;
    }
}
.toast-error {
    .toast-close-button {
        color: palette.$magenta-50;
    }
    .toast-title {
        color: palette.$magenta-50;
    }
    .toast-message {
        color: palette.$magenta-50;
    }
    &:before {
        position: absolute;
        left: 16px;
        color: palette.$magenta-50;
        font-size: 20px;
        font-family: datagalaxy;
        content: $glyph-close-circle;
    }
}
.toast-info {
    .toast-close-button {
        color: palette.$blue-50;
    }
    .toast-title {
        color: palette.$blue-50;
    }
    .toast-message {
        color: palette.$blue-50;
    }
    &:before {
        position: absolute;
        left: 16px;
        color: palette.$blue-50;
        font-size: 20px;
        font-family: datagalaxy;
        content: $glyph-info;
    }
}
.toast-warning {
    .toast-close-button {
        color: #f89406;
    }
    .toast-title {
        color: #f89406;
    }
    .toast-message {
        color: #f89406;
    }
    &:before {
        position: absolute;
        left: 16px;
        color: #f89406;
        font-size: 20px;
        font-family: datagalaxy;
        content: $glyph-warning;
    }
}

// #Archi-style Class name is too generic for a global style
.box {
    margin: 10px 10px 20px 10px;
    padding: 1em 1.5em;
    box-shadow: var(--color-shadow-any-light);

    &:first-child {
        margin-top: 20px;
    }

    &:hover {
        box-shadow: var(--color-shadow-any-light);
    }
}

// .box-actions {
//     visibility: hidden;
//     float: right;
//     color: @dat-primary;
//     .box-title:hover & {
//         visibility: visible;
//     }
// }

.form-inline .form-control {
    width: 100%;
}

.group {
    background: inherit;
    min-width: 420px;
    &.group-1 {
        @include flexItem(1, 1, 10%);
    }

    &.group-2 {
        @include flexItem(1, 1, 20%);
    }

    &.group-3 {
        @include flexItem(1, 1, 30%);
    }

    &.group-4 {
        @include flexItem(1, 1, 40%);
    }

    &.group-5 {
        @include flexItem(1, 1, 50%);
    }

    &.group-6 {
        @include flexItem(1, 1, 60%);
    }

    &.group-7 {
        @include flexItem(1, 1, 70%);
    }

    &.group-8 {
        @include flexItem(1, 1, 80%);
    }

    &.group-9 {
        @include flexItem(1, 1, 90%);
    }

    &.group-10 {
        @include flexItem(1, 1, 100%);
    }

    &.group-0-w50 {
        @include flexItem(0, 1, 50%);
    }
}

.group--main {
    &:first-child {
        > .box {
            margin-left: 20px;
        }

        > .group-container {
            margin-left: 10px;
        }
    }

    &:last-child {
        > .box {
            margin-right: 20px;
        }

        > .group-container {
            margin-right: 10px;
        }
    }
}

// #Archi-style Class name is too generic for a global style
.box-title {
    color: palette.$blue-50;
    @include txt-light();
    font-size: text.$title-text-size;
    //margin-bottom: 20px;
    background: inherit;
}

.group-container {
    background: inherit;
    @include flexH();
    @include flexItem(1);

    .box + & {
        margin-top: -10px;
    }
}

.group-container--main {
    padding-top: 10px;
}

.group-container--fullpage {
    margin: 20px;
}

.modeler-dictionary-item-div {
    display: flex;
}

.modeler-dictionary-count {
    min-width: 30px;
    text-align: right;
}

.modeler-dictionary-add-button {
    margin-left: auto;
}

.ui-widget-content .ui-icon {
    background-image: url('#{$vendor-images-path}/ui-icons_444444_256x240.png');
}
.ui-widget-header .ui-icon {
    background-image: url('#{$vendor-images-path}/ui-icons_444444_256x240.png');
}
.group-container--scroll {
    overflow-y: auto;
}

.widget-color-danger {
    background-color: palette.$magenta-50;
    &.fill {
        fill: palette.$magenta-50;
    }
    &.stroke {
        stroke: palette.$magenta-50;
    }
}
.widget-color-warning {
    background-color: #ff7b00;
    &.fill {
        fill: #ff7b00;
    }
    &.stroke {
        stroke: #ff7b00;
    }
}
.widget-color-safe {
    background-color: palette.$green-50;
    &.fill {
        fill: palette.$green-50;
    }
    &.stroke {
        stroke: palette.$green-50;
    }
}
.widget-color-unset {
    background-color: palette.$gray-60;
    &.fill {
        fill: palette.$gray-60;
    }
    &.stroke {
        stroke: palette.$gray-60;
    }
}

.widget-wrapper {
    box-shadow: var(--color-shadow-any-light);
    border-radius: 3px;
    padding: 20px;
    width: fit-content;
}
