@use 'palette';

// Chrome scrollbar customization
::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0;
}

::-webkit-scrollbar-thumb {
    background-image: linear-gradient(
        100deg,
        #d9dfed 0%,
        #d9dfed 100%
    ) !important;
    border-radius: 7px;
    &:hover {
        background: palette.$reflex-50 !important;
    }
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

// Firefox & IE / edge customization is not supported by the navigators
