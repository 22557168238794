@forward './button-default-variable-values.scss';

.dxy-button {
    position: relative;
    --opacity: var(--buttons-opacity-default, 1);
    --border-width: 0;
    --color-hover: var(--color);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;

    gap: var(--gap);
    padding: var(--padding);

    border-radius: var(--border-radius);
    border: none;
    opacity: var(--opacity);
    background: var(--background-color);
    color: var(--color);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size);
    line-height: var(--line-height);
    box-sizing: border-box;

    &::before {
        content: '';
        border-radius: var(--border-radius);
        position: absolute;
        inset: 0px;
        pointer-events: none;
        border-style: solid;
        border-color: var(--border-color);
        border-width: var(--border-width);
    }

    &:hover {
        text-decoration: none !important;
    }

    &:not(:disabled):hover {
        color: var(--color-hover);
        background: var(--background-color-hover);
    }
    &:disabled {
        background: var(--background-color-disabled);
        --opacity: var(--buttons-opacity-disabled);
        cursor: default;
    }
    &:not(:disabled):active {
        background: var(--background-color-active);
    }
    &:visited {
        background: var(--background-color-visited);
    }

    i {
        font-size: var(--icon-size);
        align-self: center;
    }

    &.pulse:not(:hover) {
        animation: simple-pulse 2s backwards infinite;
        animation-timing-function: ease-out;
    }
}

@keyframes simple-pulse {
    0% {
        background: transparent;
    }
    20% {
        background: var(--color-background-button-pulse);
    }

    100% {
        background: transparent;
    }
}
