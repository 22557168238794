@use '../mixins';
@use '../base/buttons' as buttons;
@use 'base/text' as text;
@use 'glyphs';

.mat-select-panel,
.mat-autocomplete-panel {
    .mat-option {
        @include mixins.menu-item;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .mat-option.mat-selected:not(.mat-option-multiple),
    .mat-option .mat-option-ripple .mat-ripple-element {
        background-color: var(--color-background-any--active);
    }
}
.mat-select-panel {
    margin-top: 2.5em;
    &.larger {
        max-height: 350px;
    }
}

mat-select.mat-select {
    .mat-select-arrow-wrapper {
        transform: none !important;
        @include buttons.dg5-action-icon-ripple(20px);
    }
    .mat-select-arrow {
        border: none !important;
        width: fit-content;
        height: 100%;
        display: flex;
        align-items: center;
        &::before {
            content: glyphs.$glyph-arrow-drop-down;
            font-family: glyphs.$icomoon-font-family !important;
            font-size: text.$title-text-size;
            color: var(--color-foreground-any);
        }
    }
}
