mat-radio-group {
    width: 100%;
    display: flex;
}

mat-radio-button {
    &.mat-radio-disabled .mat-radio-label {
        cursor: not-allowed;
    }
    label {
        font-weight: 450;
    }

    &:not(:first-child) {
        margin-left: 20px;
    }
}
