dxy-modal-dialog {
    $font-weight-bold: 600;

    .dg_modalServerUrl {
        width: 600px;

        .dg_directUrl {
            white-space: pre-wrap;
            word-break: break-all;
            padding-right: 20px;
        }
    }

    .dg_userInput_numeric,
    .dg_userInput_spacename {
        margin-bottom: 20px;

        input {
            font-size: 20px !important;
            font-weight: $font-weight-bold;
            text-align: center;
        }
    }
}
