.gs-internal {
    position: relative;
    overflow: hidden;

    /**
     * Prevents pointer events on nodes & edges while dragging, panning, or resizing.
     * Those classes are appended when the action is in progress, not at the start of the action.
     * Otherwise, some actions could be blocked too soon, like the node/edge selection on click.
     */
    &.dragging,
    &.pan-zooming,
    &.resizing {
        .gs-node {
            pointer-events: none;
        }

        .gs-connector {
            .gs-connector-path {
                path {
                    &.stroke,
                    &.outline {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .gs-zoomed-view {
        position: absolute;
    }
}
