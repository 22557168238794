:root {
    --color-gray-050: #f8f8f9;
    --color-gray-100: #f0f1f3;
    --color-gray-200: #e1e1e6;
    --color-gray-300: #d0d1d9;
    --color-gray-400: #bebfca;
    --color-gray-700: #848491;
    --color-gray-600: #9899a7;
    --color-gray-500: #aaabbb;
    --color-gray-900: #0c1233;
    --color-gray-800: #6c6c76;

    --color-reflex-050: #f3f4ff;
    --color-reflex-100: #e6e8ff;
    --color-reflex-200: #c9ceff;
    --color-reflex-300: #a8b0fe;
    --color-reflex-400: #7e8dfe;
    --color-reflex-500: #3d5cfe;
    --color-reflex-600: #3752e3;
    --color-reflex-700: #2f47c5;
    --color-reflex-800: #273aa1;
    --color-reflex-900: #1b2972;

    --color-blue-050: #f2f8fc;
    --color-blue-100: #e5f0f9;
    --color-blue-200: #c7e1f4;
    --color-blue-300: #a4d0ee;
    --color-blue-400: #78bee8;
    --color-blue-500: #28aae2;
    --color-blue-600: #2498ca;
    --color-blue-700: #1f84af;
    --color-blue-800: #196c8f;
    --color-blue-900: #124c65;

    --color-turquoise-050: #f2fbf9;
    --color-turquoise-100: #e4f7f2;
    --color-turquoise-200: #c6efe5;
    --color-turquoise-300: #a2e6d7;
    --color-turquoise-400: #73ddc7;
    --color-turquoise-500: #11d4b7;
    --color-turquoise-600: #0fbea4;
    --color-turquoise-700: #0da48e;
    --color-turquoise-800: #0b8674;
    --color-turquoise-900: #085f52;

    --color-grass-050: #f2f6f3;
    --color-grass-100: #e4ece7;
    --color-grass-200: #c6d7cb;
    --color-grass-300: #a2c1ab;
    --color-grass-400: #73a784;
    --color-grass-500: #12884b;
    --color-grass-600: #107a43;
    --color-grass-700: #0e693a;
    --color-grass-800: #0b562f;
    --color-grass-900: #083d22;

    --color-purple-050: #f8f2f9;
    --color-purple-100: #f1e4f4;
    --color-purple-200: #e2c6e8;
    --color-purple-300: #d2a1db;
    --color-purple-400: #c072cd;
    --color-purple-500: #ad00bf;
    --color-purple-600: #9b00ab;
    --color-purple-700: #860094;
    --color-purple-800: #6d0079;
    --color-purple-900: #4d0055;

    --color-violet-050: #f4f2f8;
    --color-violet-100: #e8e5f2;
    --color-violet-200: #cec7e3;
    --color-violet-300: #b0a4d4;
    --color-violet-400: #8c76c4;
    --color-violet-500: #5b24b2;
    --color-violet-600: #51209f;
    --color-violet-700: #461c8a;
    --color-violet-800: #3a1771;
    --color-violet-900: #291050;

    --color-magenta-050: #faf2f3;
    --color-magenta-100: #f5e4e6;
    --color-magenta-200: #ebc6cb;
    --color-magenta-300: #e1a1ab;
    --color-magenta-400: #d67284;
    --color-magenta-500: #ca004a;
    --color-magenta-600: #b50042;
    --color-magenta-700: #9c0039;
    --color-magenta-800: #80002f;
    --color-magenta-900: #5a0021;

    --color-yellow-050: #fff9f2;
    --color-yellow-100: #fff4e4;
    --color-yellow-200: #ffe8c6;
    --color-yellow-300: #ffdba1;
    --color-yellow-400: #ffcd72;
    --color-yellow-500: #ffbf00;
    --color-yellow-600: #e4ab00;
    --color-yellow-700: #c69400;
    --color-yellow-800: #a17900;
    --color-yellow-900: #332600;

    --color-orange-050: #fff5f2;
    --color-orange-100: #ffebe4;
    --color-orange-200: #ffd6c6;
    --color-orange-300: #ffbea3;
    --color-orange-400: #ffa274;
    --color-orange-500: #ff811a;
    --color-orange-600: #e47317;
    --color-orange-700: #c66414;
    --color-orange-800: #a15210;
    --color-orange-900: #331a05;

    --color-lime-100: #f5f9e4;
    --color-lime-200: #ebf4c6;
    --color-lime-300: #e0eea1;
    --color-lime-400: #d4e872;
    --color-lime-500: #c8e200;
    --color-lime-050: #fafcf2;
    --color-lime-600: #b3ca00;
    --color-lime-700: #9baf00;
    --color-lime-800: #7e8f00;
    --color-lime-900: #282d00;

    --color-green-050: #f3faf2;
    --color-green-100: #e7f5e4;
    --color-green-200: #cceac6;
    --color-green-300: #addea2;
    --color-green-400: #87d274;
    --color-green-500: #50c516;
    --color-green-600: #48b014;
    --color-green-700: #3e9911;
    --color-green-800: #337d0e;
    --color-green-900: #102704;

    --color-transparent: rgba(255 255 255 / 0);
    --color-opacity-8: rgba(1 30 183 / 0.08);

    --pixels-none: 0px;
    --pixels-none-rem: 0rem;
    --pixels-01: 1px;
    --pixels-01-rem: 0.0625rem;
    --pixels-02: 2px;
    --pixels-02-rem: 0.125rem;
    --pixels-04: 4px;
    --pixels-04-rem: 0.25rem;
    --pixels-06: 6px;
    --pixels-06-rem: 0.375rem;
    --pixels-08: 8px;
    --pixels-08-rem: 0.5rem;
    --pixels-12: 12px;
    --pixels-12-rem: 0.75rem;
    --pixels-16: 16px;
    --pixels-16-rem: 1rem;
    --pixels-24: 24px;
    --pixels-24-rem: 1.5rem;
    --pixels-32: 32px;
    --pixels-32-rem: 2rem;
    --pixels-48: 48px;
    --pixels-48-rem: 3rem;
    --pixels-64: 64px;
    --pixels-64-rem: 4rem;
    --pixels-round: 999px;
    --pixels-round-rem: 62.4375rem;

    --typography-fontface-default: 'Montserrat';
    --typography-fontweight-default: 'Medium';

    --typography-lineheight-s: 12px;
    --typography-lineheight-s-rem: 0.75rem;
    --typography-lineheight-m: 16px;
    --typography-lineheight-m-rem: 1rem;
    --typography-lineheight-l: 20px;
    --typography-lineheight-l-rem: 1.25rem;
    --typography-fontsize-xxs: 9px;
    --typography-fontsize-xs: 11px;
    --typography-fontsize-xs-rem: 0.6875rem;
    --typography-fontsize-s: 13px;
    --typography-fontsize-s-rem: 0.8125rem;
    --typography-fontsize-m: 15px;
    --typography-fontsize-m-rem: 0.9375rem;
    --typography-fontsize-l: 18px;
    --typography-fontsize-l-rem: 1.125rem;
    --typography-fontsize-xl: 22px;
    --typography-fontsize-xl-rem: 1.375rem;
    --typography-fontsize-xxl: 30px;
    --typography-fontsize-xxl-rem: 1.875rem;
}
