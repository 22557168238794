@use 'mixins' as *;

app-dp-item-element {
    display: flex;
    padding: 0 2px 0 8px !important;
    overflow: visible !important;
    box-shadow:
        0 0 2px var(--color-shadow-any),
        0 2px 4px var(--color-shadow-any);

    .wrapper {
        flex-grow: 1;
        display: flex;
    }

    .container-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        white-space: normal;
        overflow: visible;
        align-items: center;
    }
    .item-mini-obj {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .glyph {
            margin-right: 2px;
        }
        .item-name {
            display: flex;
            flex-grow: 1;
            padding-left: 4px;
            width: 100%;
            @include ellipsis;
        }
        .dg_dataProcessingItem-label {
            width: 0;
            display: inline-block;
            flex-grow: 1;
        }
    }
    .menu-trigger {
        z-index: 1049;
    }
}
