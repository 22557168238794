@use 'palette';
@use 'base/text' as text;

.dg5-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17px;
    min-width: 21px;
    padding: 2px 5px;
    border-radius: 3px;
    background-color: var(--color-background-any--active);
    color: var(--color-text-any-primary);
    font-size: text.$tiny-text-size;
    font-weight: bold;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;

    &.disabled {
        color: var(--color-foreground-any-tertiary);
        font-weight: normal;
    }

    &.big {
        font-size: 11px;
        height: 19px;
    }
    &.warning {
        background-color: var(--color-background-any-warning);
        color: var(--color-foreground-any-warning);
    }
    &.success {
        background-color: var(--color-background-any-success);
        color: var(--color-foreground-any-success);
    }
}

/**
 * Users kit : https://datagalaxy-uikit-v1.webflow.io/ui-kit#users
 */
.dg5-notif-badge {
    position: absolute;
    top: 0;
    right: 0;
    min-height: 14px;
    min-width: 14px;
    padding: 0 2px;
    border-radius: 7px;
    background-color: palette.$magenta-50;
    color: white;
    font-size: text.$small-text-size;
    line-height: 14px;
    font-weight: 700;
    text-align: center;
}

.dg5-grid-badge {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: text.$tiny-text-size;
    color: var(--color-foreground-any-secondary);
    background-color: var(--color-background-any--active);
    padding: 0 5px;
    border-radius: 3px;

    &:hover {
        color: var(--color-text-any-primary);
    }
}

.dg5-beta-badge:after {
    content: 'beta';
    font-family: text.$font-family;
    font-size: text.$tiny-text-size;
    line-height: 1.35;
    font-weight: 700;
    background-color: palette.$violet-50;
    color: #fff;
    padding: 0.175em 0.35em;
    border-radius: 0.625em;
    position: absolute;
}
