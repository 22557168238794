.dg5-spinner {
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    flex: 0 0 auto;

    &::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid var(--color-background-any--active);
        border-top-color: var(--color-text-any-primary);
        animation: spinner 0.6s linear infinite;
    }

    &.xl-spinner {
        width: 40px;
        height: 40px;
        &::before {
            width: 40px;
            height: 40px;
            margin-top: -20px;
            margin-left: -20px;
        }

        &.absolute-centered {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.xs-spinner {
        width: 16px;
        height: 16px;
        &::before {
            width: 16px;
            height: 16px;
            margin-top: -8px;
            margin-left: -8px;
        }
    }
}

// Useful for centered spinners and spinners with text
.dg5-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

    .dg5-spinner {
        margin-bottom: 10px;
        margin-left: 0;
    }
}

.dg5-primary-btn .dg5-spinner::before {
    border-color: hsla(0, 0%, 100%, 0.2);
    border-top-color: white;
}

dxy-omni-grid {
    &.dg5-spinner {
        margin: auto;
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

/* Animation */
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
