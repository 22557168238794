﻿@use 'palette';

app-impact-analysis-main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background-any-secondary);
    overflow: auto;

    //#region spotlight search field
    /* by default hidden, to prevent flickering when moved to top toolbar */
    dxy-entity-selector {
        display: none;
    }
    .more-top-controls dxy-entity-selector {
        min-width: 300px;
    }
    //#endregion
}
