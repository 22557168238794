@use 'base/text' as text;
@use 'palette';

dxy-space-version-selector {
    // (uikit: dashboard-filter-wrapper)
    flex: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    background-color: var(--color-background-any);
    box-shadow: none;

    &.no-shadow {
        box-shadow: none;
    }
    &.no-padding {
        padding: 0;
    }

    .space-selector,
    .version-selector {
        // (uikit: dashboard-filter)
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: flex-start;
        border-bottom: 1px none palette.$reflex-08;
        cursor: pointer;
        margin-right: 5px;
    }
    .separator {
        height: 30px;
        margin-right: 10px;
        border-right: 1px solid palette.$reflex-08;
    }

    .space-label {
        color: rgba(16, 53, 177, 0.9);
        font-size: text.$tiny-text-size;
        line-height: 10px;
        font-weight: 700;
    }

    .all-spaces-version-text {
        font-size: text.$normal-text-size;
        margin-right: 10px;
    }

    .space-selector.has-label {
        .workspace-icon {
            width: 22px;
            height: 22px;
            font-size: text.$tiny-text-size;
            margin-right: 5px;
        }
    }
}
