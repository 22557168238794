@mixin ellipsis() {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin multiline-ellipsis($line) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
