$icomoon-font-family: 'datagalaxy' !default;
$icomoon-font-path: '/fonts/datagalaxy' !default;

$glyph-logo-dg: unquote('"\\e9ce"');
$glyph-home: unquote('"\\e9ae"');
$glyph-search: unquote('"\\ea16"');
$glyph-object-preview: unquote('"\\e9e7"');
$glyph-delete: unquote('"\\e94f"');
$glyph-jirabug: unquote('"\\e9ba"');
$glyph-insight: unquote('"\\e9b7"');
$glyph-bulb: unquote('"\\e9b7"');
$glyph-email: unquote('"\\e967"');
$glyph-extension: unquote('"\\e90b"');
$glyph-plugins: unquote('"\\e91a"');
$glyph-file-copy: unquote('"\\e985"');
$glyph-edit: unquote('"\\e966"');
$glyph-mass-edit: unquote('"\\e9d3"');
$glyph-edit-screen: unquote('"\\e940"');
$glyph-screen: unquote('"\\e940"');
$glyph-features: unquote('"\\e96f"');
$glyph-layout-split: unquote('"\\e9be"');
$glyph-object-page: unquote('"\\e9e6"');
$glyph-compare-item: unquote('"\\e93f"');
$glyph-desktop: unquote('"\\e956"');
$glyph-online: unquote('"\\e9ea"');
$glyph-keyboard: unquote('"\\e9bc"');
$glyph-hyperlink: unquote('"\\e9af"');
$glyph-shortcut: unquote('"\\e9af"');
$glyph-send: unquote('"\\ea18"');
$glyph-share: unquote('"\\ea1e"');
$glyph-mapping: unquote('"\\e9d1"');
$glyph-notification: unquote('"\\e9e4"');
$glyph-no-notification: unquote('"\\e9df"');
$glyph-like-up: unquote('"\\e9c1"');
$glyph-like-down: unquote('"\\e9c0"');
$glyph-upload: unquote('"\\e964"');
$glyph-import: unquote('"\\e964"');
$glyph-download: unquote('"\\e994"');
$glyph-export: unquote('"\\e994"');
$glyph-live: unquote('"\\e9ca"');
$glyph-dashboard: unquote('"\\e9a5"');
$glyph-quality: unquote('"\\ea02"');
$glyph-campaign: unquote('"\\e91c"');
$glyph-content-view-module-2: unquote('"\\e946"');
$glyph-splitter: unquote('"\\ea2b"');
$glyph-more: unquote('"\\e9db"');
$glyph-drag: unquote('"\\e965"');
$glyph-dots-nine: unquote('"\\e945"');
$glyph-bulb1: unquote('"\\e91b"');
$glyph-marker: unquote('"\\e9d2"');
$glyph-pending: unquote('"\\e9ef"');
$glyph-save: unquote('"\\ea13"');
$glyph-save1: unquote('"\\ea13"');
$glyph-settings: unquote('"\\ea1c"');
$glyph-slider-2: unquote('"\\ea23"');
$glyph-settings1: unquote('"\\ea23"');
$glyph-ai: unquote('"\\e903"');
$glyph-robot: unquote('"\\e903"');
$glyph-robot2: unquote('"\\e903"');
$glyph-robot2-off: unquote('"\\e903"');
$glyph-link-obj-ai: unquote('"\\e9c7"');
$glyph-metabot-links: unquote('"\\e9c7"');
$glyph-text-ai: unquote('"\\e986"');
$glyph-language-ai: unquote('"\\e9bd"');
$glyph-redo-ai: unquote('"\\ea04"');
$glyph-glossary-ai: unquote('"\\e987"');
$glyph-metabot-glossary: unquote('"\\e96b"');
$glyph-profile: unquote('"\\e9ff"');
$glyph-person: unquote('"\\e9f0"');
$glyph-invite-user: unquote('"\\e9b9"');
$glyph-user-unlock: unquote('"\\ea4c"');
$glyph-team: unquote('"\\ea35"');
$glyph-team-share: unquote('"\\ea35"');
$glyph-tools-publish: unquote('"\\ea35"');
$glyph-personal-doc: unquote('"\\e9f1"');
$glyph-admin: unquote('"\\e902"');
$glyph-team-owner: unquote('"\\ea34"');
$glyph-sort: unquote('"\\ea28"');
$glyph-assets: unquote('"\\ea28"');
$glyph-hierarchy: unquote('"\\e955"');
$glyph-descendants: unquote('"\\e955"');
$glyph-view-flat: unquote('"\\ea4e"');
$glyph-view-tree: unquote('"\\ea52"');
$glyph-view-list: unquote('"\\ea4f"');
$glyph-view-list1: unquote('"\\ea50"');
$glyph-collection-static: unquote('"\\ea50"');
$glyph-valuelist: unquote('"\\ea4b"');
$glyph-move-child: unquote('"\\e9dc"');
$glyph-unsorted: unquote('"\\ea45"');
$glyph-asc-sort: unquote('"\\e919"');
$glyph-desc-sort: unquote('"\\e954"');
$glyph-grid-columns: unquote('"\\e9a1"');
$glyph-arrow-drop-up: unquote('"\\e914"');
$glyph-arrow-drop-down: unquote('"\\e912"');
$glyph-collapse: unquote('"\\e934"');
$glyph-uncollapse: unquote('"\\ea3d"');
$glyph-arrow-drop-right: unquote('"\\e913"');
$glyph-order-by: unquote('"\\e9ec"');
$glyph-arrow-down: unquote('"\\e911"');
$glyph-arrow-up: unquote('"\\e918"');
$glyph-arrow-left: unquote('"\\e915"');
$glyph-arrow-right: unquote('"\\e916"');
$glyph-arrow-down-right: unquote('"\\e910"');
$glyph-arrow-up-left: unquote('"\\e917"');
$glyph-expand-horizontal: unquote('"\\e96d"');
$glyph-expand-vertical: unquote('"\\e96e"');
$glyph-fullscreen-expand: unquote('"\\e98f"');
$glyph-fullscreen-contract: unquote('"\\e98e"');
$glyph-expand-all: unquote('"\\e995"');
$glyph-glyph_dgg2: unquote('"\\e995"');
$glyph-shrink-all: unquote('"\\e996"');
$glyph-glyph_ddg2: unquote('"\\e996"');
$glyph-shrink-vertical: unquote('"\\ea22"');
$glyph-filter-empty: unquote('"\\e97d"');
$glyph-filter-filled: unquote('"\\e97e"');
$glyph-chat-comment: unquote('"\\e926"');
$glyph-comment1: unquote('"\\e93e"');
$glyph-comment-add: unquote('"\\e939"');
$glyph-comment-subtract: unquote('"\\e93c"');
$glyph-comment-duo: unquote('"\\e93a"');
$glyph-comment: unquote('"\\e93b"');
$glyph-comment-edit: unquote('"\\e93b"');
$glyph-chat-question: unquote('"\\e927"');
$glyph-file-tasks-add: unquote('"\\e972"');
$glyph-file-tasks-alert: unquote('"\\e973"');
$glyph-file-tasks-block: unquote('"\\e974"');
$glyph-file-tasks-close: unquote('"\\e974"');
$glyph-file-tasks-check: unquote('"\\e975"');
$glyph-file-tasks-edit: unquote('"\\e977"');
$glyph-file-tasks-subtract: unquote('"\\e978"');
$glyph-file-tasks-view: unquote('"\\e979"');
$glyph-clock-filled: unquote('"\\e9b1"');
$glyph-invalidation: unquote('"\\e9b1"');
$glyph-in-validation: unquote('"\\e9b1"');
$glyph-redo-filled: unquote('"\\e9b0"');
$glyph-inrevision: unquote('"\\e9b0"');
$glyph-in-revision: unquote('"\\e9b0"');
$glyph-question-filled: unquote('"\\ea01"');
$glyph-proposed: unquote('"\\ea01"');
$glyph-alert-warning: unquote('"\\e906"');
$glyph-alert-critical: unquote('"\\e904"');
$glyph-close-circle-filled: unquote('"\\e9e9"');
$glyph-obsolete: unquote('"\\e9e9"');
$glyph-check-circle-filled: unquote('"\\ea49"');
$glyph-validated: unquote('"\\ea49"');
$glyph-info-circle-filled: unquote('"\\e9b5"');
$glyph-checkbox-checked-filled: unquote('"\\e92b"');
$glyph-checkbox-checked: unquote('"\\e92b"');
$glyph-checked3: unquote('"\\e92b"');
$glyph-checkbox-indeterminate-filled: unquote('"\\e9b2"');
$glyph-checkbox-indeterminate: unquote('"\\e9b2"');
$glyph-circle-filled: unquote('"\\e905"');
$glyph-color: unquote('"\\e905"');
$glyph-alert-unknown: unquote('"\\e905"');
$glyph-radiobutton-selected: unquote('"\\e936"');
$glyph-radiobutton-indeterminate: unquote('"\\e992"');
$glyph-star-fav: unquote('"\\ea2c"');
$glyph-star: unquote('"\\ea2d"');
$glyph-checkbox-unchecked: unquote('"\\e92c"');
$glyph-checkbox-checked-stroke: unquote('"\\e92d"');
$glyph-checkbox: unquote('"\\e92d"');
$glyph-checked2: unquote('"\\e92d"');
$glyph-radiobutton-unselected: unquote('"\\e993"');
$glyph-circle: unquote('"\\e993"');
$glyph-color-white: unquote('"\\e993"');
$glyph-circle-dashed: unquote('"\\e937"');
$glyph-color-multi: unquote('"\\e937"');
$glyph-add: unquote('"\\e901"');
$glyph-minus: unquote('"\\e9d9"');
$glyph-check: unquote('"\\e92a"');
$glyph-cancelsearch: unquote('"\\e91d"');
$glyph-plus-circle: unquote('"\\e9f5"');
$glyph-glyph_dggg: unquote('"\\e9f5"');
$glyph-plus-circle-small: unquote('"\\e9f4"');
$glyph-info: unquote('"\\e9b6"');
$glyph-minus-circle-small: unquote('"\\e9d7"');
$glyph-minus-circle: unquote('"\\e9d8"');
$glyph-close-circle: unquote('"\\e933"');
$glyph-close-circle-small: unquote('"\\e933"');
$glyph-check-circle-1: unquote('"\\e928"');
$glyph-mandatory: unquote('"\\e9d0"');
$glyph-clock: unquote('"\\e930"');
$glyph-help: unquote('"\\e9ac"');
$glyph-none: unquote('"\\e9e0"');
$glyph-logout: unquote('"\\e9cf"');
$glyph-spinner: unquote('"\\ea2a"');
$glyph-recent: unquote('"\\ea03"');
$glyph-activity-log: unquote('"\\e900"');
$glyph-reset: unquote('"\\ea09"');
$glyph-redo2: unquote('"\\ea05"');
$glyph-reload: unquote('"\\ea08"');
$glyph-sync: unquote('"\\ea08"');
$glyph-inverse: unquote('"\\e9b8"');
$glyph-undo: unquote('"\\ea3e"');
$glyph-undo2: unquote('"\\ea3e"');
$glyph-warning: unquote('"\\ea55"');
$glyph-lock: unquote('"\\e9cc"');
$glyph-lock2: unquote('"\\e9cc"');
$glyph-unlock: unquote('"\\ea42"');
$glyph-unlock2: unquote('"\\ea42"');
$glyph-watch-on: unquote('"\\ea56"');
$glyph-show: unquote('"\\ea20"');
$glyph-hide: unquote('"\\e9ad"');
$glyph-view-more: unquote('"\\ea51"');
$glyph-unlink: unquote('"\\ea41"');
$glyph-link-broken: unquote('"\\ea41"');
$glyph-link: unquote('"\\e9c8"');
$glyph-link1: unquote('"\\e9c8"');
$glyph-date: unquote('"\\e94d"');
$glyph-copy-compared-field: unquote('"\\e947"');
$glyph-project: unquote('"\\ea00"');
$glyph-organization: unquote('"\\e9ed"');
$glyph-datamap: unquote('"\\e94a"');
$glyph-visual-analysis: unquote('"\\ea54"');
$glyph-analysis: unquote('"\\e90e"');
$glyph-diagram1: unquote('"\\e95b"');
$glyph-lineage-explore: unquote('"\\e9c2"');
$glyph-caret-double-left: unquote('"\\e920"');
$glyph-caret-double-right: unquote('"\\e921"');
$glyph-caret-left: unquote('"\\e922"');
$glyph-caret-right: unquote('"\\e923"');
$glyph-density-high: unquote('"\\e950"');
$glyph-density-medium-1: unquote('"\\e951"');
$glyph-density-medium: unquote('"\\e952"');
$glyph-density: unquote('"\\e953"');
$glyph-text1: unquote('"\\ea3a"');
$glyph-relation: unquote('"\\ea07"');
$glyph-unrelation: unquote('"\\ea44"');
$glyph-fk: unquote('"\\e97f"');
$glyph-pk: unquote('"\\e9f3"');
$glyph-diag-arrow-01: unquote('"\\e957"');
$glyph-diag-arrow-02: unquote('"\\e958"');
$glyph-diag-arrow-03: unquote('"\\e959"');
$glyph-diag-arrow-04: unquote('"\\e95a"');
$glyph-direction-after: unquote('"\\e95e"');
$glyph-direction-before: unquote('"\\e95f"');
$glyph-direction-both: unquote('"\\e960"');
$glyph-flip-horizontal: unquote('"\\e980"');
$glyph-diagram: unquote('"\\e988"');
$glyph-frame: unquote('"\\e98c"');
$glyph-golden-link-off: unquote('"\\e99c"');
$glyph-golden-link: unquote('"\\e99d"');
$glyph-golden-off: unquote('"\\e99e"');
$glyph-golden: unquote('"\\e99f"');
$glyph-fusion: unquote('"\\e990"');
$glyph-grid: unquote('"\\e9a4"');
$glyph-grid-magnetism-off: unquote('"\\e9a2"');
$glyph-grid-magnetism-on: unquote('"\\e9a3"');
$glyph-group: unquote('"\\e9a6"');
$glyph-hand-peace: unquote('"\\e9a7"');
$glyph-hand: unquote('"\\e9a8"');
$glyph-selection: unquote('"\\ea17"');
$glyph-handle-simple: unquote('"\\e9ab"');
$glyph-handle-right: unquote('"\\e9aa"');
$glyph-handle-left: unquote('"\\e9a9"');
$glyph-maxi-obj: unquote('"\\e9d4"');
$glyph-medium-obj: unquote('"\\e9d5"');
$glyph-mini-obj: unquote('"\\e9d6"');
$glyph-nano-box: unquote('"\\e9de"');
$glyph-more-obj: unquote('"\\e9da"');
$glyph-light-links: unquote('"\\e9bf"');
$glyph-full-links: unquote('"\\e98d"');
$glyph-lineage-group: unquote('"\\e9c3"');
$glyph-lineage-ungroup: unquote('"\\e9c5"');
$glyph-lineage-static: unquote('"\\e9c4"');
$glyph-caption-outside: unquote('"\\e91f"');
$glyph-caption-inside: unquote('"\\e91e"');
$glyph-photo: unquote('"\\e9f2"');
$glyph-eraser: unquote('"\\ea38"');
$glyph-location-map: unquote('"\\e9cb"');
$glyph-note: unquote('"\\e9e3"');
$glyph-postit: unquote('"\\e9e3"');
$glyph-txt-style: unquote('"\\e929"');
$glyph-txt-title: unquote('"\\e92e"');
$glyph-txt-size: unquote('"\\e92f"');
$glyph-txt-quote: unquote('"\\e931"');
$glyph-txt-italic: unquote('"\\e932"');
$glyph-txt-bold: unquote('"\\e935"');
$glyph-txt-underline: unquote('"\\e938"');
$glyph-txt-strike: unquote('"\\e93d"');
$glyph-txt-code: unquote('"\\e941"');
$glyph-txt-bullets: unquote('"\\e961"');
$glyph-txt-num: unquote('"\\e962"');
$glyph-txt-super: unquote('"\\e96a"');
$glyph-txt-infra: unquote('"\\e96c"');
$glyph-txt-align: unquote('"\\e976"');
$glyph-align-left: unquote('"\\e976"');
$glyph-align-right: unquote('"\\e908"');
$glyph-align-center: unquote('"\\e90a"');
$glyph-align-top: unquote('"\\e90d"');
$glyph-align-middle: unquote('"\\e90c"');
$glyph-align-bottom: unquote('"\\e909"');
$glyph-object: unquote('"\\e9e8"');
$glyph-zoom-reset: unquote('"\\ea57"');
$glyph-software: unquote('"\\ea27"');
$glyph-software-dashboard: unquote('"\\ea25"');
$glyph-process: unquote('"\\e9fd"');
$glyph-dataset: unquote('"\\e94c"');
$glyph-opendataset: unquote('"\\e94c"');
$glyph-software-application: unquote('"\\ea24"');
$glyph-software-screen: unquote('"\\ea26"');
$glyph-report: unquote('"\\e984"');
$glyph-algorithm: unquote('"\\e907"');
$glyph-usage-component: unquote('"\\ea47"');
$glyph-usage-field: unquote('"\\ea48"');
$glyph-glossary: unquote('"\\e991"');
$glyph-glossary2: unquote('"\\e991"');
$glyph-term: unquote('"\\ea37"');
$glyph-indicator-group: unquote('"\\e9b3"');
$glyph-indicator: unquote('"\\e9b4"');
$glyph-universe: unquote('"\\ea40"');
$glyph-dimension-group: unquote('"\\e95c"');
$glyph-concept: unquote('"\\e942"');
$glyph-value: unquote('"\\ea4a"');
$glyph-sub-dimension: unquote('"\\ea2e"');
$glyph-dimension: unquote('"\\e95d"');
$glyph-reference-data: unquote('"\\ea06"');
$glyph-rule-governance: unquote('"\\ea0c"');
$glyph-rule-lifecycle: unquote('"\\ea0d"');
$glyph-catalog: unquote('"\\e924"');
$glyph-source: unquote('"\\ea29"');
$glyph-model: unquote('"\\e969"');
$glyph-table: unquote('"\\e98a"');
$glyph-view: unquote('"\\ea53"');
$glyph-sub-structure: unquote('"\\ea30"');
$glyph-columnview: unquote('"\\e981"');
$glyph-datafield: unquote('"\\e982"');
$glyph-filestore: unquote('"\\e97c"');
$glyph-nosql: unquote('"\\e9e1"');
$glyph-tagbase: unquote('"\\ea33"');
$glyph-equipment: unquote('"\\e968"');
$glyph-tag: unquote('"\\e98b"');
$glyph-database: unquote('"\\e949"');
$glyph-container: unquote('"\\e943"');
$glyph-containerfolder: unquote('"\\e944"');
$glyph-document: unquote('"\\e989"');
$glyph-file1: unquote('"\\e97b"');
$glyph-governance: unquote('"\\e9a0"');
$glyph-sub-domain: unquote('"\\ea2f"');
$glyph-domain: unquote('"\\ea2f"');
$glyph-domain-group: unquote('"\\ea2f"');
$glyph-rule-quality: unquote('"\\ea0f"');
$glyph-policy: unquote('"\\ea0f"');
$glyph-rule-security: unquote('"\\ea10"');
$glyph-rule-ethics: unquote('"\\ea0b"');
$glyph-rule: unquote('"\\ea11"');
$glyph-rule-privacy: unquote('"\\ea0e"');
$glyph-rules-group: unquote('"\\ea12"');
$glyph-policy-group: unquote('"\\e9f7"');
$glyph-policy-lifecycle: unquote('"\\e9f8"');
$glyph-policy-masking: unquote('"\\e9f9"');
$glyph-policy-validation: unquote('"\\e9fa"');
$glyph-policy-external: unquote('"\\e9f6"');
$glyph-sensor-internal: unquote('"\\ea1b"');
$glyph-sensor-group: unquote('"\\ea1a"');
$glyph-sensor-external: unquote('"\\ea19"');
$glyph-process-old: unquote('"\\e9fc"');
$glyph-data-flow: unquote('"\\e948"');
$glyph-dataprocessing: unquote('"\\e94b"');
$glyph-processItem: unquote('"\\e9fe"');
$glyph-cdp: unquote('"\\e925"');
$glyph-domain1: unquote('"\\e963"');
$glyph-tag-hierarchy: unquote('"\\ea32"');
$glyph-multi-value: unquote('"\\e9dd"');
$glyph-api: unquote('"\\e90f"');
$glyph-richtext: unquote('"\\ea0a"');
$glyph-text: unquote('"\\ea39"');
$glyph-number: unquote('"\\e9e5"');
$glyph-time-series: unquote('"\\ea3b"');
$glyph-ddl: unquote('"\\e94e"');
$glyph-csv-file: unquote('"\\e983"');
$glyph-file-empty: unquote('"\\e971"');
$glyph-file: unquote('"\\e97a"');
$glyph-field: unquote('"\\e970"');
$glyph-json-file: unquote('"\\e9bb"');
