@use '../core-ui-variables.scss';
@use 'base/text' as text;

@mixin menu-panel {
    padding: 10px 0;
    font-size: text.$big-text-size;
    border-radius: 3px;
    background-color: var(--color-background-any);
    border: none;
    box-shadow:
        0 0 12px 0 var(--color-shadow-any),
        0 0 46px 0 var(--color-shadow-any) !important;
    max-height: core-ui-variables.$dropdown-max-height;
    overflow-y: auto;

    > ul {
        display: inline-block;
        position: relative;
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

// an item in a mat-menu or mat-select
@mixin menu-item($height: 40px, $selectedClassName: 'selected') {
    height: $height !important;
    flex-basis: $height;
    flex-shrink: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    color: var(--color-foreground-any);
    overflow: hidden;

    &:hover,
    &:focus:not(.cdk-mouse-focused),
    &.cdk-keyboard-focused {
        background-color: var(--color-background-any--hover) !important;
    }
    &.disabled,
    &.disabled:hover,
    &:disabled,
    &:disabled:hover {
        cursor: default;
        color: var(--color-foreground-any-secondary) !important;
        background-color: unset !important;
    }

    > [class^='glyph-'],
    > [class*=' glyph-'] {
        margin-right: 10px;
    }

    a,
    a:hover,
    a:focus {
        background: unset !important;
        color: unset;
        padding: 0;
    }

    &.mat-option-search {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: var(--color-background-any) !important;
        &:hover .mat-option-text {
            background-color: var(--color-background-any--hover);
        }
    }

    &.mat-active:not(.mat-option-search),
    &.#{$selectedClassName} {
        background-color: var(--color-background-any--active);

        &:hover {
            background-color: var(--color-background-any--hover);
        }
    }
}

@mixin menu-separator {
    height: 1px;
    margin: 10px 0;
    align-self: stretch;
    border-bottom: 1px solid var(--color-border-any);
}

@mixin menu-section {
    display: block;
    font-size: text.$small-text-size;
    color: var(--color-foreground-any-secondary);
    white-space: nowrap;
    min-height: 5px;
    margin-bottom: 0;
    align-self: stretch;
    line-height: 12px;
    padding: 5px 20px;
}
