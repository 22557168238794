@use 'base/text' as text;
@use 'palette';

/** Note: Parent element is supposed to have `align-items: center;` */
@mixin breadcrumb-separator($space-around: 5px, $color: palette.$gray-60) {
    display: flex;
    width: 1px;
    height: 10px;
    margin-right: $space-around;
    padding-left: $space-around;
    padding-top: 5px;
    align-items: center;
    border-right: 1px solid $color;
    font-size: text.$normal-text-size;
    line-height: 14px;
}
