@use 'base/text' as text;
@use 'palette';

// TODO vbo : this style should be implemented locally
.dg5-match-tag {
    margin: 5px 10px 5px 0;

    .match-tag-container {
        padding: 0 5px;
        height: 35px;
        margin: 0;
        max-width: 220px;
        @extend .dg5-filter-item;

        &:hover {
            background-color: var(--color-background-any--active);
        }

        &.to-match {
            border-style: dashed;
            border-color: var(--color-foreground-any-secondary);
            background-color: var(--color-shadow-any);

            &.mandatory {
                border-color: palette.$magenta-50;
                background-color: rgba(202, 0, 74, 0.1);

                .match-tag-label {
                    color: palette.$magenta-50;
                }

                .match-tag-icon {
                    color: palette.$magenta-50;
                    opacity: 0.2;
                    &:hover {
                        color: palette.$magenta-50;
                        opacity: 0.3;
                    }
                }
            }

            .match-tag-icon {
                opacity: 0.3;
            }

            .match-tag-text {
                color: rgba(202, 0, 74, 0.2);
            }
        }

        .match-tag-text {
            font-size: text.$normal-text-size;
            line-height: 14px;
            height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @extend .filter-value;
        }

        .match-tag-group {
            display: flex;
            flex-direction: column;
            max-width: 85%;
        }

        .match-tag-label {
            color: rgba(16, 53, 177, 0.9);
            font-size: text.$small-text-size;
            line-height: 12px;
            height: 14px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .match-tag-icon {
            margin-right: 10px;
            color: rgba(29, 43, 70, 0.8);
            font-size: 20px;

            &:hover {
                color: var(--color-text-any-primary);
            }
        }
    }

    .attribute-match-mandatory-text {
        line-height: 10px;
        font-size: text.$tiny-text-size;
        text-align: right;
        letter-spacing: 0;
        color: palette.$magenta-50;
        margin-top: 1px;
    }

    &[draggable='false'] .match-tag-container,
    &.disabled-tag .match-tag-container {
        cursor: default;
        &:not(.to-match):hover {
            background-color: inherit;
        }
    }
}
