.dxy-button-group {
    display: inline-flex;
    align-items: center;
    .dxy-button:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-style: solid;
        border-left-width: 1px;
        border-left-color: var(--color-neutral-lighter);
        &::before {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-width: 0;
        }
    }
    .dxy-button:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
        &::before {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 0;
        }
    }
    &.has-primary-buttons {
        .dxy-button:not(:first-child) {
            border-left-color: var(--color-neutral-negative-light);
        }
    }
}
