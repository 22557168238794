@mixin txt-light() {
    font-weight: 100;
}
@mixin txt-regular() {
    font-weight: 450;
}
@mixin txt-bold() {
    font-weight: 600;
}
@mixin txt-bolder() {
    font-weight: 800;
}
