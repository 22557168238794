@use 'palette';

dxy-attribute-time-series-input {
    .y.axis {
        .domain,
        line {
            display: none;
        }
    }
    .x.axis {
        line {
            display: none;
        }
        .domain {
            stroke: palette.$reflex-08;
        }
    }
}
