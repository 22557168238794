@use 'glyphs' as glyphs;
@use 'palette';
@use 'base/text' as text;

a {
    color: var(--color-text-any-primary);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

// an icon-button with a growing disc on hover
@mixin dg5-action-icon-ripple(
    $diameter: 30px,
    $bg_color: palette.$reflex-08,
    $font_color: var(--color-foreground-any)
) {
    position: relative;
    width: $diameter;
    height: $diameter;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $font_color;

    &:after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        background: $bg_color;
        position: absolute;
        border-radius: calc(#{$diameter} / 2);
        transition: all 0.2s ease-out;
    }
    &.active:after {
        height: $diameter;
        width: $diameter;
    }
}
