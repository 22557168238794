@use 'base/text' as text;
@use 'palette';

dxy-rich-text-field-control {
    .ql-snow {
        .ql-editor {
            .ql-blank {
                // Allow the placeholder to take the whole space it needs
                &::before {
                    position: inherit;
                }
                /* Set the text area on top of the ql-editor so it can be
                 * on top of the placeholder if there is one
                 */
                p {
                    position: absolute;
                    inset: 0;
                }
            }

            pre.ql-syntax {
                background-color: var(--color-foreground-any-inverted);
                color: palette.$reflex-100;
                overflow: visible;
                border: none;
                font-family: monospace;
            }
        }
    }

    .ql-toolbar.ql-snow {
        font-family: text.$font-family;

        .ql-formats {
            display: inline-flex;
        }
    }

    .ql-toolbar {
        .dxy-icon-button {
            padding: 2px !important;
            height: 20px;
            width: 20px;
        }
    }

    .ql-picker-options {
        z-index: 1001 !important;
    }

    .ql-tooltip {
        z-index: 1;
        left: 0 !important;
    }

    .ql-active {
        color: palette.$reflex-100 !important;
        background-color: var(
            --buttons-background-any-grayscale-hover
        ) !important;

        .ql-stroke {
            stroke: palette.$reflex-100 !important;
        }
    }

    .ql-align,
    .ql-background,
    .ql-color {
        padding: 0px !important;
        text-align: center;
        height: 20px !important;
        width: 20px !important;
        .ql-picker-label {
            padding: 2px !important;
        }
        .ql-active {
            border-radius: 6px;
            text-align: center;
        }
        svg {
            margin: 0px;
        }
    }

    .editor-container.disabled {
        color: var(--color-foreground-any-secondary);

        .ql-editor > * {
            cursor: default;
        }
    }
}
