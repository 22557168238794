@use 'base/text' as text;

dxy-insight-top-contributors {
    dxy-mini-avatars {
        .dg_mini-user-initials,
        .dg_user-avatar {
            max-height: unset;
            width: 30px !important;
            height: 30px !important;
            display: flex;
            margin-right: 0;
        }

        .text-container .user-data {
            font-size: text.$normal-text-size !important;
        }
    }
}
