@use 'palette' as palette;

.gs-endpoint {
    position: absolute;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background-color: var(--color-background-endpointTool);
    border: 1px solid var(--color-border-endpointTool);
    cursor: move;
    z-index: 10;

    &.active {
        background-color: var(--color-background-endpointTool--active);
        border: 1px solid var(--color-border-endpointTool--active);
    }
}
