@use 'base/text' as text;
@use 'caption-position/mixin-caption-position' as *;
@use 'palette';

dxy-graphical-textarea {
    --inside-margin: 20px;
    --outside-margin: 10px;
    display: flex;
    flex-grow: 1;
    textarea {
        resize: none;
        font-family: text.$font-family;
        font-size: text.$normal-text-size;
        border-color: transparent;
        background: transparent;
        padding: 0;
        cursor: auto; // for the scrollbar
        &.outside,
        &.inside:not(.full-height) {
            // outside (fit to content) or inside when text smaller than container => no scrollbar
            &::-webkit-scrollbar,
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-thumb {
                display: none;
            }
        }
        &:not(.outside),
        &.inside.full-height {
            // not inside nor outside, or inside when text taller than container => show scrollbar
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-image: none !important;
                border: none;
                background-color: palette.$reflex-08;
                &:hover {
                    background: var(--color-foreground-plain) !important;
                }
            }
        }
        &.full-height {
            flex-grow: 1;
        }
        &:not(.caption) {
            width: 100%;
            &:not(.full-height) {
                margin-bottom: auto;
            }
        }
        &.caption {
            &.inside {
                width: 100%;
                &.full-height {
                    margin-top: var(--inside-margin);
                    margin-bottom: var(--inside-margin);
                }
            }
            @include caption-position(
                var(--inside-margin),
                var(--outside-margin)
            );
        }
    }
}
