@use 'palette';

app-team-requests {
    .action-tool-glyph {
        margin-right: 10px;
    }
    .validate-btn:hover {
        background-color: palette.$green-50;
        color: var(--color-foreground-any-inverted) !important;
    }
    .reject-btn:hover {
        background-color: palette.$magenta-50;
        color: var(--color-foreground-any-inverted) !important;
    }
}
