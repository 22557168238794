@use 'palette';

dxy-connector-import-history-modal {
    .import-status {
        .success {
            color: palette.$green-50;
        }

        .failed {
            color: palette.$magenta-50;
        }
    }
}
