$bg-color: var(--color-background-any-tertiary);

.skeleton {
    animation: 1.5s shine linear infinite;

    div,
    span {
        background-color: var(--color-background-any-tertiary);
    }
}

@keyframes shine {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.5;
    }
    60% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}
