:root {
    --buttons-foreground-primary-resting: var(--color-neutral-negative);
    --buttons-background-primary-default-resting: var(--color-brand-default);
    --buttons-background-primary-default-hover: var(--color-brand-dark);
    --buttons-background-primary-default-disabled: var(--color-brand-default);
    --buttons-border-primary-color: var(--color-neutral-negative-light);
    --buttons-icon-size-s: var(--icon-size-ic-s);
    --buttons-icon-size-m: var(--icon-size-ic-m);
    --buttons-icon-size-l: var(--icon-size-ic-m);
    --buttons-radius-s: var(--radius-rd-ml);
    --buttons-background-primary-default-whilepressing: var(
        --color-brand-light
    );
    --buttons-background-primary-default-onloading: var(--color-brand-default);
    --buttons-padding-x-s: var(--padding-pd-m);
    --buttons-padding-x-m: var(--padding-pd-l);
    --buttons-background-primary-ai-resting: var(--color-ai-default);
    --buttons-background-primary-ai-hover: var(--color-ai-dark);
    --buttons-background-primary-ai-whilepressing: var(--color-ai-light);
    --buttons-background-primary-ai-onloading: var(--color-ai-default);
    --buttons-background-primary-ai-disabled: var(--color-ai-default);
    --buttons-background-primary-success-resting: var(--color-success-default);
    --buttons-background-primary-success-hover: var(--color-success-dark);
    --buttons-background-primary-success-whilepressing: var(
        --color-success-light
    );
    --buttons-background-primary-success-onloading: var(
        --color-success-default
    );
    --buttons-background-primary-success-disabled: var(--color-success-default);
    --buttons-padding-x-l: var(--padding-pd-xl);

    --buttons-background-primary-destructive-resting: var(
        --color-danger-default
    );
    --buttons-background-primary-destructive-hover: var(--color-danger-dark);
    --buttons-background-primary-destructive-whilepressing: var(
        --color-danger-light
    );
    --buttons-background-primary-destructive-onloading: var(
        --color-danger-default
    );
    --buttons-background-primary-destructive-disabled: var(
        --color-danger-default
    );

    --buttons-background-primary-warning-resting: var(--color-warning-default);
    --buttons-background-primary-warning-hover: var(--color-warning-dark);
    --buttons-background-primary-warning-whilepressing: var(
        --color-warning-light
    );
    --buttons-background-primary-warning-onloading: var(
        --color-warning-default
    );
    --buttons-background-primary-warning-disabled: var(--color-warning-default);

    --buttons-background-any-transparent-hover: rgba(255 255 255 / 0.2);
    --buttons-background-any-transparent-whilepressing: rgba(
        255 255 255 / 0.15
    );
    --buttons-radius-m: var(--radius-rd-ml);
    --buttons-radius-l: var(--radius-rd-ml);
    --buttons-border-primary-width: var(--border-width-bd-s);
    --buttons-padding-y-s: var(--padding-pd-s);
    --buttons-padding-y-m: var(--padding-pd-m);
    --buttons-padding-y-l: var(--padding-pd-l);
    --buttons-gap-s: var(--gap-gp-s);
    --buttons-gap-m: var(--gap-gp-m);
    --buttons-gap-l: var(--gap-gp-m);
    --buttons-foreground-any-grayscale-resting: var(--color-neutral-darkest);
    --buttons-foreground-any-grayscale-disabled: var(--color-neutral-darkest);
    --buttons-border-secondary-color: var(--color-neutral-light);
    --buttons-border-secondary-width: var(--border-width-bd-s);
    --buttons-padding-x-iconbutton-s: var(--padding-pd-s);
    --buttons-padding-x-iconbutton-m: var(--padding-pd-m);
    --buttons-padding-x-iconbutton-l: var(--padding-pd-l);
    --buttons-gap-iconbutton-s: 0px;
    --buttons-gap-iconbutton-s-rem: 0rem;
    --buttons-gap-iconbutton-m: 0px;
    --buttons-gap-iconbutton-m-rem: 0rem;
    --buttons-gap-l-icon: 0px;
    --buttons-gap-l-icon-rem: 0rem;
    --buttons-background-any-grayscale-hover: color-mix(
        in srgb,
        var(--color-brand-default),
        transparent var(--transparency-background-hover)
    );
    --buttons-background-any-grayscale-whilepressing: color-mix(
        in srgb,
        var(--color-brand-default),
        transparent var(--transparency-background-whilepressing)
    );
    --buttons-background-any-grayscale-onloading: rgba(255 255 255 / 0);
    --buttons-background-any-grayscale-disabled: rgba(255 255 255 / 0);
    --buttons-icon-dropdownContainerWidth-default: var(--pixels-12);
    --buttons-icon-iconcontaineroffset-default: var(--pixels-02);
    --buttons-opacity-default: 100;
    --buttons-opacity-disabled: 50%;
    --buttons-opacity-disabled-rem: 3.125rem;

    --buttons-background-any-ai-onloading: rgba(255 255 255 / 0);
    --buttons-background-any-ai-disabled: rgba(255 255 255 / 0);
    --buttons-background-any-ai-resting: rgba(255 255 255 / 0);
    --buttons-foreground-any-ai-resting: var(--color-ai-default);
    --buttons-foreground-any-ai-disabled: var(--color-ai-default);
    --buttons-background-any-ai-hover: color-mix(
        in srgb,
        var(--color-ai-default),
        transparent var(--transparency-background-hover)
    );
    --buttons-background-any-ai-whilepressing: color-mix(
        in srgb,
        var(--color-ai-default),
        transparent var(--transparency-background-whilepressing)
    );
    --buttons-foreground-any-ai-hover: var(--color-ai-default);

    --buttons-background-any-grayscale-resting: rgba(255 255 255 / 0);

    --buttons-background-any-destructive-resting: rgba(255 255 255 / 0);
    --buttons-background-any-destructive-hover: color-mix(
        in srgb,
        var(--color-danger-default),
        transparent var(--transparency-background-hover)
    );
    --buttons-background-any-destructive-whilepressing: color-mix(
        in srgb,
        var(--color-danger-default),
        transparent var(--transparency-background-whilepressing)
    );
    --buttons-foreground-any-destructive-hover: var(--color-danger-default);
    --buttons-background-any-destructive-onloading: rgba(255 255 255 / 0);
    --buttons-background-any-destructive-disabled: rgba(255 255 255 / 0);

    --buttons-background-any-success-resting: rgba(255 255 255 / 0);
    --buttons-background-any-success-hover: color-mix(
        in srgb,
        var(--color-success-default),
        transparent var(--transparency-background-hover)
    );
    --buttons-background-any-success-whilepressing: color-mix(
        in srgb,
        var(--color-success-default),
        transparent var(--transparency-background-whilepressing)
    );
    --buttons-foreground-any-success-hover: var(--color-success-default);
    --buttons-background-any-success-onloading: rgba(255 255 255 / 0);
    --buttons-background-any-success-disabled: rgba(255 255 255 / 0);

    --buttons-background-any-warning-resting: rgba(255 255 255 / 0);
    --buttons-background-any-warning-hover: color-mix(
        in srgb,
        var(--color-warning-default),
        transparent var(--transparency-background-hover)
    );
    --buttons-background-any-warning-whilepressing: color-mix(
        in srgb,
        var(--color-warning-default),
        transparent var(--transparency-background-whilepressing)
    );
    --buttons-foreground-any-warning-hover: var(--color-warning-default);
    --buttons-background-any-warning-onloading: rgba(255 255 255 / 0);
    --buttons-background-any-warning-disabled: rgba(255 255 255 / 0);
}
