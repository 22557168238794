@use 'base/text' as text;
@use 'palette';

.dg_component {
    background: transparent;
}

.dg_component .dg_component-header {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
}

.dg_component .dg_component-title {
    font-size: text.$normal-text-size;
    text-transform: uppercase;

    & [class^='glyph-'],
    [class*=' glyph-'] {
        position: relative;
        top: 2px;
        color: palette.$blue-50;
        font-size: text.$big-text-size;
    }
}

.dg_component-title .dg_component-action {
    float: right;
}

.dg_component .dg_component-subtitle {
    text-transform: none;
    font-size: text.$small-text-size;
    text-align: justify;
    line-height: 15px;
    color: palette.$gray-60;
}

.dg_component .dg_component-content {
    display: flex;
    flex-direction: column;
    padding-top: 15px;

    &.filter {
        padding-top: 0;
    }
}

.dg_component .dg_component-horizontal-content {
    display: flex;
    padding-top: 15px;
    overflow: auto;
}

.dg_component .dg_component-wrapped-content {
    flex-wrap: wrap;
}

.dg_component .dg_component-action {
    padding: 0px;
    margin: 0px 5px;
    color: palette.$blue-50;
    display: flex;
    align-items: center;
    font-size: inherit;

    &:active,
    &:visited,
    &:focus,
    &:hover {
        color: palette.$blue-50;
        text-decoration: none;
    }

    &[disabled],
    &[disabled]:hover {
        color: var(--color-foreground-any-tertiary);
        & [class^='glyph-'],
        [class*=' glyph-'] {
            color: var(--color-foreground-any-tertiary);
        }
    }
}

.dg_component-action {
    & [class^='glyph-'],
    [class*=' glyph-'] {
        position: relative;
        top: 1px;
        margin-right: 1px;
    }
}
