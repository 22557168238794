@use 'palette';

@mixin hover-style {
    background-color: palette.$reflex-08;
    opacity: 1;
}

dxy-dashboard-cell {
    --handle-size: 20px;
    .ui-resizable-handle {
        width: auto;
        height: auto;
        transition: opacity 0.3s;
        opacity: 0;
        // fix handles position
        &.ui-resizable-n,
        &.ui-resizable-e,
        &.ui-resizable-w,
        &.ui-resizable-nw,
        &.ui-resizable-ne {
            top: 0px !important;
        }
        &.ui-resizable-n,
        &.ui-resizable-ne,
        &.ui-resizable-e,
        &.ui-resizable-se,
        &.ui-resizable-s {
            right: 0px !important;
        }
        &.ui-resizable-e,
        &.ui-resizable-se,
        &.ui-resizable-s,
        &.ui-resizable-sw,
        &.ui-resizable-w {
            bottom: 0px !important;
        }
        &.ui-resizable-s,
        &.ui-resizable-sw,
        &.ui-resizable-w,
        &.ui-resizable-nw,
        &.ui-resizable-n {
            left: 0px !important;
        }

        &.ui-resizable-ne,
        &.ui-resizable-nw,
        &.ui-resizable-se,
        &.ui-resizable-sw {
            z-index: 101 !important;
            background-image: none !important;
            transform: none !important;
        }

        &.ui-resizable-n,
        &.ui-resizable-s,
        &.ui-resizable-ne,
        &.ui-resizable-nw,
        &.ui-resizable-se,
        &.ui-resizable-sw {
            height: var(--handle-size) !important;
        }

        &.ui-resizable-e,
        &.ui-resizable-w,
        &.ui-resizable-ne,
        &.ui-resizable-nw,
        &.ui-resizable-se,
        &.ui-resizable-sw {
            width: var(--handle-size) !important;
        }
    }

    .custom-handles {
        pointer-events: none;
        position: absolute;
        inset: 5px;
        display: grid;
        grid-template-columns: 10px auto 10px;
        grid-template-rows: 10px auto 10px;
        grid-template-areas:
            'nw n ne'
            'w . e'
            'sw s se';

        > .handle {
            opacity: 0;
            transition: opacity 0.3s;
            height: 100%;
            width: 100%;
            &.on {
                @include hover-style();
            }
            &.nw {
                grid-area: nw;
            }
            &.n {
                grid-area: n;
            }
            &.ne {
                grid-area: ne;
            }
            &.w {
                grid-area: w;
            }
            &.e {
                grid-area: e;
            }
            &.sw {
                grid-area: sw;
            }
            &.s {
                grid-area: s;
            }
            &.se {
                grid-area: se;
            }
        }
    }
}

.widget-wrapper {
    .widget-content-body {
        flex: 1;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        dxy-spinner {
            position: absolute;
            inset: 0;
        }
        .svg-wrapper {
            height: 100%;
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            &.loading {
                opacity: 0.2;
            }
        }
    }
}
