@use 'palette';

.data-quality-grid {
    dxy-icon-cell {
        .cell-icon.glyph-check-circle-filled {
            color: palette.$turquoise-50;
        }

        .cell-icon.glyph-alert-critical {
            color: palette.$magenta-50;
        }

        .cell-icon.glyph-alert-warning {
            color: palette.$yellow-50;
        }

        .cell-icon.glyph-alert-unknown {
            color: palette.$gray-10;
        }
    }
}

.dxy-dq-glyph {
    &.glyph-check-circle-filled {
        color: palette.$turquoise-50 !important;
    }

    &.glyph-alert-critical {
        color: palette.$magenta-50 !important;
    }

    &.glyph-alert-warning {
        color: palette.$yellow-50 !important;
    }

    &.glyph-alert-unknown {
        color: palette.$gray-10 !important;
    }
}
