@use 'base/text' as text;
@import '../../../styles/global/base/mixin-flex.scss';

/*----------------------------------*/
/**            FOR DEBUG            */
/*----------------------------------*/
app-lineage-graph {
    dxy-graphical-controls.debug {
        /** --- structural --- */

        .debug-container {
            position: absolute;
            width: 100%;
            height: 100%;
            @include vcontainerDiv();

            .lineage-flex-columns {
                flex-grow: 1;
                @include hcontainerDiv();
                .lineage-flex-column {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        /** --- presentation --- */

        /** container, columns, blueprints  */
        .d3div {
            border: 1px red dashed;
        }
        .debug-container {
            border: 1px green dashed;
            z-index: -1;
        }
        .lineage-flex-columns {
            border: 1px blue dashed;
        }
        .lineage-flex-column {
            border: 1px black dashed;
        }
        .bp-item {
            background-color: grey;
            border: 1px solid blue;
        }
        .bp-item-head {
            background-color: lightyellow;
            text-align: center;
        }
        .bp-item-body {
            background-color: lightsteelblue;
        }

        /** items  */
        g[data-id] {
            &.uil0 .databox {
                stroke: red;
            }
            &.uil1 .databox {
                stroke: green;
            }
            &.uil2 .databox {
                stroke: blue;
            }
            .item-head-debug {
                stroke: orange;
                fill: transparent;
                pointer-events: none;
            }
            .databox,
            .item-head-debug {
                stroke-width: 0.5;
            }
        }

        /** uncomment the line to see hidden virtual links */
        //g.hidden.link.vir { visibility: unset !important; display: unset !important; opacity: .3; >path { stroke: #004d80; } }

        /** link ids */
        .link > text {
            font-family: text.$font-family;
            font-size: text.$tiny-text-size;
            opacity: 0.5;
        }
        .link.track > text {
            opacity: 1;
        }

        /** bounding boxes */
        rect.debug-bounding-box {
            fill: transparent;
            pointer-events: none;
            stroke: purple;
            stroke-width: 0.5;
        }
        rect.debug-bounding-box-padding {
            fill: transparent;
            pointer-events: none;
            stroke: orange;
            stroke-width: 0.5;
        }
        rect.item-name-debug {
            height: 0.5px;
            fill: transparent;
            pointer-events: none;
            stroke: lightblue;
            stroke-width: 0.5;
        }
        g.inhell > rect.debug {
            stroke: red;
            stroke-dasharray: 1, 2;
            stroke-width: 1px;
            fill: none;
        }

        .burger-menu {
            border: 1px dashed red;
            ul {
                border: 1px dashed green !important;
            }
        }
    }
}
