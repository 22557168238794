:root {
    --tags-radius-s: var(--radius-rd-m);
    --tags-radius-m: var(--radius-rd-ml);
    --tags-height-m: 24px;
    --tags-height-s: 16px;
    --tags-padding-m: var(--padding-pd-m);
    --tags-padding-s: var(--padding-pd-s);
    --tags-font-size-m: 13px;
    --tags-font-size-s: 10px;
    --tags-glyph-size-s: 12px;
    --tags-background-transparency: 80%;
    --tags-color-primary: var(--color-neutral-negative);
    --tags-gap-m: var(--gap-gp-s);
    --tags-gap-s: var(--gap-gp-s);
}
