.dxy-input {
    border: none;
    font-size: var(--input-font-size);
    flex-grow: 1;
    padding: var(--input-padding-xs) 0px;
    background: transparent;

    &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
