@use '../mixins/mixin-label.scss';
@use 'base/text' as text;

$miniFieldHeight: 38px;
$miniFieldSubscriptHeight: 14px;
$miniFieldFontSize: text.$small-text-size;

.dxy-field.mini {
    height: calc($miniFieldHeight + $miniFieldSubscriptHeight);
    display: block !important;
    label {
        @include mixin-label.smallLabelSize();
        max-width: 100%;
        overflow: hidden !important;
        transform: none !important;
        margin: 0 !important;
    }
    mat-label {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .mat-form-field-wrapper {
        margin-top: 0px;
        padding: 0;
        height: $miniFieldHeight;
        .mat-form-field-flex {
            padding: 0 !important;
            height: 100%;
            align-items: flex-start;
            .mat-form-field-infix {
                padding: 1px 0px 2px 0px;
                border: none;
                height: 100%;
                display: flex;
                flex-direction: column-reverse;
                width: unset !important;
                .field-value {
                    min-height: 20px !important;
                }
                .mat-form-field-label-wrapper {
                    height: 14px !important;
                    position: relative !important;
                    top: unset !important;
                    padding-top: 0px !important;
                    .mat-form-field-label {
                        position: relative;
                        top: unset;
                    }
                }
                .mat-input-element,
                .no-edit {
                    font-size: $miniFieldFontSize;
                    padding-bottom: 2px;
                }
                .mat-select-trigger {
                    height: 20px;
                }
                > *:first-child {
                    flex-grow: 1;
                }
            }
        }
        .mat-form-field-underline {
            bottom: -2px !important;
        }
    }

    .read-only-field {
        min-width: unset;
        margin-bottom: unset;
        padding-top: 0;
        height: $miniFieldHeight;
        display: flex;
        flex-direction: column;
        position: relative;
        font-size: $miniFieldFontSize;

        > *:nth-child(2) {
            flex-grow: 1;
        }

        .value-container {
            padding: 0 !important;
        }

        .field-value {
            min-height: 20px !important;
            height: 100%;
        }
        .no-edit {
            margin-top: 5px;
        }
        .read-only-separator {
            position: absolute;
            bottom: 0px;
        }

        .mat-hint {
            position: absolute;
            bottom: 0px;
        }
    }

    .mat-form-field-subscript-wrapper {
        height: $miniFieldSubscriptHeight;
        font-size: text.$tiny-text-size;
        bottom: calc(-3px - $miniFieldSubscriptHeight);
        top: unset;
    }
}
