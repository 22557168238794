@use 'palette';

$form-border-color: multiply(white, fade(#{var(--color-foreground-any)}, 16%));

.form-control[readonly] {
    background: inherit;
    color: inherit;
    text-overflow: ellipsis;
    border-color: $form-border-color;
    box-shadow: none;
}

.form-control[disabled] {
    background: inherit;
    color: inherit;
    text-overflow: ellipsis;
    border-color: $form-border-color;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: default;
}

.form-control-static {
    padding-left: 2px;
}

.form-control {
    font-size: 1rem !important;
    border-color: $form-border-color;
}

.form-group {
    padding: 2px 0px;
    margin: 0px !important;

    textarea {
        resize: vertical !important; /* user can resize vertically, but width is fixed */
    }

    .control-label {
        text-align: left;
        font-weight: 700;
        color: rgba(16, 53, 177, 0.9);
    }

    .form-control {
        &.max-input {
            border-color: #ffa801;
        }
    }

    .maxlength-warning {
        color: palette.$blue-50;
    }
}

.form-inline .form-group {
    margin-left: 0;
    margin-right: 0;
}

.dg_form-input--auto {
    width: auto !important;
}

.form-checkbox label {
    @include txt-regular();
}

.textarea {
    resize: vertical;
    height: 100px;
}

.dg_textarea--no-resize {
    resize: none;
}

::placeholder {
    font-style: italic;
}

.inputFile--hidden {
    display: none !important;
}

.dg_input--readonly:not(.dg_input--editMode) {
    border-color: transparent;
    box-shadow: none;
}

.dg_input-animation {
    position: relative;
    border-bottom: unset;
    .form-group:after {
        content: '';
        height: 2px;
        width: 0;
        transition: all 0.2s cubic-bezier(0.694, 0.048, 0.335, 1);
        background: palette.$reflex-60;
    }

    .form-group.error:after {
        background: palette.$magenta-50;
    }

    .form-group.active:after,
    .form-group.error:after {
        width: 100%;
    }
}
