@use '../../palette' as palette;

body {
    --color-background-node-primary: white;
    --color-background-node-secondary: #{palette.$gray-05};

    --color-border-nodePt1-primary: #{palette.$magenta-50};
    --color-background-nodePt1-primary: #{palette.$magenta-10};
    --color-border-nodePt1-secondary: transparent;
    --color-background-nodePt1-secondary: #{palette.$magenta-gray-05};

    --color-border-nodePt2-primary: #{palette.$blue-50};
    --color-background-nodePt2-primary: #{palette.$blue-10};
    --color-border-nodePt2-secondary: transparent;
    --color-background-nodePt2-secondary: #{palette.$blue-gray-05};

    --color-border-nodePtUnion-primary: #{palette.$violet-50};
    --color-background-nodePtUnion-primary: #{palette.$violet-10};
    --color-border-nodePtUnion-secondary: transparent;
    --color-background-nodePtUnion-secondary: #{palette.$violet-gray-05};

    --color-stroke-edge-primary: #{palette.$reflex-50};
    --color-stroke-edge-secondary: #{palette.$green-50};
    --color-stroke-edge-tertiary: #{palette.$gray-50};

    --color-stroke-edgePt1-primary: #{palette.$magenta-50};
    --color-background-edgePt1-primary: #{palette.$magenta-10};
    --color-stroke-edgePt1-secondary: #{palette.$magenta-50};
    --color-background-edgePt1-secondary: #{palette.$magenta-10};

    --color-stroke-edgePt2-primary: #{palette.$blue-50};
    --color-background-edgePt2-primary: #{palette.$blue-10};
    --color-stroke-edgePt2-secondary: #{palette.$blue-50};
    --color-background-edgePt2-secondary: #{palette.$blue-10};

    --color-stroke-edgePtUnion-primary: #{palette.$violet-50};
    --color-background-edgePtUnion-primary: #{palette.$violet-10};
    --color-stroke-edgePtUnion-secondary: #{palette.$violet-50};
    --color-background-edgePtUnion-secondary: #{palette.$violet-10};

    // Tools

    // Endpoint
    --color-background-endpointTool: white;
    --color-border-endpointTool: #{palette.$reflex-40};

    --color-background-endpointTool--active: #{palette.$reflex-40};
    --color-border-endpointTool--active: white;

    // Lineage

    --color-text-glossaryStream: white;
    --color-background-glossaryStream: #{palette.$green-50};
    --color-background-glossaryStream--hover: #{palette.$grass-50};

    --color-background-virtualStream-primary: #536efe;
    --color-background-virtualStream-secondary: #{palette.$reflex-08};
    --color-background-stream-secondary: white;
}
