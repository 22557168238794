:root {
    --beta-badge-padding-vertical-m: 0;
    --beta-badge-padding-horizontal-m: var(--padding-pd-s);

    --beta-badge-font-size: 9px;
    --beta-badge-font-weight: 600;
    --beta-badge-line-height: 12px;
    --beta-badge-border-radius: var(--radius-round);

    --beta-badge-color: var(--color-neutral-negative);
    --beta-badge-background: var(--color-violet-500);
}
