@use '../mixins';

.dg5-mat-menu.mat-menu-panel {
    @include mixins.menu-panel();
    min-height: unset;
    padding: 0;
    max-width: 400px;
    display: flex;
    flex-direction: column;

    &.relative-menu {
        position: relative;
    }
    &.fixed-width-400 {
        width: 400px;
    }
    &.no-max-width {
        max-width: unset;
    }
    &.no-padding .mat-menu-content {
        padding: 0 !important;
        dxy-option-list .list-container ul {
            padding: 0;
            margin: 0;
        }
    }
    &:not(.allow-overflow) {
        overflow-x: hidden;
        > .mat-menu-content {
            overflow-x: hidden;
        }
    }

    > .mat-menu-content {
        display: flex;
        flex-direction: column;

        // first-level children div are considered items, as any .menu-item
        > div,
        .menu-item,
        .menu-items ul > li,
        > ul > li,
        li.submenu > ul > li {
            &:not(.search-bar):not(.not-an-item):not(.submenu):not(
                    .separator
                ):not([role='separator']):not([role='header']) {
                @include mixins.menu-item();
            }

            &[role='separator'],
            &.separator {
                @include mixins.menu-separator();
            }

            &[role='header'] {
                @include mixins.menu-section();
            }

            & > a {
                display: block;
                text-decoration: none;
                @include mixins.ellipsis();
            }
        }

        > ul,
        li.submenu > ul {
            padding: 0;
            margin: 0;
            min-width: 100px;
        }
        li.submenu {
            color: var(--color-foreground-any-secondary);
            padding-left: 20px;
        }
    }
    .mat-menu-item {
        @include mixins.menu-item();
        &.fit-content {
            height: fit-content !important;
            flex-basis: content !important;
        }
    }
}
