@use './base/mixin-flex.scss';
@use 'base/text' as text;

.gridContainer {
    @include mixin-flex.flexV();
    @include mixin-flex.flexItem(1);
}

.gridContainer-title {
    margin-bottom: 10px;
    min-height: 25px;
    padding: 0;
}

.ag-root {
    .description-cell {
        font-size: text.$small-text-size !important;
        line-height: 14px;
    }
}
