﻿.animation-fade {
    &.ng-enter {
        animation: fadeIn 0.3s;
    }

    &.ng-leave {
        animation: fadeOut 0.3s;
    }
}

/* Declarations of keyframes */
@keyframes dg_spin {
    from {
        transform: rotate3d(0, 0, 1, 360deg);
    }

    to {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

@keyframes dg_fade_in_out {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/**
 * ----------------------------------------
 * animation wobble-hor-top
 * ----------------------------------------
 */
@keyframes wobble-hor-top {
    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    15% {
        -webkit-transform: translateX(-15px) rotate(5deg);
        transform: translateX(-15px) rotate(6deg);
    }
    30% {
        -webkit-transform: translateX(10px) rotate(-5deg);
        transform: translateX(10px) rotate(-6deg);
    }
    45% {
        -webkit-transform: translateX(-11px) rotate(3.4deg);
        transform: translateX(-11px) rotate(3.6deg);
    }
    60% {
        -webkit-transform: translateX(7px) rotate(-2.4deg);
        transform: translateX(7px) rotate(-2.4deg);
    }
    75% {
        -webkit-transform: translateX(-5px) rotate(1.2deg);
        transform: translateX(-5px) rotate(1.2deg);
    }
}

.animation-wobble {
    -webkit-animation: wobble-hor-top 1.1s both infinite;
    animation: wobble-hor-top 1.1s both infinite;
}
