app-client-admin-attribute-values {
    dxy-edit-grid {
        .mat-row {
            border-color: var(--color-border-any);

            .mat-cell > input {
                color: unset;
                font-size: 13px;
            }

            &.disabled .mat-cell:not(.mat-column-enable-disable) {
                opacity: 0.6;
            }
        }
    }
}
