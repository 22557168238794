.dxy-menu-item {
    display: flex;
    padding: var(--menu-item-padding);
    align-items: center;
    gap: var(--buttons-gap-l);
    align-self: stretch;
    white-space: nowrap;
}
button.dxy-menu-item {
    background: none;
    border: none;
    &:hover {
        background-color: var(--buttons-background-any-grayscale-hover);
    }
    &:active {
        background-color: var(--buttons-background-any-grayscale-whilepressing);
    }
}
