@use 'base/text' as text;
@use 'palette';

.attribute-form-container,
dxy-entity-bulk-edit-modal {
    .col-sm-12 {
        padding: 10px 0px 0px 0px;
    }

    .col-sm-3,
    .col-sm-9 {
        width: 100%;
    }

    .dg_material_attribute {
        margin: 10px 0px 10px 0px;
    }
}

.dg_material_attribute {
    margin-top: 10px;

    .form-group {
        padding: 0px;
        border-bottom-color: palette.$reflex-10;
        border-bottom-width: 2px;
        border-bottom-style: solid;
    }
}

.attribute-form-container {
    .control-label {
        transition:
            line-height 0ms ease 0ms,
            margin-top 0ms ease 0ms,
            font-size 125ms ease 0ms,
            padding 125ms ease 0ms,
            top 125ms ease 0ms;
    }
    // TEMPO : WHILE MATERIAL DESIGN IS NOT IMPLEMENTED IN MODALS
    .form-group {
        .col-sm-12 {
            padding: 0px !important;
        }
        .dg_material_attribute {
            margin: 2px 0px 2px 0px;
        }
    }
}

dxy-entity-attribute-input {
    dxy-attribute-text-input {
        word-wrap: break-word;
    }
    &.mini {
        flex: 1 0 0px !important;
        min-width: 70px !important;
        max-width: 300px !important;
        position: relative;
        overflow-y: visible !important;
        & ~ dxy-entity-attribute-input.mini {
            margin-left: 16px;
        }
        .attribute-actions,
        .attribute-status-placeholder {
            position: absolute !important;
            right: 0;
            top: 2px;
            .attribute-action,
            .attribute-status {
                width: 30px;
                height: 30px;
                padding: 0 !important;
                font-size: text.$small-text-size;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
            }
        }
        app-attribute-suggestion-list {
            top: -10px !important;
        }
    }
}

.attribute-form-container,
dxy-entity-bulk-edit-modal,
dxy-entity-attribute-input {
    color: palette.$reflex-100 !important;

    [role='button'] {
        cursor: default;
    }

    .dg_material_attribute.editEnabled {
        background-color: white;

        .form-group {
            border-bottom-color: palette.$reflex-10;
            border-bottom-width: 2px;
        }
        &:hover {
            background-color: var(--color-background-any-secondary);
        }
    }

    p,
    input,
    textarea,
    .input-group,
    .ql-editor,
    .editor-container,
    .tags,
    .col-sm-9 {
        &:hover {
            background-color: transparent;
        }
    }

    .active {
        background-color: var(--color-background-any-secondary);

        & input,
        .ql-editor,
        .tags {
            background-color: var(--color-background-any-secondary);
        }
    }

    .error {
        .control-label {
            color: palette.$gray-60;
        }
        & input,
        .ql-editor,
        .tags {
            background-color: transparent;
        }
        background-color: transparent;
    }

    .error-message {
        color: palette.$magenta-50;
        padding-left: 10px;

        &:hover {
            background-color: transparent;
        }
    }

    .info-message {
        color: palette.$magenta-50;
        padding-left: 10px;

        &:hover {
            background-color: transparent !important;
        }
    }

    .info-message.mandatory {
        color: black;
        font-weight: 300;
    }

    .control-label {
        //Line-height+margin-top=20px !!
        line-height: 14px;
        margin-top: 4px;
        font-size: text.$small-text-size;
        padding: 0px 10px 0px 10px !important;
        z-index: 10;
        top: 0;
        transition:
            line-height 0ms ease 0ms,
            margin-top 0ms ease 0ms,
            font-size 125ms ease 0ms,
            padding 125ms ease 0ms,
            top 125ms ease 0ms;
    }

    .label-container {
        width: 100%;
    }

    div.col-sm-9 {
        margin-top: 2px;
        margin-bottom: 8px;
        line-height: 20px;
        padding: 0px 10px 0px 10px;
    }

    .form-control {
        height: unset;
    }

    .dg_text-input {
        //Trick to align input text to bottom (16px into 20px)
        margin-top: 2px;
        margin-bottom: -2px;
        height: 20px;
        font-size: 16px !important;
        background-color: transparent;
        padding: 0px !important;
    }

    input {
        border: none !important;
        box-shadow: none !important;
        background-color: transparent;
    }

    .tags {
        border: none !important;
        box-shadow: none !important;
        padding: 0px 2px 0px 0px !important;
    }

    .form-control-static {
        padding: 0px 10px 0px 0px;
        font-size: text.$big-text-size;
        min-height: 20px;
    }

    .tag-item {
        margin: 2px 4px 2px 0px !important;
    }

    .attribute-actions {
        // for dxy-unitary-field-actions
        display: flex;
        flex-direction: row;
        top: -10px;
        position: relative;
        z-index: 100 !important;

        &.left {
            margin-left: 10px;
            float: left;
        }

        &.right {
            float: right;
        }

        &:hover {
            background-color: transparent;
        }
    }
    .attribute-action {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        font-size: 24px;
        padding-left: 8px;
        padding-top: 8px;
        margin-right: 10px;
    }

    .glyph-undo {
        cursor: pointer;
        font-size: 20px;
        padding-left: 10px;
        padding-top: 10px;
        color: palette.$reflex-100;
        background-color: palette.$gray-40;
        &:hover {
            cursor: pointer !important;
            color: white;
            background-color: palette.$reflex-100 !important;
        }
    }
    .attribute-actions,
    .attribute-status-placeholder {
        .glyph-check {
            cursor: pointer;
            color: white;
            background-color: palette.$reflex-100;
            &:hover {
                cursor: pointer !important;
                background-color: palette.$green-50 !important;
            }
        }
    }
    .attribute-status-placeholder {
        height: 0px;
        display: flex !important;
        top: -45px;
        position: relative !important;
        float: right;
        z-index: 50 !important;
        background-color: transparent;
        &:hover {
            background-color: transparent;
        }
        .glyph-spinner {
            padding-left: 11px;
            padding-top: 10px;
            font-size: text.$title-text-size;
            color: palette.$reflex-100;
            background-color: palette.$gray-40;
            animation-name: dg_spin;
            animation-duration: 1s;
            animation-direction: reverse;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        .externally-modified {
            cursor: default;
            animation: dg_fade_in_out;
            animation-duration: 3s;
            animation-timing-function: ease-in-out;
            background-color: unset !important;
        }
        .glyph-check {
            color: white;
            background-color: palette.$green-50;
            visibility: hidden;
            opacity: 0;
            z-index: 0;
        }
        .glyph-cancelsearch {
            color: white;
            background-color: palette.$magenta-50 !important;
        }
    }
    .attribute-status {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        font-size: 24px;
        padding-left: 8px;
        padding-top: 8px;
        margin-right: 10px;
    }
}
