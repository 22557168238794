:root {
    --card-border-radius: var(--radius-rd-xxl);
    --card-background-color: var(--color-background-any);

    --card-title-padding-vertical: var(--padding-pd-xl);
    --card-title-padding-horizontal: 32px;
    --card-title-gap: var(--gap-gp-xl);

    --card-section-padding: var(--padding-pd-m) var(--padding-pd-xxl);
    --card-last-section-padding-bottom: var(--padding-pd-xl);
    --card-section-border-color: var(--color-neutral-lighter);
}
