app-suggestion-cell {
    dxy-option-item {
        .object-name {
            font-size: 13px !important;
        }

        app-entity-card-cell {
            margin: 3px 0;
        }
    }
}
