@use 'palette';

dxy-connection-form-settings {
    dxy-field-password .mat-form-field-wrapper .mat-input-element::placeholder {
        font-size: 15px;
    }

    .advanced-mode-field {
        textarea {
            background-color: palette.$reflex-05;
            min-height: 250px;
            padding-left: 5px;
        }

        .mat-form-field-underline {
            display: none;
        }
    }
}
